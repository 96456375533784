// React Util
import React, { useState, useEffect, useMemo } from "react";
import { FaArrowLeftLong } from "react-icons/fa6";

// React Router
import {
  Link,
  NavLink,
  Outlet,
  useNavigate,
  useParams,
  useLocation,
} from "react-router-dom";

// Axios Util
import axiosInstance from "../../utils/axiosInstance";

const HighCourtDelhiDetails = () => {
  const [caseDetails, setCaseDetails] = useState();
  const [error, setError] = useState(false);

  const access_token = localStorage.getItem("access_token"); // Authorization Token

  const { id } = useParams(); // Accessing url params.

  const navigate = useNavigate(); // Navigation
  const location = useLocation(); // Getting state knowing if from dashboard or not

  // Fetching Cases Stats
  const fetchCaseSummary = useMemo(
    () => async () => {
      try {
        const response = await axiosInstance.get(`highcourt/hcbank/${id}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(response.data);
        setCaseDetails(response.data);
        if (response.data?.analytics === null) {
          startFetchProcess(); // Start interval only if analytics is null
        }
      } catch (err) {
        console.log(err);
        setError(true);
      }
    },
    [access_token, id]
  );

  // Interval management variables
  let executionCount = 0; // Tracks number of executions
  let interval = null; // Tracks the interval ID

  function startFetchProcess() {
    if (interval !== null) {
      console.log("Interval already running. No new interval will be started.");
      return; // Prevent multiple intervals
    }

    interval = setInterval(async () => {
      if (executionCount >= 6) {
        clearInterval(interval); // Stop the interval after 6 executions
        interval = null; // Reset the interval ID
        console.log("Stopped after 6 executions.");
        return;
      }

      try {
        const response = await axiosInstance.get(`highcourt/hcbank/${id}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(`Execution count: ${executionCount + 1}`, response.data); // Debugging
        setCaseDetails(response.data);

        if (response.data?.analytics === null) {
          console.log("Analytics still null, continuing...");
        } else {
          clearInterval(interval); // Stop interval if analytics is no longer null
          interval = null;
          console.log("Analytics is no longer null, stopping interval.");
        }
      } catch (err) {
        console.error("Error during interval fetch:", err);
        clearInterval(interval); // Stop interval in case of error
        interval = null;
      }

      executionCount++;
    }, 10000); // Runs every 10 seconds
  }

  useEffect(() => {
    return () => {
      if (interval !== null) {
        clearInterval(interval); // Clear interval on unmount
        console.log("Interval cleared on unmount.");
      }
    };
  }, []);

  useEffect(() => {
    fetchCaseSummary();
  }, [fetchCaseSummary]);

  // Creating a chat room for the Clicked Case.
  const createChatRoom = async (cnr_number, is_true) => {
    try {
      const response = await axiosInstance.get("chatapp/chat-room/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      await axiosInstance.post(
        "chatapp/chat-room/",
        {
          highcourt_id: cnr_number,
          is_query: is_true,
        },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log("Chat room created:", response.data);
      navigate("/talkaboutcase");
    } catch (err) {
      console.log(err);
    }
  };

  if (!caseDetails && !error) {
    return (
      <div className="h-screen w-screen flex justify-center items-center bg-white dark:bg-black">
        <Loader />
      </div>
    );
  }

  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <h1>
          Error Loading This Case Detail. Please Refresh or Try Again After Some
          Time.
        </h1>
      </div>
    );
  }

  return (
    <div className="bg-white dark:bg-black dark:text-gray-200 pt-20 pb-4 relative">
      {/* Back Button */}
      <div className="px-6 py-4">
        {location.state?.all_cases_dashboard ? (
          <Link
            to="../.."
            relative="path"
            state={{ court: caseDetails?.court }}
          >
            <div className="flex gap-2 items-center">
              <FaArrowLeftLong /> <span>Back to All Cases Dashboard</span>
            </div>
          </Link>
        ) : (
          <Link to=".." relative="path" className="">
            <div className="flex gap-2 items-center">
              <FaArrowLeftLong /> <span>Back to Delhi High Court Cases</span>
            </div>
          </Link>
        )}
      </div>
      {/* Top Section */}
      <div className="flex flex-col gap-4 py-10 max-sm:px-3 max-w-[387px] mx-auto text-center">
        <h4 className="font-raleway text-xl font-bold">Try the Case Chatbot</h4>
        <p className="font-raleway text-sm font-medium">
          Try our legal chatbot in your preferred language to uncover valuable
          insights and hidden answers in your case
        </p>
        <button
          onClick={() => createChatRoom(caseDetails.id, "True")}
          className="bg-dark-600 dark:bg-light-600 max-md:px-2 max-md:text-sm px-4 py-2 w-3/5 font-medium rounded-md mx-auto text-white dark:text-black"
        >
          Talk About Your Case
        </button>
      </div>
      {/* Middle Section */}
      <div className="px-6 py-8 flex flex-col gap-8">
        <div>
          <p className="font-raleway font-bold text-xl max-md:text-base">
            {caseDetails?.petitioner_vs_respondent}
          </p>
        </div>
        <div className="grid grid-cols-5 max-md:grid-cols-2 max-md:text-sm max-md:gap-x-4 max-md:gap-y-8 justify-center gap-x-8 gap-y-10 font-raleway ">
          <CaseStat title={"Case Type"} value={caseDetails?.case_type} />
          <CaseStat
            title={"Registration No"}
            value={caseDetails?.case_information[0]?.Registration_Number}
          />
          <CaseStat
            title={"Filing No"}
            value={
              caseDetails?.case_information[0]?.Filing_Number !== "None"
                ? caseDetails?.case_information[0]?.Filing_Number
                : `${caseDetails?.caseno}/${caseDetails?.year}`
            }
          />
          <CaseStat
            title={"Nature of Disposal"}
            value={caseDetails?.case_information[0]?.Nature_of_Disposal}
          />
          <CaseStat title={"Case Status"} value={caseDetails?.case_status} />
          <CaseStat title={"Court Number & Judge"} value={"None"} />
          <CaseStat
            title={"Judge Details"}
            value={
              caseDetails?.case_information[0]?.Judge_Details?.Judge_Name
                ? caseDetails?.case_information[0]?.Judge_Details?.Judge_Name
                : Array.isArray(caseDetails.case_information[0]?.Judge_Details)
                ? caseDetails?.case_information[0]?.Judge_Details.map(
                    (judge) => (
                      <p>
                        {typeof judge === "object" ? judge.Judge_Name : judge}
                      </p>
                    )
                  )
                : caseDetails?.case_information[0]?.Judge_Details
            }
            // value={
            //   Array.isArray(caseDetails.case_information[0]?.Judge_Details)
            //     ? caseDetails?.case_information[0]?.Judge_Details[0]
            //     : caseDetails?.case_information[0]?.Judge_Details
            // }
          />
          <CaseStat
            title={"Petitioner Name"}
            value={caseDetails?.case_information[0]?.Petitioner_Name}
          />
          <CaseStat
            title={"Respondent Name"}
            value={caseDetails?.case_information[0]?.Respondent_Name}
          />
          <CaseStat title={"FIR Details"} value={"Not Available"} />
        </div>
      </div>
      {/* Bottom Section */}
      <div className="px-6 flex flex-col max-md:px-0 gap-8 mt-16">
        {/* Navbar */}
        <nav className="overflow-x-scroll text-nowrap px-6 py-8 flex gap-12 items-center bg-[#FAFAFA] dark:bg-dark-500 border-t border-b border-t-[#D9D9D9] border-b-[#D9D9D9] dark:border-t-gray-600 dark:border-b-gray-600 font-raleway">
          <NavLink
            to="."
            end
            className={({ isActive }) =>
              isActive
                ? "border-b-2 border-b-dark-600 dark:border-b-gray-200 font-semibold"
                : null
            }
            state={{ all_cases_dashboard: location.state?.all_cases_dashboard }}
          >
            Case Summary
          </NavLink>
          <NavLink
            to="timeline"
            className={({ isActive }) =>
              isActive
                ? "border-b-2 border-b-dark-600 dark:border-b-gray-200 font-semibold"
                : null
            }
            state={{ all_cases_dashboard: location.state?.all_cases_dashboard }}
          >
            Timeline
          </NavLink>
          <NavLink
            to="complete-order-sheet"
            className={({ isActive }) =>
              isActive
                ? "border-b-2 border-b-dark-600 dark:border-b-gray-200 font-semibold"
                : null
            }
            state={{ all_cases_dashboard: location.state?.all_cases_dashboard }}
          >
            Complete Order Sheet
          </NavLink>

          {caseDetails?.case_type === "BAIL APPLN." &&
            caseDetails?.analytics && (
              <NavLink
                to="judge-analytics"
                className={({ isActive }) =>
                  isActive
                    ? "border-b-2 border-b-dark-600 dark:border-b-gray-200 font-semibold"
                    : null
                }
                state={{
                  all_cases_dashboard: location.state?.all_cases_dashboard,
                }}
              >
                Judge Analytics
              </NavLink>
            )}
          {caseDetails?.case_type === "BAIL APPLN." &&
            caseDetails?.analytics && (
              <NavLink
                to="opposing-counsel-analytics"
                className={({ isActive }) =>
                  isActive
                    ? "border-b-2 border-b-dark-600 dark:border-b-gray-200 font-semibold"
                    : null
                }
                state={{
                  all_cases_dashboard: location.state?.all_cases_dashboard,
                }}
              >
                Opposing Counsel Analytics
              </NavLink>
            )}
          {caseDetails?.case_type === "BAIL APPLN." &&
            caseDetails?.analytics && (
              <NavLink
                to="similar-cases"
                className={({ isActive }) =>
                  isActive
                    ? "border-b-2 border-b-dark-600 dark:border-b-gray-200 font-semibold"
                    : null
                }
                state={{
                  all_cases_dashboard: location.state?.all_cases_dashboard,
                }}
              >
                Similar Cases
              </NavLink>
            )}
          <NavLink
            to="notes"
            className={({ isActive }) =>
              isActive
                ? "border-b-2 border-b-dark-600 dark:border-b-gray-200 font-semibold"
                : null
            }
            state={{ all_cases_dashboard: location.state?.all_cases_dashboard }}
          >
            Notes
          </NavLink>
        </nav>
        {/* Summary, Timeline, Order Sheet, Notes, Analytics */}
        <div>
          <Outlet context={{ caseDetails }} />
        </div>
      </div>
    </div>
  );
};

const CaseStat = ({ title, value }) => {
  return (
    <div className="flex flex-col gap-1">
      <p className="font-semibold">{title}</p>
      <p className="font-light">
        <p className="font-light">
          {value === "None" ? "Not Available" : value}
        </p>
      </p>
    </div>
  );
};

// Loader Component
const Loader = () => {
  return (
    <div className="flex flex-row gap-2">
      <div className="w-4 h-4 rounded-full bg-red-500 animate-bounce"></div>
      <div className="w-4 h-4 rounded-full bg-red-500 animate-bounce [animation-delay:-.3s]"></div>
      <div className="w-4 h-4 rounded-full bg-red-500 animate-bounce [animation-delay:-.5s]"></div>
    </div>
  );
};

export default HighCourtDelhiDetails;

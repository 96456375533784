import React, { useState } from "react";
import "../assets/css/Newsignup.css";
import { GoogleLogin, useGoogleLogin } from "@react-oauth/google";
import google from "../assets/images/signup/google_2702602.png";
import { jwtDecode as jwt_decode } from "jwt-decode";
import axiosInstance from "../utils/axiosInstance";
import { Link, useNavigate } from "react-router-dom";
import { useAuth } from "../context/Authcontext";
import logo from "../assets/images/navbar/legal-tech-logo.svg";
import { toast } from "react-toastify";
import { FaSpinner } from "react-icons/fa";
import leftimage from "../assets/images/signup/left-login-image.svg";
import loginvec from "../assets/images/signup/login-back-vector.svg";
import Navbar from "./Navbar";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { IoMdInformationCircleOutline } from "react-icons/io";

const Newsignup = () => {
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [confirmPassword, setConfirmPassword] = useState("");
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const [agreed, setAgreed] = useState(false);
  const { login } = useAuth();
  const navigate = useNavigate();
  const [passwordStrength, setPasswordStrength] = useState("");
  const [isLawyer, setIsLawyer] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const handleInputChange = (setter, setActive) => (event) => {
    setter(event.target.value);
    setActive(true);
  };
  const handleLawyerCheckboxChange = (event) => {
    setIsLawyer(event.target.checked);
  };

  const handleCheckBoxChange = () => {
    setAgreed(!agreed);
  };
  const handlePasswordChange = (e) => {
    const inputValue = e.target.value;
    setPassword(inputValue);
    const strength = calculatePasswordStrength(inputValue);
    setPasswordStrength(strength);
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword((prev) => !prev);
  };

  const validatePasswordwithRegx = (password) => {
    const regex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d!@#$%^&*()_+]{6,}$/;
    if (!regex.test(password)) {
      return false;
    }
    return true;
  };

  const calculatePasswordStrength = (password) => {
    if (password.length < 6) return "weak";
    if (
      password.length >= 8 &&
      /[0-9]/.test(password) &&
      /[A-Za-z]/.test(password)
    )
      return "strong";
    if (
      password.length >= 6 &&
      /[0-9]/.test(password) &&
      /[A-Za-z]/.test(password)
    )
      return "medium";
    return "weak";
  };

  const getPasswordStrengthColor = () => {
    switch (passwordStrength) {
      case "weak":
        return "red";
      case "medium":
        return "orange";
      case "strong":
        return "green";
      default:
        return "2px solid white";
    }
  };
  const validatePasswords = () => {
    if (password !== confirmPassword) {
      return false;
    }
    return true;
  };

  const handlesignup = async () => {
    if (!first_name.trim()) {
      toast.error("Please fil in the first name field.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!last_name.trim()) {
      toast.error("Please fill in last name field.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }

    if (!email.includes("@")) {
      toast.error("Please enter the valid email address.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!password.trim()) {
      toast.error("Please fill in the password field.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!validatePasswordwithRegx(password)) {
      toast.error(
        "Your password must contain at least one letter, one number, and be at least 6 characters long..",
        {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
      return;
    }
    if (!validatePasswords()) {
      toast.error("Password did not match.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!agreed) {
      toast.info("Please agree to the Terms of Service and Privacy Policy.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    setIsLoading(true);
    try {
      // const lowerCaseEmail = email.toLowerCase();
      const result = await axiosInstance.post(
        "api/signup/",
        {
          first_name,
          last_name,
          email,
          password,
          is_lawyer: isLawyer,
        },
        {
          headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
          },
          withCredentials: true,
        }
      );

      console.log("User info:", JSON.stringify(result.data));
      console.log("Access token:", result.data.access);
      const userId = result.data.user_id;
      console.log(userId);
      const intendedURL = sessionStorage.getItem("intendedURL");
      sessionStorage.removeItem("intendedURL");
      login();
      navigate("/wait-list");
      // sessionStorage.setItem("user-info", JSON.stringify(result.data));
      // sessionStorage.setItem("access_token", result.data.access);
      localStorage.setItem("user-info", JSON.stringify(result.data.user));
      localStorage.setItem("access_token", result.data.access);
      // navigate('/verify-otp', {state:{email, userId}});
    } catch (error) {
      if (error.response) {
        // The request was made and the server responded with an error status code
        let errorMessage = "";
        if (error.response.data) {
          if (error.response.data.email) {
            toast.error(`${error.response.data.email[0]}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else if (error.response.data.password) {
            toast.info(`${error.response.data.password[0]}`, {
              position: "top-right",
              autoClose: 5000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
            });
          } else {
          }
        }
        // setMessage(errorMessage);
      } else if (error.request) {
      } else {
      }
    } finally {
      setIsLoading(false);
    }
  };

  // signup with google
  const googleSuccess = (response) => {
    const credentialResponseDecode = jwt_decode(response.credential);
    const id_token = response.credential;
    // Using axios for sending data to the server
    axiosInstance
      .post(
        "api/google-login/",
        { id_token },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((response) => {
        // Handle the server response if needed
        console.log("Server response:", response);
        // login();
        if (response.data.message === "Signed Up successful") {
          navigate("/wait-list"); // Navigate to the wait list page
        }
        // sessionStorage.setItem('user-info', JSON.stringify(response.data));
        console.log(response);
        if (response.data.access) {
          localStorage.setItem("user-info", JSON.stringify(response.data.user));
          localStorage.setItem("access_token", response.data.access);
        }
        const intendedURL = sessionStorage.getItem("intendedURL") || "/";
        sessionStorage.removeItem("intendedURL"); // Clear it

        login();
        // navigate("/");
        navigate(intendedURL);
        // sessionStorage.removeItem('intendedURL');
        // sessionStorage.setItem('access_token', response.data.access); // Make sure your backend is sending this data
        // const intendedURL = sessionStorage.getItem('intendedURL');
        // navigate("/wait-list");
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };

  const googleFailure = (error) => {
    toast.error("Google sign in failed. Please try again.", {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  return (
    <>
      {/* <Navbar /> */}
      <div className="signup-page dark:bg-black bg-light-600 h-screen m-0 pt-20 pb-6">
        <div className="upper-section">
          {/* <img src={logo} alt="" /> */}
          <h3 className="dark:text-gray-300">
            <span className="dark:text-gray-200">Welcome to </span> CaseMinister
          </h3>
          {/* <p>Sign up today to start for free and be a part of the new legal revolution! We are here to guide you every step of the way.</p> */}
        </div>
        <div className="signup-centered-box bg-white dark:bg-dark-400 px-0 flex flex-col gap-4">
          <div className="mobile-upper-section">
            <p className="dark:text-gray-200 px-4">
              Secure your spot! Sign up now to join for CaseMinister's access.
            </p>
          </div>
          <div className="signup-main-container bg-white dark:bg-dark-400">
            <div className="login-left bg-gray-800 dark:bg-gray-700 rounded-none">
              <div className="main-signup-text">
                <h3>
                  Secure your spot! Sign up now to join for CaseMinister's
                  access.
                </h3>
              </div>
              <p>
                Get FREE access for 1 month after entering your details and be a
                part of the new legal revolution!
              </p>
              <div className="box2">
                <div className="center">
                  <div className="dialog-2">
                    <div
                      style={{
                        paddingLeft: "10px",
                        fontFamily: "Arial, sans-serif",
                        position: "relative",
                        // backgroundColor: '#f5f5f5',
                        borderRadius: "10px",
                        padding: "20px",
                      }}
                    >
                      <span
                        className="text-black"
                        style={{
                          fontSize: "3em",
                          fontWeight: "bold",
                          verticalAlign: "top",
                          position: "absolute",
                          top: "0",
                          left: "10px",
                        }}
                      >
                        “
                      </span>
                      <p>
                        CaseMinister is pivotal in modernizing legal practice
                        and ensuring the profession is equipped for future
                        challenges.
                      </p>
                      <span
                        className="text-black"
                        style={{
                          fontSize: "3em",
                          fontWeight: "bold",
                          verticalAlign: "bottom",
                          position: "absolute",
                          right: "25%",
                          top: "45%",
                        }}
                      >
                        ”
                      </span>
                      <br />
                      <span
                        style={{
                          color: "#4a90e2",
                          fontWeight: "bold",
                          display: "block",
                          textAlign: "left",
                          marginTop: "-20px",
                        }}
                      >
                        - Bar Council Of Delhi
                      </span>
                    </div>
                    <div className="right-point"></div>
                  </div>
                </div>
              </div>
            </div>
            <div className="login-right px-4">
              <div className="login-container">
                <div className="signup-main-headings">
                  <h3 className="mt-1 dark:text-gray-300 mb-2">
                    Create your Account
                  </h3>
                </div>
                <GoogleLogin
                  buttonText="Login with Google"
                  onSuccess={googleSuccess}
                  onError={googleFailure}
                  render={(renderProps) => (
                    <button
                      onClick={renderProps.onClick}
                      disabled={renderProps.disabled}
                      style={{
                        display: "flex",
                        justifyContent: "center", // Center button text
                        alignItems: "center",
                        width: "100%", // Set button width to 100% of its container
                        maxWidth: "400px", // Optional: if you want to limit its maximum width
                        height: "50px", // Match the height of your other buttons
                        backgroundColor: "#4285F4", // Google Blue color
                        color: "white", // Text color
                        border: "none", // No border
                        borderRadius: "25px", // Slight rounded corners
                        padding: "0 25px", // Padding on the sides
                        fontSize: "16px", // Font back
                        cursor: "pointer", // Cursor to pointer
                        boxShadow: "0 2px 4px rgba(0,0,0,0.25)", // Subtle shadow
                        margin: "10px 0", // Margin top and bottom for spacing
                      }}
                    >
                      <img
                        src={google}
                        alt="Google Icon"
                        style={{
                          marginRight: "12px",
                          height: "24px",
                          width: "24px",
                        }}
                      />
                      Sign in With Google
                    </button>
                  )}
                />
              </div>
              <div className="signup-or-login-container">
                <span className="line dark:invert-[1]"></span>
                <span className="or-text dark:text-gray-200">or</span>
                <span className="line dark:invert-[1]"></span>
              </div>
              <div className="signup-container">
                <div className="signup-form">
                  <div className="form-row">
                    <div className="form-group">
                      <label
                        htmlFor="first-name"
                        className="dark:text-gray-300"
                      >
                        First Name
                      </label>
                      <input
                        type="text"
                        id="first-name"
                        placeholder="First name"
                        value={first_name}
                        onChange={(e) => {
                          const value = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setFirstName(value);
                        }}
                        required
                        className="border mb-2 border-[#dbdbdb] dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                      />
                    </div>
                    <div className="form-group">
                      <label htmlFor="last-name" className="dark:text-gray-300">
                        Last Name
                      </label>
                      <input
                        type="text"
                        id="last-name"
                        placeholder="Last name"
                        value={last_name}
                        onChange={(e) => {
                          const value = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setLastName(value);
                        }}
                        required
                        className="border mb-2 border-[#dbdbdb] dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                      />
                    </div>
                  </div>
                  <div className="form-group">
                    <label htmlFor="email" className="dark:text-gray-300">
                      Email
                    </label>
                    <input
                      type="email"
                      id="email"
                      placeholder="example@gmail.com"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      required
                      autoComplete="off"
                      className="border mb-2 border-[#dbdbdb] dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                    />{" "}
                  </div>
                  <div className="form-group" style={{ position: "relative" }}>
                    <label htmlFor="password" className="dark:text-gray-300">
                      Password
                    </label>
                    <input
                      type={showPassword ? "text" : "password"}
                      id="password"
                      placeholder="Your Password"
                      value={password}
                      onChange={handlePasswordChange}
                      autoComplete="off"
                      className="border border-[#dbdbdb] dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                      style={{
                        // backgroundColor: "white",
                        // color: "black",
                        borderColor: getPasswordStrengthColor(),
                        boxShadow: getPasswordStrengthColor(),
                        paddingRight: "40px", // to make space for the icon
                      }}
                      required
                    />
                    <span
                      onClick={togglePasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "14px",
                        top: "50%",
                        transform: "translateY(20%)",
                        cursor: "pointer",
                      }}
                    >
                      {showPassword ? (
                        <FaEyeSlash className="dark:text-gray-200" />
                      ) : (
                        <FaEye className="dark:text-gray-200" />
                      )}
                    </span>
                  </div>
                  <div className="flex gap-1 max-sm:tracking-tighter ml-1 dark:text-gray-300 -mt-1 mb-4">
                    <IoMdInformationCircleOutline className="text-sm" />
                    <p className="text-[11px] max-sm:text-[10px] text-left">
                      Your password must contain at least one letter, one
                      number, and be at least 6 characters long.
                    </p>
                  </div>
                  <div className="form-group" style={{ position: "relative" }}>
                    <label
                      htmlFor="confirm-password"
                      className="dark:text-gray-300"
                    >
                      Confirm Password
                    </label>
                    <input
                      type={showConfirmPassword ? "text" : "password"}
                      id="confirm-password"
                      placeholder="Confirm Password"
                      value={confirmPassword}
                      onChange={(e) => setConfirmPassword(e.target.value)}
                      required
                      className="border border-[#dbdbdb] dark:border-none dark:bg-sh-dark-500 dark:placeholder:text-gray-400 dark:text-gray-300"
                    />{" "}
                    <span
                      onClick={toggleConfirmPasswordVisibility}
                      style={{
                        position: "absolute",
                        right: "14px",
                        top: "50%",
                        transform: "translateY(20%)",
                        cursor: "pointer",
                      }}
                    >
                      {showConfirmPassword ? (
                        <FaEyeSlash className="dark:text-gray-200" />
                      ) : (
                        <FaEye className="dark:text-gray-200" />
                      )}
                    </span>
                  </div>
                  {/* <div className="form-options">
                                <label>
                                    <input type="checkbox" name="terms" 
                                        checked={agreed}
                                        onChange={handleCheckBoxChange}
                                    /> Before proceeding further, click to confirm that you have read Case Minister’s Terms of Services and Privacy Policies
                                </label>
                            </div> */}
                  <div className="form-options mt-6 mb-6">
                    <div class="flex items-center mb-4">
                      <input
                        id="default-checkbox"
                        type="checkbox"
                        // value=""
                        name="terms"
                        checked={agreed}
                        onChange={handleCheckBoxChange}
                        class="w-4 h-4 mr-2 text-blue-600 bg-gray-100 border-gray-300 rounded focus:ring-blue-500 dark:focus:ring-blue-600 dark:ring-offset-gray-800 focus:ring-2 dark:bg-gray-700 dark:border-gray-600"
                      />
                      {/* <label
                        for="default-checkbox"
                        class="ms-2 text-sm font-medium text-gray-900 dark:text-gray-300"
                      >
                        Checkbox
                      </label> */}
                    </div>
                    <label className="checkbox-container">
                      {/* <input
                        type="checkbox"
                        name="terms"
                        checked={agreed}
                        onChange={handleCheckBoxChange}
                      /> */}
                      {/* <span className="checkbox-custom"></span> */}
                      <span className="checkbox-text dark:text-gray-200">
                        Before proceeding further, click to confirm that you
                        have read Case Minister’s{" "}
                        <Link className="font-bold" to="/terms-and-conditions">
                          Terms of Service
                        </Link>{" "}
                        &{" "}
                        <Link className="font-bold" to="/privacy-policy">
                          {" "}
                          Privacy Policy.
                        </Link>
                      </span>
                    </label>
                  </div>
                  <div className="form-footer">
                    {/* <button type="submit" onClick={handlesignup}>Sign up</button> */}
                    <button
                      type="submit"
                      onClick={handlesignup}
                      disabled={isLoading}
                      className="max-sm:text-xs w-full px-5 py-2 dark:bg-sh-dark-500 bg-gray-800 text-white font-bold"
                    >
                      {isLoading ? <div className="loader"></div> : "Sign up"}
                    </button>
                    <p className="dark:text-gray-200 mb-4">
                      Already have an account?{" "}
                      <a href="/login" className="text-semibold">
                        Login here
                      </a>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* <img
            src={leftimage}
            alt="Decorative"
            className="left-image max-sm:hidden"
          /> */}
        </div>
        <img
          src={loginvec}
          alt=""
          className="login-back-vec dark:opacity-[2%]"
        />
      </div>
    </>
  );
};

export default Newsignup;

import React, { useEffect, useMemo, useState, useRef } from "react";
import { useDebounce } from "react-use";
import axiosInstance from "../../../utils/axiosInstance";
import moment from "moment";

// Importing Case Types & Year
import {
  caseTypesPunjab,
  caseTypesDelhi,
  caseTypesOthers,
  caseTypesSupremeCourt,
  courtsOthers,
  benchesOthers,
} from "./data";
import { years } from "./data";

// ShadCN Tabs
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../../components/ui/tabs";

// ShadCN Input and Select
import { Input } from "../../../components/ui/input";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../components/ui/select";

// Dialog Box
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogClose,
} from "../../../components/ui/dialog";

// Icons
import { PiBookOpenText } from "react-icons/pi";
import { IoLocationOutline } from "react-icons/io5";
import { CiCalendar } from "react-icons/ci";
import { AiOutlineDelete } from "react-icons/ai";
import { CiEdit } from "react-icons/ci";
import { FiUserPlus } from "react-icons/fi";

// Get Width
import { useWindowWidth } from "@react-hook/window-size";

// Case Context
import { useCaseContext } from "../../../context/CaseContext";

import { toast } from "react-toastify";
import { useLocation, useNavigate } from "react-router-dom";
import Loader from "../../HighCourtDelhi/Loader";

const Dashboard = () => {
  const [casesStats, setCasesStats] = useState({}); // Object
  const [allCases, setAllCases] = useState({
    supremeCourtCases: [],
    highCourtCases: [],
    allHighCourtCases: [],
    districtCases: [],
    punjabHighCourtCases: [],
  });
  const [isEditable, setIsEditable] = useState(false); // Boolean
  const [caseId, setCaseId] = useState(0); // Integer
  const [hearingDate, setHearingDate] = useState(""); // String
  const [openAddCaseModal, setOpenAddCaseModal] = useState(false); // Boolean
  const [newCaseAdded, setNewCaseAdded] = useState(false); // Boolean
  const [addCaseLoader, setAddCaseLoader] = useState(false); // Boolean
  const [caseDeleted, setCaseDeleted] = useState(false); // Boolean
  const [allCasesLoading, setAllCasesLoading] = useState(false); // Boolean
  const [selectedCourt, setSelectedCourt] = useState(""); // String
  const [selectedBench, setSelectedBench] = useState(""); // String
  const [benchOptions, setBenchOptions] = useState([]); // Array
  const [caseTypes, setCaseTypes] = useState([]); // Array
  const [allCasesSelectedCourt, setAllCasesSelectedCourt] =
    useState("supremeCourtCases");
  const [selectedFilter, setSelectedFilter] = useState("All Cases");
  const [searchTerm, setSearchTerm] = useState("");
  const [filteredCases, setFilteredCases] = useState([]);
  const [debounceSearchTerm, setDebounceSearchTerm] = useState(""); // String

  useDebounce(() => setDebounceSearchTerm(searchTerm), 200, [searchTerm]); // Debounce for search term.

  useEffect(() => {
    let filteredData = allCases[allCasesSelectedCourt] || []; // Get cases of selected court
    console.log(filteredData);
    switch (selectedFilter) {
      case "Pending Cases":
        filteredData = filteredData.filter(
          (caseItem) => caseItem?.case_status?.toLowerCase() === "pending"
        );
        break;

      case "Disposed Cases":
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem?.case_status?.toLowerCase() === "disposed off" ||
            caseItem?.case_status?.toLowerCase() === "disposed" ||
            caseItem?.case_status?.toLowerCase() === "disposed of" ||
            caseItem?.case_status?.toLowerCase() === "case disposed"
        );
        break;

      case "Status Not Available":
        filteredData = filteredData.filter(
          (caseItem) => !caseItem.case_status // Cases where status is undefined/null
        );
        break;

      case "Custom Cases":
        filteredData = filteredData.filter(
          (caseItem) =>
            caseItem?.case_status?.toLowerCase() !== "pending" &&
            caseItem?.case_status?.toLowerCase() !== "disposed off" &&
            caseItem?.case_status?.toLowerCase() !== "disposed" &&
            caseItem?.case_status?.toLowerCase() !== "disposed of" &&
            caseItem?.case_status?.toLowerCase() !== "case disposed" &&
            caseItem.case_status
        );
        break;

      case "All Cases":
      default:
        break;
    }

    if (debounceSearchTerm.trim() !== "") {
      filteredData = filteredData.filter((eachcase) => {
        return (
          eachcase?.parties
            ?.toLowerCase()
            ?.includes(debounceSearchTerm?.toLowerCase()) ||
          eachcase?.case_status
            ?.toLowerCase()
            ?.includes(debounceSearchTerm?.toLowerCase()) ||
          eachcase?.case_type
            ?.toLowerCase()
            ?.includes(debounceSearchTerm?.toLowerCase()) ||
          eachcase?.cnr_number?.toString().includes(debounceSearchTerm) ||
          eachcase?.caseno?.toString().includes(debounceSearchTerm) ||
          eachcase?.dairy_number?.toString().includes(debounceSearchTerm) ||
          eachcase?.year?.toString().includes(debounceSearchTerm)
        );
      });
      console.log(filteredData);
    }

    setFilteredCases(filteredData);
    console.log(filteredData);
  }, [allCasesSelectedCourt, selectedFilter, debounceSearchTerm, allCases]);

  const onlyWidth = useWindowWidth(); // Getting Sceen Width for Easy Mobile Changes

  const handleCourtChange = (value) => {
    const court = value;
    console.log(court);
    setSelectedCourt(court);
    setSelectedBench("");
    setBenchOptions(benchesOthers[court] || []);
  };

  const handleBenchChange = (value) => {
    const bench = value;
    console.log(bench);
    setSelectedBench(bench);
    const types = caseTypesOthers[selectedCourt]?.[bench] || [];
    setCaseTypes(types);
  };

  const access_token = localStorage.getItem("access_token"); // Authorization Token

  const { fetchNotifications } = useCaseContext();

  // Fetching Cases Stats
  const fetchCasesStats = useMemo(
    () => async () => {
      try {
        const response = await axiosInstance.get(
          "dashboard/casedetails/stats/",
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );
        console.log(response.data);
        setCasesStats(response.data);
        fetchNotifications();
      } catch (err) {
        console.log(err);
      }
    },
    [access_token]
  );

  // Fetching All Cases
  const fetchAllCases = useMemo(
    () => async () => {
      try {
        setAllCasesLoading(true);
        const response = await axiosInstance.get("dashboard/casedetails/", {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });
        setAllCases({
          supremeCourtCases: response.data?.supreme_court_cases.reverse() || [],
          highCourtCases: response.data?.high_court_cases.reverse() || [],
          allHighCourtCases:
            response.data?.all_high_court_cases.reverse() || [],
          districtCases: response.data?.district_cases.reverse() || [],
          punjabHighCourtCases:
            response.data?.punjab_highcourt_cases.reverse() || [],
        });

        let timeout;
        console.log(response, "fetchAllCases Called");
        if (
          (response?.data?.high_court_cases?.length > 0 &&
            response?.data?.high_court_cases[0]?.status !== "COMPLETED") ||
          (response?.data?.punjab_highcourt_cases?.length > 0 &&
            response?.data?.punjab_highcourt_cases[0]?.status !==
              "COMPLETED") ||
          (response?.data?.all_high_court_cases?.length > 0 &&
            response?.data?.all_high_court_cases[0]?.status !== "COMPLETED") ||
          (response?.data?.supreme_court_cases?.length > 0 &&
            response?.data?.supreme_court_cases[0]?.status !== "COMPLETED") ||
          (response?.data?.district_cases?.length > 0 &&
            response?.data?.district_cases[0]?.status !== "COMPLETED")
        ) {
          console.log("TIMEOUT CALLED");
          timeout = setTimeout(() => {
            setNewCaseAdded((prev) => !prev);
          }, 5000);
        }

        if (
          response?.data?.high_court_cases[0]?.status === "COMPLETED" &&
          response?.data?.punjab_highcourt_cases[0]?.status === "COMPLETED" &&
          response?.data?.district_cases[0]?.status === "COMPLETED" &&
          response?.data?.supreme_court_cases[0]?.status === "COMPLETED" &&
          response?.data?.all_high_court_cases[0]?.status === "COMPLETED"
        ) {
          fetchNotifications();
          clearTimeout(timeout);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setAllCasesLoading(false);
      }
    },
    [access_token]
  );

  // const getfilteredCases = () => {
  //   let filtered = [].concat(...Object.values(allCases));
  //   setFilteredCases(filtered);
  // };

  // Adding a Client
  const handleAddClient = async (eachcase, e) => {
    e.preventDefault();

    // Change Courts
    let court;
    let courtId;
    if (eachcase.court === "delhi") {
      court = "delhihighcourt";
      courtId = eachcase.id;
    } else if (eachcase.court === "punjab") {
      court = "punjabhighcourt";
      courtId = eachcase.id;
    } else if (eachcase.court === "allhighcourt") {
      court = "allhighcourt";
      courtId = eachcase.id;
    } else if (eachcase.court === "supreme") {
      court = "supremecourt";
      courtId = eachcase.id;
    } else if (eachcase.court === "district") {
      court = "districtcourt";
      courtId = eachcase.id;
    }

    const form = e.target;
    const formData = new FormData(form);
    formData.append("content_type", court);
    formData.append("object_id", courtId);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }
    console.log(data);
    try {
      await axiosInstance.post("/dashboard/clients/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      // setClientAdded((prev) => !prev);
      toast.success("Client Added Successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.log(err.response);
      if (err.response.data.contact_no) {
        toast.error(err.response.data.contact_no[0], {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (err.response.data.email) {
        toast.error(err.response.data.email[0], {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  // Handle the Next Hearing Date Edit Click.
  const handleEditClick = (caseid, e) => {
    e.stopPropagation();
    setIsEditable(true);
    setCaseId(caseid);
  };

  // Handle the Cancel Button for Hearing Date Edit.
  const handleCancelEdit = () => {
    setIsEditable(false);
  };

  // Handle the Submission of Hearing Date Edit.
  const handleDateSubmit = async (e, court) => {
    e.preventDefault();

    try {
      const response = await axiosInstance.put(
        `${
          court === "delhi"
            ? `highcourt/cases/${caseId}/`
            : court === "punjab"
            ? `highcourt/punjabcases/${caseId}/`
            : court === "allhighcourt"
            ? `allhighcourt/cases/${caseId}/`
            : court === "supreme"
            ? `sccourt/cases/${caseId}/`
            : court === "district"
            ? `casedetail/cases/${caseId}/`
            : ""
        }`,
        { next_hearing_date: e.target[0].value },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      setHearingDate(
        moment(response.data?.next_hearing_date).format("D MMMM YYYY")
      );
      setIsEditable(false);

      toast.success("Next Hearing Date updated successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      toast.error("Not able to update Next Hearing Date.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // Handle Addition of a Delhi High Court Case
  const handleAddCaseDelhiHighCourt = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }

    console.log(data);
    setAddCaseLoader(true);
    try {
      const response = await axiosInstance.post("highcourt/cases/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      setNewCaseAdded((prev) => !prev);
      setOpenAddCaseModal(false);
      // console.log(newCaseAdded);

      toast.success("Processing your case details...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log(response);

      // setTimeout(() => {
      //   setNewCaseAdded((prev) => !prev);
      // }, 20000);
    } catch (err) {
      setOpenAddCaseModal(false);

      if (err.response.status === 400) {
        toast.error(err.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setAddCaseLoader(false);
    }
  };

  // Handle Addition of a Punjab & Haryana High Court Case
  const handleAddCasePHHighCourt = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }
    setAddCaseLoader(true);
    try {
      const response = await axiosInstance.post(
        "highcourt/punjabcases/",
        data,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      setNewCaseAdded((prev) => !prev);
      setOpenAddCaseModal(false);
      // console.log(newCaseAdded);

      toast.success("Processing your case details...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log(response);

      // setTimeout(() => {
      //   setNewCaseAdded((prev) => !prev);
      // }, 20000);
    } catch (err) {
      setOpenAddCaseModal(false);

      if (err.response.status === 400) {
        toast.error(err.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setAddCaseLoader(false);
    }
  };

  // Handle Addition of any High Court Case
  const handleAddCaseAllHighCourt = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    formData.append("court", selectedCourt);
    formData.append("bench", selectedBench);
    const data = {};
    console.log(data);
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }
    setAddCaseLoader(true);
    try {
      const response = await axiosInstance.post("allhighcourt/cases/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      setNewCaseAdded((prev) => !prev);
      setOpenAddCaseModal(false);
      // console.log(newCaseAdded);

      toast.success("Processing your case details...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log(response);

      // setTimeout(() => {
      //   setNewCaseAdded((prev) => !prev);
      // }, 20000);
    } catch (err) {
      setOpenAddCaseModal(false);

      if (err.response?.status === 400) {
        toast.error(err.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setAddCaseLoader(false);
    }
  };

  // Handle Addition of a District Court Case
  const handleAddCaseDistrictCourt = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }

    console.log(data);
    setAddCaseLoader(true);
    try {
      const response = await axiosInstance.post("casedetail/cases/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      setNewCaseAdded((prev) => !prev);
      setOpenAddCaseModal(false);
      // console.log(newCaseAdded);

      toast.success("Processing your case details...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log(response);

      // setTimeout(() => {
      //   setNewCaseAdded((prev) => !prev);
      // }, 20000);
    } catch (err) {
      setOpenAddCaseModal(false);

      if (err.response.status === 400) {
        toast.error(
          err.response.data.cnr_number
            ? err.response.data.cnr_number
            : err.response.data.detail,
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
    } finally {
      setAddCaseLoader(false);
    }
  };

  // Handle Addition of a Supreme Court Case
  const handleAddCaseSupremeCourt = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }
    setAddCaseLoader(true);
    try {
      const response = await axiosInstance.post("sccourt/cases/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      setNewCaseAdded((prev) => !prev);
      setOpenAddCaseModal(false);
      // console.log(newCaseAdded);

      toast.success("Processing your case details...", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      console.log(response);

      // setTimeout(() => {
      //   setNewCaseAdded((prev) => !prev);
      // }, 20000);
    } catch (err) {
      setOpenAddCaseModal(false);

      if (err.response.status === 400) {
        toast.error(err.response.data.detail, {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } finally {
      setAddCaseLoader(false);
    }
  };

  // Handle Deletion of a Case
  const handleCaseDelete = async (caseid, court, e) => {
    e.stopPropagation();

    try {
      await axiosInstance.delete(
        `${
          court === "delhi"
            ? `highcourt/cases/${caseid}/`
            : court === "punjab"
            ? `highcourt/punjabcases/${caseid}/`
            : court === "allhighcourt"
            ? `allhighcourt/cases/${caseid}/`
            : court === "supreme"
            ? `sccourt/cases/${caseid}/`
            : court === "district"
            ? `casedetail/cases/${caseid}/`
            : ""
        }`,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      toast.success("Case Deleted Successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });

      setCaseDeleted((prev) => !prev);
    } catch (err) {
      toast.error("Unable to delete this case.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  // TABS FOCUS
  const tabsRef = useRef([]);
  const tabsListRef = useRef(null);
  const [activeTab, setActiveTab] = useState("Supreme Court");

  useEffect(() => {
    const activeIndex = tabsRef.current.findIndex(
      (tab) => tab.getAttribute("data-value") === allCasesSelectedCourt
    );

    if (activeIndex !== -1 && tabsListRef.current) {
      const activeTabElement = tabsRef.current[activeIndex];
      const container = tabsListRef.current;

      // Center the active tab
      container.scrollTo({
        left:
          activeTabElement.offsetLeft -
          container.clientWidth / 2 +
          activeTabElement.clientWidth / 2,
        behavior: "smooth",
      });
    }
  }, [allCasesSelectedCourt]);

  const navigate = useNavigate();

  const location = useLocation();

  const [defaultTab, setDefaultTab] = useState(
    location?.state?.court || "Supreme Court"
  ); // String

  // Handle Case Card Click to Open Case Summary.
  const handleCaseCardClick = (eachcase) => {
    let court = eachcase.court;
    let caseid;
    if (court === "delhi") {
      caseid = eachcase.highcourtcase;
      navigate(`/all-cases-dashboard/high-court-delhi/${caseid}`, {
        state: { all_cases_dashboard: true, court: eachcase?.court },
      });
    } else if (court === "punjab") {
      caseid = eachcase.punjabhighcourtcase;
      navigate(`/all-cases-dashboard/high-court-punjab-haryana/${caseid}`, {
        state: { all_cases_dashboard: true, court: eachcase?.court },
      });
    } else if (court === "allhighcourt") {
      caseid = eachcase.allhighcourtcase;
      navigate(`/all-cases-dashboard/all-high-court-cases/${caseid}`, {
        state: { all_cases_dashboard: true, court: eachcase?.court },
      });
    } else if (court === "supreme") {
      caseid = eachcase.supremecourtcase;
      navigate(`/all-cases-dashboard/supreme-court/${caseid}`, {
        state: { all_cases_dashboard: true, court: eachcase?.court },
      });
    } else if (court === "district") {
      caseid = eachcase.cnr_number;
      navigate(`/all-cases-dashboard/district-court/${caseid}`, {
        state: { all_cases_dashboard: true, court: eachcase?.court },
      });
    }
  };

  useEffect(() => {
    fetchCasesStats();
    fetchAllCases();
  }, [fetchCasesStats, fetchAllCases, hearingDate, newCaseAdded, caseDeleted]);

  // useEffect(() => {
  // getfilteredCases();
  // }, [allCases]);

  if (Object.values(allCases).every((casesArray) => casesArray.length === 0)) {
    return (
      <>
        {allCasesLoading ? (
          <div className="flex justify-center items-center mt-40">
            <Loader />
          </div>
        ) : (
          <div className="h-[70vh] flex flex-col gap-4 justify-center items-center bg-white dark:bg-dark-600">
            {/* {openAddCaseModal && ( */}
            <AddCase
              handleAddCaseSupremeCourt={handleAddCaseSupremeCourt}
              handleAddCaseDistrictCourt={handleAddCaseDistrictCourt}
              handleAddCaseDelhiHighCourt={handleAddCaseDelhiHighCourt}
              handleAddCasePHHighCourt={handleAddCasePHHighCourt}
              handleAddCaseAllHighCourt={handleAddCaseAllHighCourt}
              addCaseLoader={addCaseLoader}
              newCaseAdded={newCaseAdded}
              openAddCaseModal={openAddCaseModal}
              setOpenAddCaseModal={setOpenAddCaseModal}
              handleCourtChange={handleCourtChange}
              handleBenchChange={handleBenchChange}
              selectedCourt={selectedCourt}
              selectedBench={selectedBench}
              benchOptions={benchOptions}
              caseTypes={caseTypes}
            />
            {/* )} */}
            <div className="flex flex-col gap-4 items-center">
              <p className="dark:text-gray-200 font-semibold text-gray-700">
                You have not added any case yet.
              </p>
              {/* <button
                onClick={() => setOpenAddCaseModal(true)}
                className="max-sm:text-xs px-5 py-2 bg-dark-500 text-gray-300 font-raleway font-semibold rounded-lg border border-dark-500 dark:bg-light-500 dark:text-black text-sm hover:shadow-[4px_4px_0px_0px_] transition duration-200"
              >
                Add New Case
              </button> */}
            </div>
          </div>
        )}
      </>
    );
  }

  return (
    <div className="pb-4 relative dark:bg-dark-600">
      {/* Top Stats Box */}
      {onlyWidth > 768 ? (
        <div className="flex items-start flex-col gap-6 border border-[#D9D9D9] dark:border-gray-600 shadow-sm mx-6 px-6 py-8">
          <div className="flex items-center gap-4 w-full justify-between">
            {/* <Input
            className="flex-1"
            placeholder="Search cases by case number, court, or status..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          /> */}
            <AddCase
              handleAddCaseSupremeCourt={handleAddCaseSupremeCourt}
              handleAddCaseDistrictCourt={handleAddCaseDistrictCourt}
              handleAddCaseDelhiHighCourt={handleAddCaseDelhiHighCourt}
              handleAddCasePHHighCourt={handleAddCasePHHighCourt}
              handleAddCaseAllHighCourt={handleAddCaseAllHighCourt}
              addCaseLoader={addCaseLoader}
              newCaseAdded={newCaseAdded}
              openAddCaseModal={openAddCaseModal}
              setOpenAddCaseModal={setOpenAddCaseModal}
              handleCourtChange={handleCourtChange}
              handleBenchChange={handleBenchChange}
              selectedCourt={selectedCourt}
              selectedBench={selectedBench}
              benchOptions={benchOptions}
              caseTypes={caseTypes}
            />
          </div>
          <div className="flex gap-2">
            <StatBox title={"Total Cases"} value={casesStats.total_cases} />
            <StatBox title={"Pending Cases"} value={casesStats.pending_cases} />
            <StatBox
              title={"Disposed Cases"}
              value={casesStats.disposed_cases}
            />
            <StatBox
              title={"Status Not Available"}
              value={casesStats.not_available_cases}
            />
            <StatBox
              title={"Custom Cases"}
              value={casesStats.custom_status_cases}
            />
          </div>
        </div>
      ) : (
        <div className="flex items-start flex-col gap-6 border border-[#D9D9D9] dark:border-gray-600 shadow-sm mx-6 px-6 max-md:px-3 py-8">
          <AddCase
            handleAddCaseSupremeCourt={handleAddCaseSupremeCourt}
            handleAddCaseDistrictCourt={handleAddCaseDistrictCourt}
            handleAddCaseDelhiHighCourt={handleAddCaseDelhiHighCourt}
            handleAddCasePHHighCourt={handleAddCasePHHighCourt}
            handleAddCaseAllHighCourt={handleAddCaseAllHighCourt}
            addCaseLoader={addCaseLoader}
            newCaseAdded={newCaseAdded}
            openAddCaseModal={openAddCaseModal}
            setOpenAddCaseModal={setOpenAddCaseModal}
            handleCourtChange={handleCourtChange}
            handleBenchChange={handleBenchChange}
            selectedCourt={selectedCourt}
            selectedBench={selectedBench}
            benchOptions={benchOptions}
            caseTypes={caseTypes}
          />
          <div className="p-2 rounded-lg flex w-full flex-col bg-gray-50 dark:bg-black/15 gap-2">
            <MobileStatBox
              color={"bg-[#000000]"}
              title={"Total Cases"}
              value={casesStats.total_cases}
            />
            <MobileStatBox
              color={"bg-[#299B00]"}
              title={"Pending Cases"}
              value={casesStats.pending_cases}
            />
            <MobileStatBox
              color={"bg-[#BA0101]"}
              title={"Disposed Cases"}
              value={casesStats.disposed_cases}
            />
            <MobileStatBox
              color={"bg-[#FF7700]"}
              title={"Status Not Available"}
              value={casesStats.not_available_cases}
            />
            <MobileStatBox
              color={"bg-[#FFBB00]"}
              title={"Custom Cases"}
              value={casesStats.custom_status_cases}
            />
          </div>
        </div>
      )}
      {/* Bottom Cases Box */}
      <div className="flex flex-col gap-6 border border-[#D9D9D9] dark:border-gray-600 shadow-sm mx-6 py-8">
        {/* All Cases Box */}
        <div className="flex flex-col gap-6 px-6 max-md:px-3">
          <Tabs
            defaultValue={
              defaultTab === "district"
                ? "District Court"
                : defaultTab === "delhi"
                ? "Delhi High Court"
                : defaultTab === "punjab"
                ? "Punjab & Haryana High Court"
                : defaultTab === "allhighcourt"
                ? "Other High Courts"
                : defaultTab === "supreme"
                ? "Supreme Court"
                : "Supreme Court"
            }
            // onValueChange={setActiveTab}
            // defaultValue="Supreme Court"
            value={allCasesSelectedCourt}
            onValueChange={setAllCasesSelectedCourt}
          >
            <TabsList
              ref={tabsListRef}
              className="w-full justify-evenly mb-4 overflow-x-auto"
            >
              {[
                { key: "supremeCourtCases", label: "Supreme Court" },
                { key: "highCourtCases", label: "Delhi High Court" },
                {
                  key: "punjabHighCourtCases",
                  label: "Punjab & Haryana High Court",
                },
                { key: "allHighCourtCases", label: "Other High Courts" },
                { key: "districtCases", label: "District Court" },
              ].map(({ key, label }) => (
                <TabsTrigger key={key} value={key}>
                  {label}
                </TabsTrigger>
              ))}
              {/* <TabsTrigger value="Supreme Court">Supreme Court</TabsTrigger>
              <TabsTrigger value="Delhi High Court">
                Delhi High Court
              </TabsTrigger>
              <TabsTrigger value="Punjab & Haryana High Court">
                Punjab & Haryana High Court
              </TabsTrigger>
              <TabsTrigger value="Other High Courts">
                Other High Courts
              </TabsTrigger>
              <TabsTrigger value="District Court">District Court</TabsTrigger> */}
            </TabsList>

            {[
              { key: "supremeCourtCases", label: "Supreme Court" },
              { key: "highCourtCases", label: "Delhi High Court" },
              {
                key: "punjabHighCourtCases",
                label: "Punjab & Haryana High Court",
              },
              { key: "allHighCourtCases", label: "Other High Courts" },
              { key: "districtCases", label: "District Court" },
            ].map(({ key, label }) => (
              <TabsContent key={key} value={key}>
                <div className="flex gap-2 mb-6">
                  <div className="w-[300px] max-w-[350px] relative">
                    <Input
                      placeholder="Search for cases..."
                      type="text"
                      value={searchTerm}
                      onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    {searchTerm && (
                      <button
                        onClick={() => setSearchTerm("")}
                        className="ml-2 tet-sm text-red-500 absolute right-2 top-[50%] translate-y-[-50%]"
                      >
                        ✖
                      </button>
                    )}
                  </div>
                  <div className="w-[200px] max-w-[200px]">
                    <Select
                      required
                      autoComplete="off"
                      value={selectedFilter}
                      onValueChange={setSelectedFilter}
                    >
                      <SelectTrigger>
                        <SelectValue placeholder="Select Filter" />
                      </SelectTrigger>
                      <SelectContent>
                        {[
                          "All Cases",
                          "Pending Cases",
                          "Disposed Cases",
                          "Status Not Available",
                          "Custom Cases",
                        ].map((filter) => (
                          <SelectItem key={filter} value={filter}>
                            {filter}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                </div>
                <div className="flex flex-col gap-6">
                  {filteredCases.length > 0 ? (
                    filteredCases.map((eachcase) => (
                      <Case
                        key={eachcase.id}
                        eachcase={eachcase}
                        handleEditClick={handleEditClick}
                        handleCancelEdit={handleCancelEdit}
                        handleDateSubmit={handleDateSubmit}
                        handleCaseDelete={handleCaseDelete}
                        handleCaseCardClick={handleCaseCardClick}
                        handleAddClient={handleAddClient}
                        isEditable={isEditable}
                        caseId={caseId}
                        allCases={allCases}
                      />
                    ))
                  ) : (
                    <p className="text-center text-gray-500">No Cases Found</p>
                  )}
                </div>
              </TabsContent>
            ))}

            {/* <TabsContent value="Supreme Court">
              <div className="flex flex-col gap-6">
                {filteredCases?.supremeCourtCases?.map((eachcase) => (
                  <Case
                    key={eachcase.id}
                    eachcase={eachcase}
                    handleEditClick={handleEditClick}
                    handleCancelEdit={handleCancelEdit}
                    handleDateSubmit={handleDateSubmit}
                    handleCaseDelete={handleCaseDelete}
                    handleCaseCardClick={handleCaseCardClick}
                    handleAddClient={handleAddClient}
                    isEditable={isEditable}
                    caseId={caseId}
                    allCases={allCases}
                  />
                ))}
              </div>
            </TabsContent>

            <TabsContent value="Delhi High Court">
              <div className="flex flex-col gap-6">
                {filteredCases?.highCourtCases?.map((eachcase) => (
                  <Case
                    key={eachcase.id}
                    eachcase={eachcase}
                    handleEditClick={handleEditClick}
                    handleCancelEdit={handleCancelEdit}
                    handleDateSubmit={handleDateSubmit}
                    handleCaseDelete={handleCaseDelete}
                    handleCaseCardClick={handleCaseCardClick}
                    handleAddClient={handleAddClient}
                    isEditable={isEditable}
                    caseId={caseId}
                    allCases={allCases}
                  />
                ))}
              </div>
            </TabsContent>

            <TabsContent value="Punjab & Haryana High Court">
              <div className="flex flex-col gap-6">
                {filteredCases?.punjabHighCourtCases?.map((eachcase) => (
                  <Case
                    key={eachcase.id}
                    eachcase={eachcase}
                    handleEditClick={handleEditClick}
                    handleCancelEdit={handleCancelEdit}
                    handleDateSubmit={handleDateSubmit}
                    handleCaseDelete={handleCaseDelete}
                    handleCaseCardClick={handleCaseCardClick}
                    handleAddClient={handleAddClient}
                    isEditable={isEditable}
                    caseId={caseId}
                    allCases={allCases}
                  />
                ))}
              </div>
            </TabsContent>

            <TabsContent value="Other High Courts">
              <div className="flex flex-col gap-6">
                {filteredCases?.allHighCourtCases?.map((eachcase) => (
                  <Case
                    key={eachcase.id}
                    eachcase={eachcase}
                    handleEditClick={handleEditClick}
                    handleCancelEdit={handleCancelEdit}
                    handleDateSubmit={handleDateSubmit}
                    handleCaseDelete={handleCaseDelete}
                    handleCaseCardClick={handleCaseCardClick}
                    handleAddClient={handleAddClient}
                    isEditable={isEditable}
                    caseId={caseId}
                    allCases={allCases}
                  />
                ))}
              </div>
            </TabsContent>

            <TabsContent value="District Court">
              <div className="flex flex-col gap-6">
                {filteredCases?.districtCases?.map((eachcase) => (
                  <Case
                    key={eachcase.id}
                    eachcase={eachcase}
                    handleEditClick={handleEditClick}
                    handleCancelEdit={handleCancelEdit}
                    handleDateSubmit={handleDateSubmit}
                    handleCaseDelete={handleCaseDelete}
                    handleCaseCardClick={handleCaseCardClick}
                    handleAddClient={handleAddClient}
                    isEditable={isEditable}
                    caseId={caseId}
                    allCases={allCases}
                  />
                ))}
              </div>
            </TabsContent> */}
          </Tabs>

          {/* {filteredCases?.map((eachcase) => (
            <Case
              key={eachcase.id}
              eachcase={eachcase}
              handleEditClick={handleEditClick}
              handleCancelEdit={handleCancelEdit}
              handleDateSubmit={handleDateSubmit}
              handleCaseDelete={handleCaseDelete}
              handleCaseCardClick={handleCaseCardClick}
              isEditable={isEditable}
              caseId={caseId}
              allCases={allCases}
            />
          ))} */}
        </div>
      </div>
    </div>
  );
};

// Statistic Box Component
const StatBox = ({ title, value }) => {
  return (
    <div className="flex flex-col gap-2 justify-between border dark:border-gray-600 rounded-lg p-2 font-mulish w-[200px]">
      <p className="font-medium">{title}</p>
      <div className="text-3xl">{value > 0 ? value : 0}</div>
    </div>
  );
};

// Mobile Statistic Box Component
const MobileStatBox = ({ color, title, value }) => {
  return (
    <div className="p-2 flex justify-between bg-white dark:bg-gray-700 rounded-sm">
      <div className="flex gap-2 items-center">
        <div className={`${color} size-3 rounded-full`}></div>
        <p className="font-mulish text-bold text-sm">{title}</p>
      </div>
      <p className="font-mulish text-sm">{value ? value : 0}</p>
    </div>
  );
};

// Each Case Card Component
const Case = ({
  eachcase,
  handleEditClick,
  handleCancelEdit,
  handleDateSubmit,
  handleCaseDelete,
  handleCaseCardClick,
  handleAddClient,
  isEditable,
  caseId,
  allCases,
}) => {
  const onlyWidth = useWindowWidth(); // Getting Sceen Width for Easy Mobile Changes
  return (
    <div
      onClick={() => handleCaseCardClick(eachcase)}
      className="flex flex-col gap-6 border border-[#D9D9D9] dark:border-gray-600 cursor-pointer shadow-sm px-6 py-4 max-md:px-3"
    >
      <div className="flex gap-8 justify-between items-center max-md:gap-4">
        <div className="truncate">
          <h6 className="font-medium truncate max-md:text-xs">
            {/* {eachcase?.parties
              ? eachcase?.parties
              : onlyWidth > 500
              ? "Please wait for few seconds, while we get your case details."
              : "Plese wait..."} */}
            {eachcase?.parties && eachcase?.status === "COMPLETED"
              ? eachcase?.parties
              : eachcase?.parties === null && eachcase?.status === "COMPLETED"
              ? "XXXXX vs XXXXX (Restricted)"
              : onlyWidth > 500
              ? "Please wait for few seconds, while we get your case details."
              : "Please wait..."}
          </h6>
        </div>
        <div className="flex items-center gap-4 max-md:gap-2">
          {/* <p>{eachcase?.filing_date}</p> */}
          {eachcase?.case_status?.toLowerCase() === "pending" &&
          eachcase?.status === "COMPLETED" ? (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-red-700">
              Pending
            </div>
          ) : (eachcase?.case_status?.toLowerCase() === "disposed off" ||
              eachcase?.case_status?.toLowerCase() === "disposed of" ||
              eachcase?.case_status?.toLowerCase() === "disposed" ||
              eachcase?.case_status?.toLowerCase()?.includes("disposed")) &&
            eachcase?.status === "COMPLETED" ? (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-green-800">
              Disposed
            </div>
          ) : (eachcase?.case_status?.toLowerCase() === "allowed" ||
              eachcase?.case_status?.toLowerCase() === "regular") &&
            eachcase?.status === "COMPLETED" ? (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-amber-600 tracking-wide">
              {eachcase?.case_status[0] +
                eachcase?.case_status.substring(1).toLowerCase()}
            </div>
          ) : eachcase?.case_status?.toLowerCase() === "dismissed" &&
            eachcase?.status === "COMPLETED" ? (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-yellow-950 tracking-wide">
              {eachcase?.case_status[0] +
                eachcase?.case_status.substring(1).toLowerCase()}
            </div>
          ) : eachcase?.case_status?.toLowerCase()?.includes("reserved") &&
            eachcase?.status === "COMPLETED" ? (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-cyan-800 tracking-wide">
              Reserved
            </div>
          ) : (
            <div className="px-3 max-md:px-1.5 max-md:py-0.5 max-md:text-[10px] max-md:rounded-sm p-1 text-xs font-medium text-white rounded-md bg-gray-500">
              {onlyWidth > 768 ? "Not Available" : "NA"}
            </div>
          )}
          <div className="flex items-center gap-3 max-md:gap-2">
            <div onClick={(e) => e.stopPropagation()} className="mt-1">
              <Dialog>
                <DialogTrigger onClick={(e) => e.stopPropagation()}>
                  <FiUserPlus className="text-lg max-md:text-base cursor-pointer select-none hover:opacity-60" />
                </DialogTrigger>
                <DialogContent onClick={(e) => e.stopPropagation()}>
                  <DialogHeader>
                    <DialogTitle>Add Client</DialogTitle>
                    <DialogDescription>
                      Add clients for your pending or disposed cases.
                    </DialogDescription>
                  </DialogHeader>
                  <form
                    className="flex flex-col gap-4"
                    onSubmit={(e) => handleAddClient(eachcase, e)}
                  >
                    <Input
                      name="full_name"
                      placeholder="Full Name *"
                      required
                      autoComplete="off"
                    />
                    <Input
                      name="contact_no"
                      placeholder="Contact No. * "
                      required
                      autoComplete="off"
                    />
                    <Input
                      name="email"
                      placeholder="Email *"
                      required
                      autoComplete="off"
                    />
                    <DialogFooter>
                      <button
                        type="submit"
                        className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                      >
                        Add Client
                      </button>
                    </DialogFooter>
                  </form>
                </DialogContent>
              </Dialog>
            </div>
            <div onClick={(e) => e.stopPropagation()} className="mt-1">
              <Dialog>
                <DialogTrigger onClick={(e) => e.stopPropagation()}>
                  <AiOutlineDelete className="text-lg max-md:text-base cursor-pointer select-none hover:opacity-60" />
                </DialogTrigger>
                <DialogContent onClick={(e) => e.stopPropagation()}>
                  <DialogHeader>
                    <DialogTitle>Delete Case</DialogTitle>
                  </DialogHeader>
                  <div className="flex flex-col gap-4">
                    <p>Are you sure you want to delete this case?</p>
                    <div className="flex gap-2">
                      <DialogClose className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm">
                        Cancel
                      </DialogClose>
                      <button
                        className="px-5 py-1.5 bg-red-600 text-gray-200 text-sm"
                        onClick={(e) =>
                          handleCaseDelete(eachcase.id, eachcase.court, e)
                        }
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
            </div>
          </div>
        </div>
      </div>
      <div className="flex max-md:flex-col max-md:gap-2 gap-8 text-sm">
        <div className="flex items-center gap-2 basis-[250px] max-md:basis-0">
          <PiBookOpenText className="max-md:text-xs" />
          <p className="max-md:text-xs">
            {eachcase?.case_type ? eachcase?.case_type + "/" : ""}
            {eachcase?.caseno
              ? eachcase?.caseno + "/"
              : eachcase?.dairy_number
              ? eachcase?.dairy_number + "/"
              : eachcase?.cnr_number
              ? eachcase?.cnr_number
              : ""}
            {eachcase?.cnr_number ? "" : eachcase?.year}
          </p>
          <p>{/* {eachcase.case_type}/{eachcase.caseno}/{eachcase.year} */}</p>
        </div>
        <div className="flex items-center gap-2 basis-[200px]  max-md:basis-0">
          <IoLocationOutline className="max-md:text-xs" />
          <p className="max-md:text-xs">
            {eachcase?.court === "supreme"
              ? "Supreme Court"
              : eachcase?.court === "delhi"
              ? "Delhi High Court"
              : eachcase?.court === "punjab"
              ? "Punjab High Court"
              : eachcase?.court === "allhighcourt"
              ? eachcase?.court_detail
              : "District Court"}
          </p>
        </div>
        {isEditable && eachcase.id === caseId ? (
          <form
            className="flex gap-2"
            onSubmit={(e) => handleDateSubmit(e, eachcase?.court)}
            onClick={(e) => e.stopPropagation()}
          >
            <input
              type="date"
              className="bg-light-600 border border-[#d9d9d9] dark:border-gray-500 dark:text-black px-1 text-xs"
              required
            />
            <button
              type="submit"
              className="bg-blue-600 text-white px-2 text-xs rounded-sm"
            >
              Submit
            </button>
            <button
              className="bg-slate-200 px-2 dark:text-black text-xs font-medium rounded-sm"
              onClick={handleCancelEdit}
            >
              Cancel
            </button>
          </form>
        ) : (
          <div className="flex items-center gap-2">
            <CiCalendar className="max-md:text-xs" />
            <div
              className="flex items-center gap-2"
              onClick={(e) => handleEditClick(eachcase.id, e)}
            >
              <p className="max-md:text-xs">
                {eachcase.next_hearing_date
                  ? eachcase?.next_hearing_date?.includes("st") ||
                    eachcase?.next_hearing_date?.includes("nd") ||
                    eachcase?.next_hearing_date?.includes("rd") ||
                    eachcase?.next_hearing_date?.includes("th")
                    ? `Next Hearing - ${eachcase?.next_hearing_date}`
                    : `Next Hearing - ${moment(
                        eachcase.next_hearing_date
                      ).format("D MMMM YYYY")}`
                  : "Add next hearing date"}
              </p>
              <CiEdit className="cursor-pointer" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const AddCase = ({
  addCaseLoader,
  handleAddCaseSupremeCourt,
  handleAddCaseDistrictCourt,
  handleAddCaseDelhiHighCourt,
  handleAddCasePHHighCourt,
  handleAddCaseAllHighCourt,
  newCaseAdded,
  openAddCaseModal,
  setOpenAddCaseModal,
  handleCourtChange,
  handleBenchChange,
  selectedCourt,
  selectedBench,
  benchOptions,
  caseTypes,
}) => {
  return (
    <Dialog open={openAddCaseModal} onOpenChange={setOpenAddCaseModal}>
      <DialogTrigger className="max-sm:text-xs px-5 py-2 bg-dark-500 text-gray-300 font-raleway font-semibold rounded-lg border border-dark-500 dark:bg-light-500 dark:text-black text-sm hover:shadow-[4px_4px_0px_0px_] transition duration-200">
        Add New Case
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>Add Case</DialogTitle>
          <DialogDescription>
            Add new case by selecting the court and its details.
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="High Court">
          <TabsList className="w-full justify-evenly">
            <TabsTrigger value="High Court">High Court</TabsTrigger>
            <TabsTrigger value="Supreme Court">Supreme Court</TabsTrigger>
            <TabsTrigger value="District Court">District Court</TabsTrigger>
          </TabsList>
          <TabsContent value="High Court">
            <Tabs defaultValue="Delhi">
              <TabsList className="w-full justify-evenly">
                <TabsTrigger value="Delhi">Delhi</TabsTrigger>
                <TabsTrigger value="Punjab & Haryana">
                  Punjab & Haryana
                </TabsTrigger>
                <TabsTrigger value="Others">Others</TabsTrigger>
              </TabsList>
              <TabsContent value="Delhi">
                <form
                  className="w-full flex flex-col gap-4 mt-4"
                  onSubmit={handleAddCaseDelhiHighCourt}
                >
                  <Select required name="case_type" autoComplete="off">
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Type*" />
                    </SelectTrigger>
                    <SelectContent>
                      {caseTypesDelhi.map((caseType) => (
                        <SelectItem key={caseType} value={caseType}>
                          {caseType}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="number"
                    min="1"
                    placeholder="Case Number*"
                    name="caseno"
                    required
                    autoComplete="off"
                  />
                  <Select
                    required
                    name="year"
                    autoComplete="off"
                    className="h-10"
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Year*" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[300px]">
                      {years.map((year) => (
                        <SelectItem key={year} value={`${year}`}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="text"
                    placeholder="Case Name"
                    name="case_nickname"
                    autoComplete="off"
                  />
                  <DialogFooter>
                    <button
                      type="submit"
                      className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                    >
                      {addCaseLoader ? (
                        <div className="flex gap-1 justify-center items-center">
                          <p>Adding Case...</p>
                          <div className="w-4 h-4 border-2 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
                        </div>
                      ) : (
                        "Continue to Add Case"
                      )}
                    </button>
                  </DialogFooter>
                </form>
              </TabsContent>
              <TabsContent value="Punjab & Haryana">
                <form
                  className="w-full flex flex-col gap-4 mt-4"
                  onSubmit={handleAddCasePHHighCourt}
                >
                  <Select required name="case_type" autoComplete="off">
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Type*" />
                    </SelectTrigger>
                    <SelectContent>
                      {caseTypesPunjab.map((caseType) => (
                        <SelectItem key={caseType} value={caseType}>
                          {caseType}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="number"
                    min="1"
                    placeholder="Case Number*"
                    name="caseno"
                    required
                    autoComplete="off"
                  />
                  <Select
                    required
                    name="year"
                    autoComplete="off"
                    className="h-10"
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Year*" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[300px]">
                      {years.map((year) => (
                        <SelectItem key={year} value={`${year}`}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="text"
                    placeholder="Case Name"
                    name="case_nickname"
                    autoComplete="off"
                  />
                  <DialogFooter>
                    <button
                      type="submit"
                      className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                    >
                      {addCaseLoader ? (
                        <div className="flex gap-1 justify-center items-center">
                          <p>Adding Case...</p>
                          <div className="w-4 h-4 border-2 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
                        </div>
                      ) : (
                        "Continue to Add Case"
                      )}
                    </button>
                  </DialogFooter>
                </form>
              </TabsContent>
              <TabsContent value="Others">
                <form
                  className="w-full flex flex-col gap-4 mt-4"
                  onSubmit={handleAddCaseAllHighCourt}
                >
                  <Select
                    required
                    name="court"
                    autoComplete="off"
                    className="h-10"
                    onValueChange={(value) => handleCourtChange(value)}
                    value={selectedCourt}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Court *" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[300px]">
                      {courtsOthers.map((court) => (
                        <SelectItem key={court} value={`${court}`}>
                          {court}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Select
                    required
                    name="bench"
                    autoComplete="off"
                    className="h-10"
                    onValueChange={(value) => handleBenchChange(value)}
                    value={selectedBench}
                    disabled={!selectedCourt}
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Bench *" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[300px]">
                      {benchOptions.map((bench) => (
                        <SelectItem key={bench} value={`${bench}`}>
                          {bench}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Select required name="case_type" autoComplete="off">
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Type*" />
                    </SelectTrigger>
                    <SelectContent>
                      {caseTypes?.map((caseType) => (
                        <SelectItem key={caseType} value={caseType}>
                          {caseType}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="number"
                    min="1"
                    placeholder="Case Number*"
                    name="caseno"
                    required
                    autoComplete="off"
                  />
                  <Select
                    required
                    name="year"
                    autoComplete="off"
                    className="h-10"
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Year*" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[300px]">
                      {years.map((year) => (
                        <SelectItem key={year} value={`${year}`}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="text"
                    placeholder="Case Name"
                    name="case_nickname"
                    autoComplete="off"
                  />
                  <DialogFooter>
                    <button
                      type="submit"
                      className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                    >
                      {addCaseLoader ? (
                        <div className="flex gap-1 justify-center items-center">
                          <p>Adding Case...</p>
                          <div className="w-4 h-4 border-2 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
                        </div>
                      ) : (
                        "Continue to Add Case"
                      )}
                    </button>
                  </DialogFooter>
                </form>
              </TabsContent>
            </Tabs>
          </TabsContent>
          <TabsContent value="Supreme Court">
            <Tabs defaultValue="Diary Number">
              <TabsList className="w-full justify-evenly">
                <TabsTrigger value="Diary Number">Diary Number</TabsTrigger>
                <TabsTrigger value="Case No">Case Number</TabsTrigger>
              </TabsList>
              <TabsContent value="Diary Number">
                <form
                  className="w-full flex flex-col gap-4 mt-4"
                  onSubmit={handleAddCaseSupremeCourt}
                >
                  {/* <Select required name="case_type" autoComplete="off">
                        <SelectTrigger>
                          <SelectValue placeholder="Select Case Type*" />
                        </SelectTrigger>
                        <SelectContent>
                          {caseTypes.map((caseType) => (
                            <SelectItem key={caseType} value={caseType}>
                              {caseType}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select> */}
                  <Input
                    type="number"
                    min="1"
                    placeholder="Diary Number*"
                    name="dairy_number"
                    required
                    autoComplete="off"
                  />
                  <Select
                    required
                    name="year"
                    autoComplete="off"
                    className="h-10"
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Year*" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[300px]">
                      {years.map((year) => (
                        <SelectItem key={year} value={`${year}`}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="text"
                    placeholder="Case Name"
                    name="case_nickname"
                    autoComplete="off"
                  />
                  <DialogFooter>
                    <button
                      type="submit"
                      className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                    >
                      {addCaseLoader ? (
                        <div className="flex gap-1 justify-center items-center">
                          <p>Adding Case...</p>
                          <div className="w-4 h-4 border-2 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
                        </div>
                      ) : (
                        "Continue to Add Case"
                      )}
                    </button>
                  </DialogFooter>
                </form>
              </TabsContent>
              <TabsContent value="Case No">
                <form
                  className="w-full flex flex-col gap-4 mt-4"
                  onSubmit={handleAddCaseSupremeCourt}
                >
                  <Select required name="case_type" autoComplete="off">
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Type*" />
                    </SelectTrigger>
                    <SelectContent>
                      {caseTypesSupremeCourt.map((caseType) => (
                        <SelectItem key={caseType} value={caseType}>
                          {caseType}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="number"
                    min="1"
                    placeholder="Case Number*"
                    name="caseno"
                    required
                    autoComplete="off"
                  />
                  <Select
                    required
                    name="year"
                    autoComplete="off"
                    className="h-10"
                  >
                    <SelectTrigger>
                      <SelectValue placeholder="Select Case Year*" />
                    </SelectTrigger>
                    <SelectContent className="max-h-[300px]">
                      {years.map((year) => (
                        <SelectItem key={year} value={`${year}`}>
                          {year}
                        </SelectItem>
                      ))}
                    </SelectContent>
                  </Select>
                  <Input
                    type="text"
                    placeholder="Case Name"
                    name="case_nickname"
                    autoComplete="off"
                  />
                  <DialogFooter>
                    <button
                      type="submit"
                      className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                    >
                      {addCaseLoader ? (
                        <div className="flex gap-1 justify-center items-center">
                          <p>Adding Case...</p>
                          <div className="w-4 h-4 border-2 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
                        </div>
                      ) : (
                        "Continue to Add Case"
                      )}
                    </button>
                  </DialogFooter>
                </form>
              </TabsContent>
            </Tabs>
          </TabsContent>
          <TabsContent value="District Court">
            <form
              className="w-full flex flex-col gap-4 mt-6"
              onSubmit={handleAddCaseDistrictCourt}
            >
              <Input
                type="text"
                placeholder="CNR Number*"
                name="cnr_number"
                required
                autoComplete="off"
              />
              <Input
                type="text"
                placeholder="Case Name"
                name="case_nickname"
                autoComplete="off"
              />
              <p className="dark:text-gray-200 text-sm">
                *Case Number Record (CNR) is a unique 16 digit number given to
                all cases. For example, MHAU019999992015.
                <a
                  href="https://caseminister.blob.core.windows.net/frontend/CNR Number.pdf
                                "
                  target="_blank"
                  rel="noopener noreferrer"
                  className="hover:opacity-60"
                >
                  {" "}
                  Click here for more information.
                </a>
              </p>
              <DialogFooter>
                <button
                  type="submit"
                  className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                >
                  {addCaseLoader ? (
                    <div className="flex gap-1 justify-center items-center">
                      <p>Adding Case...</p>
                      <div className="w-4 h-4 border-2 border-t-blue-500 border-gray-300 rounded-full animate-spin"></div>
                    </div>
                  ) : (
                    "Continue to Add Case"
                  )}
                </button>
              </DialogFooter>
            </form>
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
};

export default Dashboard;

export const caseTypes = [
  "SPECIAL LEAVE PETITION (CIVIL)",
  "SPECIAL LEAVE PETITION (CRIMINAL)",
  "CIVIL APPEAL",
  "CRIMINAL APPEAL",
  "WRIT PETITION (CIVIL)",
  "WRIT PETITION(CRIMINAL)",
  "TRANSFER PETITION (CIVIL)",
  "TRANSFER PETITION (CRIMINAL)",
  "REVIEW PETITION (CIVIL)",
  "REVIEW PETITION (CRIMINAL)",
  "TRANSFERRED CASE (CIVIL)",
  "TRANSFERRED CASE (CRIMINAL)",
  "SPECIAL LEAVE TO PETITION (CIVIL)",
  "SPECIAL LEAVE TO PETITION (CRIMINAL)",
  "WRIT TO PETITION (CIVIL)",
  "WRIT TO PETITION (CRIMINAL)",
  "ORIGINAL SUIT",
  "DEATH REFERENCE CASE",
  "CONTEMPT PETITION(CIVIL)",
  "CONTEMPT PETITION (CRIMINAL)",
  "TAX REFERENCE CASE",
  "SPECIAL REFERENCE CASE",
  "ELECTION PETITION (CIVIL)",
  "ARBITRATION PETITION",
  "CURATIVE PETITION (CIVIL)",
  "CURATIVE PETITION (CRL)",
  "REF. U/A 317(1)",
  "MOTION (CRL)",
  "DIARYNO AND DIARYYR",
  "SUO MOTO WRIT PETITION (CIVIL)",
  "SUO MOTO WRIT PETITION (CRIMINAL)",
  "SUO MOTO CONTEMPT PETITION (CIVIL)",
  "SUO MOTO CONTEMPT PETITION (CRIMINAL)",
  "REF. U/S 14 RTI",
  "REF. U/S 17 RTI",
  "MISCELLANEOUS APPLICATION",
  "SUO MOTO TRANSFER PETITION (CIVIL)",
  "SUO MOTO TRANSFER PETITION (CRIMINAL)",
  "Unknown",
];

export const years = [
  2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013,
  2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000,
  1999,
];

import React from "react";
import caseDetailswaiting from "../assets/images/comingSoon/CaseWaitingPage.svg";
import "../assets/css/waitlist.css";
import Navbar from "./Navbar";
import Waitlistrobo from "../assets/images/comingSoon/waitList.svg";
import Base from "../assets/images/waitlist/Base.png";
import Hammer from "../assets/images/waitlist/Hammer.png";
import Logo from "../assets/images/navbar/legal-tech-logo.svg";
import BaseMobile from "../assets/images/waitlist/BaseMobile.png";

export default function WaitList() {
  return (
    <div className="">
      <div className="grid justify-items-center relative h-screen bg-[#010a13] bg-[url('./assets/images/waitlist/Background.png')]">
        <div className="mt-28 flex flex-col gap-4 items-center w-2/3 max-sm:w-[90%]">
          <img
            src={Logo}
            alt="Logo"
            className="border-2 rounded-full size-20"
          />
          <h1 className="font-mulish font-bold text-[40px] leading-[60px] text-center max-lg:text-2xl text-white">
            Thank you for signing up! <br />
            You’re now on the CaseMinister Waitlist
          </h1>
          <p className="font-mulish text-[28px] leading-[40px] text-center max-lg:text-xl text-white">
            Stay tuned for updates and be among the first to experience the
            future of legal technology.
          </p>
        </div>
        <img
          src={Base}
          className="absolute bottom-0 max-[430px]:hidden"
          alt="decorative base background"
        />
        <img
          src={BaseMobile}
          className="absolute bottom-0 sm:hidden"
          alt="decorative base background"
        />
        <img
          src={Hammer}
          className="absolute -bottom-20 max-sm:bottom-8 left-[50%] max-sm:scale-90 translate-x-[-50%] scale-[70%]"
          alt="decorative hammer background"
        />
      </div>
    </div>
    //  <Navbar/>
    // <div className="waitLis-app-container dark:bg-dark-600 h-full mt-12 md:h-screen">
    //   <div className="waitList-container-contain">
    //     <h1>
    //       Thank you for signing up! You’re now on the CaseMinister waitlist
    //     </h1>
    //     <p>
    //       Stay tuned for updates and be among the first to experience the
    //       future of legal technology.
    //     </p>
    //   </div>
    //   <div className="WaitList-Right-Side">
    //     <img
    //       src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/waitList.svg"
    //       alt=""
    //     />
    //   </div>
    // </div>
  );
}

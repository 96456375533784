// React Utils
import React, { useState } from "react";

// Framer Motion Utils
import { motion } from "framer-motion";

// Images
import InsightsFolderIcon from "../../assets/images/NewLandingPage/InsightsFolderIcon.png";
import LogoVector from "../../assets/images/NewLandingPage/LogoVector.png";
import ChatbotVector from "../../assets/images/NewLandingPage/ChatbotVector.png";
import InsightsBackground from "../../assets/images/NewLandingPage/InsightsBackground.png";

// Get Width
import { useWindowWidth } from "@react-hook/window-size";

const InsightsSection = () => {
  const [cardDataId, setCardDataId] = useState(null);
  const onlyWidth = useWindowWidth(); // Getting Sceen Width for Easy Mobile Changes

  const cardsData = [
    {
      id: 1,
      title: "📊 Total Cases Analyzed",
      subtitle: "Track Matter status and hearing outcomes",
      bulletPoint1: "Total Matters Analyzed",
      bulletPoint2: "Average Disposal Time",
    },
    {
      id: 2,
      title: "⚖️ Judgment Prediction",
      subtitle: "Forecast Judicial outcomes with AI precision",
      bulletPoint1: "Favorable Order Probability",
      bulletPoint2: "Expected Judgement Timeline",
    },
    {
      id: 3,
      title: "🧑‍⚖️ Advocate Performance",
      subtitle: "Measure effectiveness in various forum",
      bulletPoint1: "Counsel Success Rate",
      bulletPoint2: "Client Portfolio Growth",
    },
    {
      id: 4,
      title: "🎯 Practice Management",
      subtitle: "Optimize Resources and reduce growth",
      bulletPoint1: "Litigation Cost Reducation",
      bulletPoint2: "Clerical Time Saving",
    },
  ];

  const handleCardHover = (id) => {
    setCardDataId(id);
  };

  const handleCardNoHover = () => {
    setCardDataId(null);
  };

  return (
    <div className="py-32 bg-black relative">
      <div className="absolute">
        <img src={InsightsBackground} alt="background" />
      </div>
      <div className="flex flex-col items-center gap-20 px-20 max-sm:px-4 text-white">
        <motion.div
          initial={{
            opacity: 0,
            y: 20,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.7,
            ease: "easeOut",
            delay: 0,
          }}
          viewport={{
            once: true,
            margin: "-50px",
          }}
          className="flex flex-col gap-10"
        >
          <motion.h4
            className="font-sora font-bold text-5xl max-sm:text-3xl text-center"
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0 }}
            viewport={{ once: true }}
          >
            Powerful Insights, Smarter Decisions
          </motion.h4>

          <motion.p
            className="font-poppins text-sm text-center"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0 }}
            viewport={{ once: true }}
          >
            We track performance, monitor trends, and optimize strategies with
            real-time data
          </motion.p>
        </motion.div>
        <div className="flex max-md:flex-col gap-16 items-center relative">
          {onlyWidth >= 768 ? (
            <div className="absolute w-[71%] inset-0">
              <SVGDesktop />
            </div>
          ) : (
            <div className="absolute w-full inset-0">
              <SVGMobile />
            </div>
          )}

          <div className="flex max-md:flex-col max-md:gap-10 gap-20 relative">
            <div className="flex-1 bg-gradient-to-b max-sm:max-w-[200px] from-[#E5E5E5] to-[#F1F1F14D] p-[1px] rounded-lg">
              <div className="flex h-full flex-col text-center items-center gap-5 bg-black p-6  rounded-lg">
                <img
                  src={InsightsFolderIcon}
                  width={55}
                  alt="folder-icon"
                  className="w-[35px]"
                />
                <p className="font-poppins font-semibold text-xl max-sm:text-base">
                  Legal Data/Cases
                </p>
                <p className="font-poppins text-sm max-sm:text-xs">
                  Data sourced from the Indian legal system
                </p>
              </div>
            </div>
            <div className="flex-1 bg-gradient-to-b from-[#E5E5E5] to-[#F1F1F14D] p-[1px] rounded-lg">
              <div className="flex h-full flex-col text-center items-center gap-5 bg-black p-6 rounded-lg">
                <img
                  src={LogoVector}
                  width={53}
                  alt="logo"
                  className="w-[35px]"
                />
                <p className="font-poppins font-semibold text-xl max-sm:text-base">
                  CaseMinister
                </p>
                <p className="font-poppins text-sm max-sm:text-xs">
                  Proprietary Analysis
                </p>
              </div>
            </div>
            <div className="flex-1 bg-gradient-to-b from-[#E5E5E5] to-[#F1F1F14D] p-[1px] rounded-lg">
              <div className="flex h-full flex-col text-center items-center gap-5 bg-black p-6 rounded-lg">
                <img
                  src={ChatbotVector}
                  width={54}
                  alt="chatbot-vector"
                  className="w-[35px]"
                />
                <p className="font-poppins font-semibold text-xl max-sm:text-base">
                  AI-Driven Insight
                </p>
                <p className="font-poppins text-sm max-sm:text-xs">
                  Judgment Prediction
                </p>
              </div>
            </div>
          </div>
          <div className="flex flex-col max-md:grid max-md:grid-cols-2 max-md:justify-center gap-6 relative">
            {cardsData.map((cardData) => (
              <div
                className="flex cursor-pointer bg-gradient-to-br from-[#521EDF] to-[#D028CB] p-[1px] rounded-lg"
                onMouseEnter={() => handleCardHover(cardData.id)}
                // onClick={() => handleCardHover(cardData.id)}
                onMouseLeave={() => handleCardNoHover()}
                key={cardData.id}
              >
                <div className="flex w-full h-full flex-col text-center items-center gap-5 bg-black p-6 max-sm:px-4 rounded-lg">
                  <p className="font-poppins font-semibold text-xl max-sm:text-base">
                    {cardData.title}
                  </p>
                  <p className="font-poppins font-medium max-sm:text-xs">
                    {cardData.subtitle}
                  </p>
                  <ul
                    className={`font-poppins max-sm:text-xs transition-all duration-300 ease-in-out ${
                      cardDataId === cardData.id
                        ? "opacity-100 max-h-[100px]"
                        : "opacity-0 max-h-0 mb-[-20px]"
                    }`}
                  >
                    <li className="list-disc mb-1 text-left max-sm:text-center max-sm:list-none">
                      {cardData.bulletPoint1}
                    </li>
                    <li className="list-disc text-left max-sm:text-center max-sm:list-none">
                      {cardData.bulletPoint2}
                    </li>
                  </ul>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

const SVGMobile = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 400 980"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex flex-shrink-0"
    >
      <path
        d="M200 0 V585 
        M200 585 Q200 620 160 620 
        M160 620 H130 
        M130 620 Q90 620 90 685 
        M90 685 V960
        M200 585 Q200 620 240 620
        M240 620 H270
        M270 620 Q310 620 310 685
        M310 685 V960"
        // d="M200 0 V585 M200 585 Q80 585 80 685 M80 685 V960 M200 585 Q320 585 320 685 M320 685 V960"
        stroke="url(#grad6)"
        strokeWidth={2}
      />

      <defs>
        {/* Line Gradient */}
        <linearGradient id="grad6" gradientUnits="userSpaceOnUse">
          <stop stopColor="#521EDF" stopOpacity="0" offset="0%" />
          <stop stopColor="#521EDF" offset="50%" />
          <stop stopColor="#6344F5" offset="10%" />
          <stop stopColor="#6344F5" stopOpacity="0.5" offset="40%" />
        </linearGradient>

        {/* Glowing beam gradient */}
        <radialGradient id="beamGradient" cx="50%" cy="50%" r="50%">
          <stop stopColor="#521EDF" offset="0%" />
          <stop stopColor="#6344F5" offset="60%" />
          <stop stopColor="transparent" offset="100%" />
        </radialGradient>
      </defs>

      {/* USING <animate /> & <animateMotion /> tags */}

      {/* Centre Most Animation */}
      <circle
        cx="200"
        cy="0"
        r="4"
        fill="url(#beamGradient)"
        opacity="0"
        filter="url(#shadowFilter)"
      >
        <animate
          id="centreMostAnimationMobile"
          attributeName="cy"
          from="0"
          to="585"
          repeatCount="1"
          dur="4s"
          begin="centreToLeft.end; 0s"
        />

        <animate
          attributeName="opacity"
          from="0"
          to="0.7"
          dur="0.01s"
          begin="centreToLeft.end; 0s"
          fill="freeze"
        />
      </circle>

      <circle r="4" fill="url(#beamGradient)" opacity="0">
        <animateMotion
          id="centreToLeft"
          dur="3s"
          repeatCount="1"
          rotate="auto"
          begin="centreMostAnimationMobile.end"
        >
          <mpath href="#beam-path-mobile" />
        </animateMotion>

        <animate
          attributeName="opacity"
          from="0"
          to="0.7"
          dur="0.01s"
          begin="centreMostAnimationMobile.end"
          fill="freeze"
        />

        <animate
          attributeName="opacity"
          from="0.7"
          to="0"
          dur="0.01s"
          begin="centreToLeft.end - 0.01s"
          fill="freeze"
        />
      </circle>
      <path
        id="beam-path-mobile"
        d="M200 585 Q200 620 160 620 
        M160 620 H130 
        M130 620 Q90 620 90 685 
        M90 685 V960"
        fill="none"
      />

      <circle r="4" fill="url(#beamGradient)" opacity="0">
        <animateMotion
          id="centreToRight"
          dur="3s"
          repeatCount="1"
          rotate="auto"
          begin="centreMostAnimationMobile.end"
        >
          <mpath href="#beam-path2-mobile" />
        </animateMotion>

        <animate
          attributeName="opacity"
          from="0"
          to="0.7"
          dur="0.01s"
          begin="centreMostAnimationMobile.end"
          fill="freeze"
        />

        <animate
          attributeName="opacity"
          from="0.7"
          to="0"
          dur="0.01s"
          begin="centreToLeft.end - 0.01s"
          fill="freeze"
        />
      </circle>
      <path
        id="beam-path2-mobile"
        d=" M200 585 Q200 620 240 620
        M240 620 H270
        M270 620 Q310 620 310 685
        M310 685 V960"
        fill="none"
      />
    </svg>
  );
};

const SVGDesktop = () => {
  return (
    <svg
      width="100%"
      height="100%"
      viewBox="0 0 987 590"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="flex flex-shrink-0 "
    >
      <path
        d="M0 300 H910 M910 220 Q950,230 950 80 M950 80 Q 950 40 990 40 M40 990 H1050 M0 300 H910 M910 380 Q950, 380 950 520 M950 520 Q950 560 990 560 M990 560 H1060 M0 300 H910 Q960, 300 960 250 M960 250 Q960 220 990 220 M990 220 H1060 M0 300 H910 Q960, 300 960 370 M960 370 Q960 400 990 400 M990 400 H1060"
        stroke="url(#grad6)"
        strokeWidth={2}
        // d="M0 300 H950 V40 V560 M950 40 H985 M950 560 H985 M950 230 H985 M950 400 H985" - Straight Lines
      />
      <defs>
        {/* Line Gradient */}
        <linearGradient id="grad6" gradientUnits="userSpaceOnUse">
          <stop stopColor="#521EDF" stopOpacity="0" offset="0%" />
          <stop stopColor="#521EDF" offset="50%" />
          <stop stopColor="#6344F5" offset="10%" />
          <stop stopColor="#6344F5" stopOpacity="0.5" offset="40%" />
        </linearGradient>

        {/* Glowing beam gradient */}
        <radialGradient id="beamGradient" cx="50%" cy="50%" r="50%">
          <stop stopColor="#521EDF" offset="0%" />
          <stop stopColor="#6344F5" offset="60%" />
          <stop stopColor="transparent" offset="100%" />
        </radialGradient>
      </defs>

      {/* USING <animate /> & <animateMotion /> tags */}

      {/* Centre Most Animation */}
      <circle
        cx="0"
        cy="300"
        r="4"
        fill="url(#beamGradient)"
        opacity="0"
        filter="url(#shadowFilter)"
      >
        <animate
          id="centreMostAnimation"
          attributeName="cx"
          from="0"
          to="910"
          repeatCount="1"
          dur="4s"
          begin="centreToTopMost.end; 0s"
        />

        <animate
          attributeName="opacity"
          from="0"
          to="0.7"
          dur="0.01s"
          begin="centreToTopMost.end; 0s"
          fill="freeze"
        />
      </circle>

      {/* Centre To Top Most Animation */}
      <circle r="4" fill="url(#beamGradient)" opacity="0">
        <animateMotion
          id="centreToTopMost"
          dur="1.25s"
          repeatCount="1"
          rotate="auto"
          begin="centreMostAnimation.end"
        >
          <mpath href="#beam-path" />
        </animateMotion>

        <animate
          attributeName="opacity"
          from="0"
          to="0.7"
          dur="0.01s"
          begin="centreMostAnimation.end"
          fill="freeze"
        />

        <animate
          attributeName="opacity"
          from="0.7"
          to="0"
          dur="0.01s"
          begin="centreToTopMost.end - 0.01s"
          fill="freeze"
        />
      </circle>
      <path
        id="beam-path"
        d="M910 220 Q950,230 950 80 Q 950 40 990 40"
        fill="none"
      />

      {/* Centre to Bottom Most Animation */}
      <circle r="4" fill="url(#beamGradient)" opacity="0">
        <animateMotion
          id="centreToTopMost"
          dur="1.25s"
          repeatCount="1"
          rotate="auto"
          begin="centreMostAnimation.end"
        >
          <mpath href="#beam-path2" />
        </animateMotion>

        <animate
          attributeName="opacity"
          from="0"
          to="0.7"
          dur="0.01s"
          begin="centreMostAnimation.end"
          fill="freeze"
        />

        <animate
          attributeName="opacity"
          from="0.7"
          to="0"
          dur="0.01s"
          begin="centreToTopMost.end - 0.01s"
          fill="freeze"
        />
      </circle>
      <path
        id="beam-path2"
        d="M910 380 Q950, 380 950 520 Q950 560 990 560"
        fill="none"
      />

      {/* Centre to 2nd Top Most Aniamtion */}
      <circle r="4" fill="url(#beamGradient)" opacity="0">
        <animateMotion
          id="centreToTopMost"
          dur="1.25s"
          repeatCount="1"
          rotate="auto"
          begin="centreMostAnimation.end"
        >
          <mpath href="#beam-path3" />
        </animateMotion>

        <animate
          attributeName="opacity"
          from="0"
          to="0.7"
          dur="0.01s"
          begin="centreMostAnimation.end"
          fill="freeze"
        />

        <animate
          attributeName="opacity"
          from="0.7"
          to="0"
          dur="0.01s"
          begin="centreToTopMost.end - 0.01s"
          fill="freeze"
        />
      </circle>
      <path
        id="beam-path3"
        d="M910 300 Q960, 300 960 250 Q960 220 990 220"
        fill="none"
      />

      {/* Centre to 2nd Bottom Most Aniamtion */}
      <circle r="4" fill="url(#beamGradient)" opacity="0">
        <animateMotion
          id="centreToTopMost"
          dur="1.25s"
          repeatCount="1"
          rotate="auto"
          begin="centreMostAnimation.end"
        >
          <mpath href="#beam-path4" />
        </animateMotion>

        <animate
          attributeName="opacity"
          from="0"
          to="0.7"
          dur="0.01s"
          begin="centreMostAnimation.end"
          fill="freeze"
        />

        <animate
          attributeName="opacity"
          from="0.7"
          to="0"
          dur="0.01s"
          begin="centreToTopMost.end - 0.01s"
          fill="freeze"
        />
      </circle>
      <path
        id="beam-path4"
        d="M910 300 Q960, 300 960 370 Q960 400 990 400"
        fill="none"
      />

      {/* Using SVG Circles and Framer Motion (Not Completed - Could be Better */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 0, y: 300, opacity: 1 }} // Start position
      animate={{ x: [0, 950], y: [300, 300], opacity: [1, 1, 0] }} // Moves to the end
      transition={{
        duration: 2, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 4, // Adds 4s delay before each loop
        times: [0, 0.99, 1],
      }}
    /> */}

      {/* Centre to Top Most */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 950, y: 300, opacity: 0 }} // Start position
      animate={{
        x: [950, 950],
        y: [300, 40],
        opacity: [0, 1, 0],
      }} // Moves to the end
      transition={{
        duration: 0.5, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 5.5, // Adds 4s delay before each loop
        delay: 2,
      }}
    /> */}

      {/* Centre to 2nd Top */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 950, y: 300, opacity: 0 }} // Start position
      animate={{
        x: [950, 950],
        y: [300, 230],
        opacity: [0, 1, 0],
      }} // Moves to the end
      transition={{
        duration: 0.5, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 5.5, // Adds 4s delay before each loop
        delay: 2,
      }}
    /> */}

      {/* Centre to 2nd Bottom */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 950, y: 300, opacity: 0 }} // Start position
      animate={{
        x: [950, 950],
        y: [300, 400],
        opacity: [0, 1, 0],
      }} // Moves to the end
      transition={{
        duration: 0.5, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 5.5, // Adds 4s delay before each loop
        delay: 2,
      }}
    /> */}

      {/* Centre to Bottom Most */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 950, y: 300, opacity: 0 }} // Start position
      animate={{
        x: [950, 950],
        y: [300, 560],
        opacity: [0, 1, 0],
      }} // Moves to the end
      transition={{
        duration: 0.5, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 5.5, // Adds 4s delay before each loop
        delay: 2,
      }}
    /> */}

      {/* Top Most to Right */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 950, y: 40, opacity: 0 }} // Start position
      animate={{
        x: [950, 990],
        y: [40, 40],
        opacity: [0, 1, 1],
      }} // Moves to the end
      transition={{
        duration: 0.25, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 5.75, // Adds 4s delay before each loop
        delay: 2.5,
      }}
    /> */}

      {/* 2nd Top to Right */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 950, y: 40, opacity: 0 }} // Start position
      animate={{
        x: [950, 990],
        y: [230, 230],
        opacity: [0, 1, 1],
      }} // Moves to the end
      transition={{
        duration: 0.25, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 5.75, // Adds 4s delay before each loop
        delay: 2.5,
      }}
    /> */}

      {/* 2nd Bottom to Right */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 950, y: 40, opacity: 0 }} // Start position
      animate={{
        x: [950, 990],
        y: [400, 400],
        opacity: [0, 1, 1],
      }} // Moves to the end
      transition={{
        duration: 0.25, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 5.75, // Adds 4s delay before each loop
        delay: 2.5,
      }}
    /> */}

      {/* Bottom Most to Right */}
      {/* <motion.circle
      r="4"
      fill="url(#beamGradient2)"
      initial={{ x: 950, y: 40, opacity: 0 }} // Start position
      animate={{
        x: [950, 990],
        y: [560, 560],
        opacity: [0, 1, 1],
      }} // Moves to the end
      transition={{
        duration: 0.25, // Speed of motion
        ease: "linear",
        repeat: Infinity, // Infinite loop
        repeatDelay: 5.75, // Adds 4s delay before each loop
        delay: 2.5,
      }}
    /> */}
    </svg>
  );
};

export default InsightsSection;

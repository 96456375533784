// React Utils
import React, { useState, useEffect, useRef, useContext } from "react";

// Shadcn Navbar
import {
  NavigationMenu,
  NavigationMenuContent,
  NavigationMenuItem,
  NavigationMenuLink,
  NavigationMenuList,
  NavigationMenuTrigger,
  navigationMenuTriggerStyle,
} from "../../components/ui/shadcn-navbar.jsx";

// Icons
import { IoMoon, IoSunny } from "react-icons/io5";
import { FaBell } from "react-icons/fa";
import { FaUser } from "react-icons/fa";
import { BiLogOut } from "react-icons/bi";
import { BiSolidUser } from "react-icons/bi";

// Shadcn Utils
import { cn } from "../../utils/utils";

// React Router
import { useNavigate, Link } from "react-router-dom";

// Components
import NotificationDropdown from "../NotificationDropdown";
import { ThemeContext } from "../../context/ThemeContext";

// Contexts
import { useAuth } from "../../context/Authcontext";
import { useCaseContext } from "../../context/CaseContext";

import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "../../components/ui/popover";

import { useScroll, useMotionValueEvent } from "framer-motion";

// Images
import CaseMinisterLogo from "../../assets/images/navbar/legal-tech-logo.svg";
import FeaturesStarIcon from "../../assets/images/NewNavbar/FeaturesStarIcon.svg";
import LawizChatbotIcon from "../../assets/images/NewNavbar/LawizChatbotIcon.svg";
import CaseManagementIcon from "../../assets/images/NewNavbar/CaseManagementIcon.svg";
import JudgementSearchIcon from "../../assets/images/NewNavbar/JudgementSearchIcon.svg";
import DocumentAnalysisIcon from "../../assets/images/NewNavbar/DocumentAnalysisIcon.svg";
import CompanyIcon from "../../assets/images/NewNavbar/CompanyIcon.svg";
import AboutUsIcon from "../../assets/images/NewNavbar/AboutUsIcon.svg";
import FAQsIcon from "../../assets/images/NewNavbar/FAQsIcon.svg";
import PressReleaseIcon from "../../assets/images/NewNavbar/PressReleaseIcon.svg";
import ContactUsIcon from "../../assets/images/NewNavbar/ContactUsIcon.svg";

// Icons
import { FaArrowRightLong } from "react-icons/fa6";
import { RxArrowTopRight } from "react-icons/rx";
import { LuMenu } from "react-icons/lu";

// Shadcn Sheet
import {
  Sheet,
  SheetContent,
  SheetTrigger,
} from "../../components/ui/sheet.jsx";

// Popover
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

import { motion } from "framer-motion";

const NewNavbar = () => {
  const [scrolled, setScrolled] = useState(false);
  const [clicked, setClicked] = useState(false);
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [dropdownOpen1, setDropdownOpen1] = useState(false);
  const dropdownRef = useRef(null);
  const navigate = useNavigate();
  const [notificationDropdownOpen, setNotificationDropdownOpen] =
    useState(false);
  const notificationRef = useRef(null);
  const {
    unreadNotifications,
    setUnreadNotifications,
    fetchNotifications,
    handleCategoryClick,
    filteredNotifications,
  } = useCaseContext();
  const { login, isAuthenticated } = useAuth();

  // Handle Theme Toggling
  const { themeToggle, handleThemeToggle } = useContext(ThemeContext);

  // const handleScroll = debounce(() => {
  //   setScrolled(window.scrollY > 0);
  // }, 100);

  const access_token = localStorage.getItem("access_token");
  // const access_token = localStorage.getItem("access_token");

  useEffect(() => {
    if (access_token) {
      fetchNotifications();
    }
  }, [access_token]);

  // useEffect(() => {
  //   window.addEventListener("scroll", handleScroll);
  //   return () => {
  //     window.removeEventListener("scroll", handleScroll);
  //   };
  // }, []);

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setDropdownOpen(false);
        setDropdownOpen1(false);
      }
      if (
        notificationRef.current &&
        !notificationRef.current.contains(event.target)
      ) {
        setNotificationDropdownOpen(false);
      }
    };

    document.addEventListener("click", handleOutsideClick);
    return () => {
      document.removeEventListener("click", handleOutsideClick);
    };
  }, []);

  const handleClick = () => {
    setClicked(!clicked);
  };

  const handleLogout = () => {
    // sessionStorage.clear();
    localStorage.clear();
    navigate("/");
    setClicked(!clicked);
  };

  const toggleDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDropdownOpen(!dropdownOpen);
  };

  const toggleDropdown1 = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setDropdownOpen1(!dropdownOpen1);
  };
  const toggleNotificationDropdown = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setNotificationDropdownOpen(!notificationDropdownOpen);
  };

  const handleButtonClick = (path) => {
    if (!isAuthenticated) {
      sessionStorage.setItem("intendedURL", path);
      navigate("/signup");
    } else {
      navigate(path);
    }
  };

  const [active, setActive] = useState(null);

  // User Initials
  const userInfo =
    localStorage.getItem("user-info") &&
    JSON.parse(localStorage.getItem("user-info"));

  const firstInitial = !userInfo
    ? "C"
    : userInfo.first_name
    ? userInfo.first_name?.substring(0, 1)
    : userInfo[0]?.user?.first_name?.substring(0, 1);

  const lastInitial = !userInfo
    ? "M"
    : userInfo.last_name
    ? userInfo.last_name?.substring(0, 1)
    : userInfo[0]?.user?.last_name?.substring(0, 1);

  // const firstInitial = userInfo
  //   ? userInfo[0]?.user?.first_name !== ""
  //     ? userInfo[0]?.user?.first_name?.substring(0, 1)
  //     : "C"
  //   : "C";

  // const lastInitial = userInfo
  //   ? userInfo[0]?.user?.last_name !== ""
  //     ? userInfo[0]?.user?.last_name?.substring(0, 1)
  //     : "M"
  //   : "M";

  const { scrollY } = useScroll();
  const [hidden, setHidden] = useState(false);

  useMotionValueEvent(scrollY, "change", (latest) => {
    const prev = scrollY.getPrevious() || 0;
    if (latest > prev && latest > 50) {
      setHidden(true); // Hide navbar when scrolling down
    } else {
      setHidden(false); // Show navbar when scrolling up
    }
  });
  return (
    <motion.div
      initial={{ y: 0 }}
      animate={{ y: hidden ? "-100%" : "0%" }}
      transition={{ duration: 0.2, ease: "easeInOut" }}
      className={cn("fixed top-0 left-0 inset-x-0 w-full mx-auto z-50")}
    >
      <div className="gap-4 flex items-center justify-between w-full h-[72px] bg-white dark:bg-black px-20 max-lg:px-6 max-md:px-4">
        <div className="flex items-center gap-4">
          <Sheet>
            <SheetTrigger asChild>
              <LuMenu className="max-md:block hidden" />
            </SheetTrigger>
            <SheetContent side="left">
              <div className="flex flex-col gap-8 py-4">
                <div className="flex items-center gap-2">
                  <img src={CaseMinisterLogo} width="30px" alt="company logo" />
                  <h5 className="font-garamond font-medium text-xl">
                    CaseMinister
                  </h5>
                </div>
                <div className="flex flex-col gap-4">
                  <Accordion type="single" collapsible className="w-full">
                    <AccordionItem value="item-1">
                      <AccordionTrigger className="hover:no-underline font-poppins text-sm">
                        Features
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="flex flex-col gap-6">
                          <a href="/talkaboutcase" className="flex">
                            <div className="flex items-center gap-4">
                              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                                <img
                                  src={LawizChatbotIcon}
                                  alt="lawiz chatbot icon"
                                  width="30px"
                                />
                              </div>
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="font-poppins flex items-center justify-between gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                                  LaWiz Chatbot
                                  <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
                                </div>
                                <p className="font-poppins text-xs leading-snug text-muted-foreground">
                                  Get answers to your legal queries with our
                                  AI-driven chatbot.
                                </p>
                              </div>
                            </div>
                          </a>
                          <a href="/mycases" className="flex">
                            <div className="flex items-center gap-4">
                              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                                <img
                                  src={CaseManagementIcon}
                                  alt="case management icon"
                                  width="30px"
                                />
                              </div>
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="font-poppins flex items-center justify-between w-full gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                                  AI-Based Case Management
                                  <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
                                </div>
                                <p className="font-poppins text-xs leading-snug text-muted-foreground">
                                  Track your Case across all courts, get orders
                                  and Summaries, Talk to your case.
                                </p>
                              </div>
                            </div>
                          </a>
                          <a href="/judgement-search" className="flex">
                            <div className="flex items-center gap-4">
                              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                                <img
                                  src={JudgementSearchIcon}
                                  alt="judgement search icon"
                                  width="30px"
                                />
                              </div>
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="font-poppins flex items-center justify-between w-full gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                                  Judgement Search
                                  <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
                                </div>
                                <p className="font-poppins text-xs leading-snug text-muted-foreground">
                                  Search for your Case and get the insights of
                                  your Court Room Trials.
                                </p>
                              </div>
                            </div>
                          </a>
                          <a href="/upload_judgement" className="flex">
                            <div className="flex items-center gap-4">
                              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                                <img
                                  src={DocumentAnalysisIcon}
                                  alt="document analysis icon"
                                  width="30px"
                                />
                              </div>
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="font-poppins flex items-center justify-between w-full gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                                  Document Analysis
                                  <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
                                </div>
                                <p className="font-poppins text-xs leading-snug text-muted-foreground">
                                  Get AI-driven analysis and detailed insights
                                  for your documents.
                                </p>
                              </div>
                            </div>
                          </a>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                    <AccordionItem value="item-2">
                      <AccordionTrigger className="hover:no-underline font-poppins text-sm">
                        Company
                      </AccordionTrigger>
                      <AccordionContent>
                        <div className="flex flex-col gap-4">
                          <a href="/about-us" className="flex">
                            <div className="flex items-center gap-4">
                              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                                <img
                                  src={AboutUsIcon}
                                  alt="about us icon"
                                  width="30px"
                                />
                              </div>
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="font-poppins flex items-center justify-between w-full gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                                  About Us
                                  <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
                                </div>
                              </div>
                            </div>
                          </a>
                          <a href="/faq" className="flex">
                            <div className="flex items-center gap-4">
                              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                                <img
                                  src={FAQsIcon}
                                  alt="faqs icon"
                                  width="30px"
                                />
                              </div>
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="font-poppins flex items-center justify-between w-full gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                                  FAQs
                                  <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
                                </div>
                              </div>
                            </div>
                          </a>
                          {/* <a href="/#press-release" className="flex">
                            <div className="flex items-center gap-4">
                              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                                <img
                                  src={PressReleaseIcon}
                                  alt="press release icon"
                                  width="30px"
                                />
                              </div>
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="font-poppins flex items-center justify-between w-full gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                                  Press Release
                                  <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
                                </div>
                              </div>
                            </div>
                          </a> */}
                          <a href="/contactus" className="flex">
                            <div className="flex items-center gap-4">
                              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                                <img
                                  src={ContactUsIcon}
                                  alt="contact us icon"
                                  width="30px"
                                />
                              </div>
                              <div className="flex flex-1 flex-col gap-1">
                                <div className="font-poppins flex items-center justify-between w-full gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                                  Contact Us
                                  <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
                                </div>
                              </div>
                            </div>
                          </a>
                        </div>
                      </AccordionContent>
                    </AccordionItem>
                  </Accordion>
                  <div className="w-full border-b pb-4">
                    <a
                      href="/pricing"
                      className="font-poppins text-sm font-medium"
                    >
                      Pricing
                    </a>
                  </div>
                </div>
              </div>
            </SheetContent>
          </Sheet>
          <Link className="flex items-center gap-2" to="/">
            <img src={CaseMinisterLogo} alt="case minister logo" width="40" />
            <h5 className="font-garamond font-medium text-2xl max-lg:hidden">
              CaseMinister
            </h5>
          </Link>
        </div>
        <NavigationMenu className="dark:bg-black hidden md:block">
          <NavigationMenuList>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="font-poppins">
                Features
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <NavigationMenuLink>
                  <ul className="grid gap-3 p-2 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                    <li className="row-span-4">
                      <NavigationMenuLink asChild>
                        <div className="flex bg-[#F7F7FB] dark:bg-black h-full w-full select-none flex-col justify-start rounded-r-xl bg-gradient-to-b from-muted/50 to-muted px-5 py-6 no-underline outline-none focus:shadow-md">
                          <div className="flex flex-col gap-3">
                            <div className="flex items-center gap-4 font-poppins font-medium text-lg">
                              <div className="bg-white dark:bg-slate-900 size-14 flex items-center justify-center rounded-[11px] shadow-lg">
                                <img src={FeaturesStarIcon} alt="star icon" />
                              </div>
                              <div>
                                <p>Features</p>
                              </div>
                            </div>
                            <div>
                              <p className="font-poppins text-sm mb-1.5">
                                Smart AI-powered case management—track, analyze,
                                and automate with ease.
                              </p>
                            </div>
                            <div>
                              <Link
                                to="/mycases"
                                className="bg-[#521EDF] hover:bg-indigo-800 font-poppins font-medium text-sm rounded-[28px] py-2 px-6 text-white"
                              >
                                Check out
                              </Link>
                            </div>
                          </div>
                        </div>
                      </NavigationMenuLink>
                    </li>
                    <ListItem
                      href="/talkaboutcase"
                      title="LaWiz Chatbot"
                      imageSrc={LawizChatbotIcon}
                    >
                      Get answers to your legal queries with our AI-driven
                      chatbot.
                    </ListItem>
                    <ListItem
                      href="/mycases"
                      title="AI-Based Case Management"
                      imageSrc={CaseManagementIcon}
                    >
                      Track your Case across all courts, get orders and
                      Summaries, Talk to your case.
                    </ListItem>
                    <ListItem
                      href="/judgement-search"
                      title="Judgement Search"
                      imageSrc={JudgementSearchIcon}
                    >
                      Search for your Case and get the insights of your Court
                      Room Trials.
                    </ListItem>
                    <ListItem
                      href="/upload_judgement"
                      title="Document Analysis"
                      imageSrc={DocumentAnalysisIcon}
                    >
                      Get AI-driven analysis and detailed insights for your
                      documents.
                    </ListItem>
                  </ul>
                </NavigationMenuLink>
              </NavigationMenuContent>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <NavigationMenuTrigger className="font-poppins">
                Company
              </NavigationMenuTrigger>
              <NavigationMenuContent>
                <NavigationMenuLink>
                  <ul className="grid gap-3 p-2 md:w-[400px] lg:w-[500px] lg:grid-cols-[.75fr_1fr]">
                    <li className="row-span-4">
                      <NavigationMenuLink asChild>
                        <div className="flex bg-[#F7F7FB] dark:bg-black h-full w-full select-none flex-col justify-start rounded-r-xl bg-gradient-to-b from-muted/50 to-muted px-5 py-6 no-underline outline-none focus:shadow-md">
                          <div className="flex flex-col gap-3">
                            <div className="flex items-center gap-4 font-poppins font-medium text-lg">
                              <div className="bg-white dark:bg-slate-900 size-14 flex items-center justify-center rounded-[11px] shadow-lg">
                                <img src={CompanyIcon} alt="star icon" />
                              </div>
                              <div>
                                <p>Resource</p>
                              </div>
                            </div>
                            <div>
                              <p className="font-poppins text-sm mb-1.5">
                                Learn more about CaseMinister, explore FAQs
                              </p>
                            </div>
                          </div>
                        </div>
                      </NavigationMenuLink>
                    </li>
                    <ListItem
                      href="/about-us"
                      title="About Us"
                      imageSrc={AboutUsIcon}
                    ></ListItem>
                    <ListItem
                      href="/faq"
                      title="FAQs"
                      imageSrc={FAQsIcon}
                    ></ListItem>
                    {/* <ListItem
                      href="/#press-release"
                      title="Press Release"
                      imageSrc={PressReleaseIcon}
                    ></ListItem> */}
                    <ListItem
                      href="/contactus"
                      title="Contact Us"
                      imageSrc={ContactUsIcon}
                    ></ListItem>
                  </ul>
                </NavigationMenuLink>
              </NavigationMenuContent>
            </NavigationMenuItem>
            <NavigationMenuItem>
              <Link to="/pricing" className="font-poppins">
                <NavigationMenuLink className={navigationMenuTriggerStyle()}>
                  Pricing
                </NavigationMenuLink>
              </Link>
            </NavigationMenuItem>
          </NavigationMenuList>
        </NavigationMenu>
        <div>
          {!localStorage.getItem("isAuthenticated") ? (
            <>
              <div className="login-signup flex gap-3 items-center">
                <div className="select-none mr-2">
                  <div className="cursor-pointer" onClick={handleThemeToggle}>
                    {!themeToggle && <IoMoon className="text-lg" />}
                    {themeToggle && <IoSunny className="fill-white text-lg" />}
                  </div>
                </div>
                <a href="/signup">
                  {/* <button className="bg-light-500 dark:bg-dark-500 dark:text-gray-300 dark:border-dark-500 font-raleway text-sm font-semibold hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[2.5px_2.5px_0px_0px_#313134] transition duration-200 border px-3 py-1 hover:text-black">
                  Signup
                </button> */}
                  <button className="font-poppins group flex gap-1 items-center font-medium text-sm">
                    Signup{" "}
                    <div className="size-7 border rounded-full border-[#521EDF] dark:border-white group-hover:bg-[#521EDF] dark:group-hover:bg-black flex justify-center items-center">
                      <RxArrowTopRight className="font-semibold text-[#521EDF] dark:text-white group-hover:text-white group-hover:translate-x-0.5 group-hover:-translate-y-0.5 transition-transform duration-200" />
                    </div>
                  </button>
                </a>
                <a href="/login">
                  {/* <button className="bg-light-500 dark:bg-dark-500 dark:text-gray-300 dark:border-dark-500 font-raleway text-sm font-semibold hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[2.5px_2.5px_0px_0px_#313134] transition duration-200 border px-3 py-1 hover:text-black">
                  Login
                </button> */}
                  <button className="bg-[#521EDF] font-medium dark:bg-white dark:text-black text-sm hover:bg-indigo-800 text-white px-5 py-1.5 rounded-[32px]">
                    Login
                  </button>
                </a>
              </div>
            </>
          ) : (
            <div className="login-signup flex flex-row items-center max-lg:gap-4 gap-[30px]">
              {/* ({unreadNotifications.length}) */}
              <div className="select-none">
                <div className="cursor-pointer" onClick={handleThemeToggle}>
                  {!themeToggle && <IoMoon className="text-xl" />}
                  {themeToggle && <IoSunny className="fill-white text-xl" />}
                </div>
              </div>
              <div className="flex items-center max-lg:gap-4 gap-[30px]">
                <li
                  className="dropdown list-none cursor-pointer relative"
                  onClick={toggleNotificationDropdown}
                >
                  <Popover>
                    <PopoverTrigger>
                      <FaBell className="text-xl dark:text-whiteborder mt-1.5" />
                    </PopoverTrigger>
                    {unreadNotifications.length > 0 && (
                      <p className="flex justify-center items-center text-[10px] font-semibold absolute bg-red-700 text-white rounded-full h-2.5 w-2.5 top-[-1px] left-3">
                        {/* {unreadNotifications.length} */}
                      </p>
                    )}
                    <PopoverContent className="w-0 h-0">
                      <NotificationDropdown
                        isOpen={notificationDropdownOpen}
                        toggleDropdown={toggleNotificationDropdown}
                      />
                    </PopoverContent>
                  </Popover>
                </li>
                <li className="list-none">
                  <Popover>
                    <PopoverTrigger>
                      {/* <a href="/user-profile"> */}
                      {firstInitial && lastInitial ? (
                        <div className="bg-gray-300 dark:bg-gray-600 text-sm max-sm:text-xs rounded-full p-2 flex select-none">
                          <p>{firstInitial}</p>
                          <p>{lastInitial}</p>
                        </div>
                      ) : (
                        <FaUser className="text-xl dark:text-white" />
                      )}
                      {/* </a> */}
                    </PopoverTrigger>
                    <PopoverContent
                      sideOffset={12}
                      className="w-28 py-1 px-1 flex flex-col dark:text-gray-200 dark:bg-dark-600 bg-light-600 text-gray-700"
                    >
                      <a
                        className="flex gap-2 items-center hover:dark:bg-dark-400 hover:bg-gray-200 px-2 py-1.5 rounded-md"
                        href="/user-profile"
                      >
                        <BiSolidUser />
                        <p>Account</p>
                      </a>
                      <a
                        className="flex gap-2 items-center hover:dark:bg-dark-400 hover:bg-gray-200 px-2 py-1.5 rounded-md"
                        href="/"
                        onClick={handleLogout}
                      >
                        <BiLogOut />
                        <p>Logout</p>
                      </a>
                    </PopoverContent>
                  </Popover>
                </li>
              </div>
              {/* <a href="/" onClick={handleLogout} className="select-none">
              <button className="bg-light-500 dark:bg-dark-500 dark:text-gray-300 dark:border-dark-500 font-raleway text-sm font-semibold hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[2.5px_2.5px_0px_0px_#313134] transition duration-200 border px-3 py-1 hover:text-black">
                Logout
              </button>
            </a> */}
            </div>
          )}
        </div>
      </div>
    </motion.div>
  );
};

export default NewNavbar;

const ListItem = React.forwardRef(
  ({ className, title, children, imageSrc, href, ...props }, ref) => {
    return (
      <li>
        <NavigationMenuLink asChild>
          <Link
            ref={ref}
            to={href}
            className={cn(
              "group flex flex-1 items-center gap-4 select-none space-y-0 rounded-md p-3 leading-none no-underline outline-none transition-colors hover:bg-accent hover:text-accent-foreground focus:bg-accent focus:text-accent-foreground",
              className
            )}
            {...props}
          >
            <div className="flex-shrink-0">
              <div className="bg-white dark:bg-slate-900 size-[60px] flex justify-center items-center rounded-[15px] shadow-md">
                <img src={imageSrc} alt={imageSrc} width="30px" />
              </div>
            </div>
            <div className="flex flex-1 flex-col gap-1">
              <div className="font-poppins flex items-center gap-3 font-semibold text-[#4A3AFF] dark:text-indigo-500 leading-none">
                {title}{" "}
                <FaArrowRightLong className="flex-shrink-0 transition-transform duration-300 group-hover:translate-x-2 mr-4" />
              </div>
              <p className="font-poppins text-xs leading-snug text-muted-foreground">
                {children}
              </p>
            </div>
          </Link>
        </NavigationMenuLink>
      </li>
    );
  }
);
ListItem.displayName = "ListItem";

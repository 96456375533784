// React Utils
import React from "react";

// Outlext Context
import { useOutletContext } from "react-router-dom";

// Recharts PieChart
import { PieChart, Pie, Cell, Legend, Tooltip } from "recharts";

import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../components/ui/table";

// Get Width
import { useWindowWidth } from "@react-hook/window-size";

const BailAnalysis = () => {
  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();

  const COLORS = ["#16A34A", "#DC2626"];

  return (
    <div className="flex flex-col gap-10 px-4">
      {caseDetails?.analytics?.["Judges"].map((judge, index) => (
        <>
          {judge?.["Total Cases"] > 0 ? (
            <React.Fragment key={index}>
              {/* Heading Section */}
              <div>
                <p className="font-raleway font-semibold text-xl max-md:text-lg">
                  Case Distribution of Judge {judge?.["Judge Name"]}
                </p>
              </div>
              {/* Total Cases Section */}
              <div className="flex px-10 max-md:px-0 max-md:flex-col max-md:gap-6 justify-between">
                {/* Left Section */}
                <div className="flex flex-col gap-4 w-[500px] max-md:w-full">
                  <div className="border px-3 pt-3 pb-8 rounded-md border-black/10 dark:border-gray-600">
                    <div className="flex flex-col gap-1">
                      <p className="font-raleway font-semibold text-lg opacity-50 dark:opacity-100">
                        Total Cases
                      </p>
                      <p className="font-roboto font-medium text-5xl max-md:text-4xl">
                        {judge?.["Total Cases"]}
                      </p>
                    </div>
                  </div>
                  <div className="flex gap-4">
                    <div className="border px-3 pt-3 pb-8 w-1/2 rounded-md border-black/10 dark:border-gray-600">
                      <div className="flex flex-col gap-1">
                        <p className="font-raleway font-semibold text-lg opacity-50 dark:opacity-100">
                          Bail Granted
                        </p>
                        <p className="font-roboto font-medium text-5xl max-md:text-4xl text-green-600 dark:text-green-500">
                          {judge?.["Bail Granted Cases"]}
                        </p>
                      </div>
                    </div>
                    <div className="border px-3 pt-3 pb-8 w-1/2 rounded-md border-black/10 dark:border-gray-600">
                      <div className="flex flex-col gap-1">
                        <p className="font-raleway font-semibold text-lg opacity-50 dark:opacity-100">
                          Bail Denied
                        </p>
                        <p className="font-roboto font-medium text-5xl max-md:text-4xl text-red-600 dark:text-red-500">
                          {judge?.["Bail Denied Cases"]}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Right Section */}
                <div className="select-none relative mx-auto">
                  <DrawPieChart data={judge} COLORS={COLORS} />
                  <div className="absolute top-1/2 left-1/2 translate-x-[-50%] translate-y-[0%] text-center">
                    <p className="font-roboto font-semibold text-xl max-md:text-sm">
                      Total Cases
                    </p>
                    <p className="font-roboto font-semibold text-xl max-md:text-sm">
                      ({judge?.["Total Cases"]})
                    </p>
                  </div>
                </div>
              </div>
              <hr />
            </React.Fragment>
          ) : (
            <p>No analytics details found for judge {judge?.["Judge Name"]}</p>
          )}
        </>
      ))}
      {/* Section Insights Section */}
      <div className="flex flex-col gap-8 mt-4">
        <div>
          <p className="font-raleway font-semibold text-2xl">
            Section Insights
          </p>
        </div>
        {caseDetails?.analytics?.["Section Insights"].length > 0 ? (
          <div className="px-10 max-md:px-0 text-nowrap">
            <DrawTable caseDetails={caseDetails} />
          </div>
        ) : (
          "No insights available."
        )}
      </div>

      <hr />
    </div>
  );
};

const DrawPieChart = ({ data, COLORS }) => {
  const dataRequiredForm = [
    {
      name: "Bail Granted",
      value: data?.["Bail Granted Cases"],
    },
    {
      name: "Bail Denied",
      value: data?.["Bail Denied Cases"],
    },
  ];

  const CustomLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 1.8;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fontSize={`${onlyWidth >= 768 ? "14px" : "9px"}`} // Font size
        fontFamily="Arial, sans-serif" // Font family
        fontWeight="bold" // Font weight
        textAnchor={x > cx ? "start" : "end"} // Horizontal alignment
        dominantBaseline="central" // Vertical alignment
        className="fill-gray-700 dark:fill-gray-200"
      >
        {`${dataRequiredForm[index].name} (${(percent * 100).toFixed(0)}%)`}
      </text>
    );
  };

  const onlyWidth = useWindowWidth(); // Getting Sceen Width for Easy Mobile Changes

  return (
    <PieChart
      width={onlyWidth >= 768 ? 600 : 400}
      height={onlyWidth >= 768 ? 400 : 300}
    >
      <Legend verticalAlign="top" height={40} />
      <Tooltip />
      <Pie
        // isAnimationActive={false}
        data={dataRequiredForm}
        dataKey="value"
        nameKey="name"
        cx="50%"
        cy="50%"
        outerRadius={onlyWidth >= 768 ? 120 : 80}
        innerRadius={onlyWidth >= 768 ? 80 : 55}
        fill="#82ca9d"
        startAngle={135}
        endAngle={495}
        paddingAngle={4}
        label={CustomLabel}
      >
        {dataRequiredForm.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
        ))}
      </Pie>
    </PieChart>
  );
};

const DrawTable = ({ caseDetails }) => {
  return (
    <Table className="font-bold">
      <TableHeader className="bg-gray-100 dark:bg-dark-450">
        <TableRow>
          <TableHead className="text-left border-r">Sections & Acts</TableHead>
          <TableHead className="text-center border-r">Total Cases</TableHead>
          <TableHead className="text-center border-r">Granted Cases</TableHead>
          <TableHead className="text-center">Denied Cases</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {caseDetails?.analytics?.["Section Insights"].map((section, index) => (
          <TableRow key={index}>
            <TableCell className="text-left border-r">
              {section?.["Section"]}
            </TableCell>
            <TableCell className="text-center border-r">
              {section?.["Total Cases"]}
            </TableCell>
            <TableCell className="text-center border-r">
              {section?.["Granted Cases"]}
              <span className="text-green-500 font-medium ml-2">
                (
                {(
                  (section?.["Granted Cases"] / section?.["Total Cases"]) *
                  100
                ).toFixed(2)}
                %)
              </span>
            </TableCell>
            <TableCell className="text-center">
              {section?.["Denied Cases"]}
              <span className="text-red-500 font-medium ml-2">
                (
                {(
                  (section?.["Denied Cases"] / section?.["Total Cases"]) *
                  100
                ).toFixed(2)}
                %)
              </span>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default BailAnalysis;

// React utils
import React, { useEffect, useMemo, useState } from "react";

// Axios
import axiosInstance from "../../utils/axiosInstance";

// React Router
import { useOutletContext } from "react-router-dom";

// Loader Component
import Loader from "./Loader";

// Icons
import FolderIcon from "../../assets/images/HighCourtDelhi/FolderIcon.png";
import { AiOutlineDelete } from "react-icons/ai";
import { TbEdit } from "react-icons/tb";

// ShadCN Tooltip
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../components/ui/tooltip";

const Notes = () => {
  const [notes, setNotes] = useState([]); // Array
  const [notesLoader, setNotesLoader] = useState(false); // Boolean
  const [addNoteModal, setAddNoteModal] = useState(false); // Boolean
  const [noteDeleted, setNoteDeleted] = useState(false); // Boolean
  const [noteData, setNoteData] = useState({}); // Object
  const [editNoteModal, setEditNoteModal] = useState(false); // Boolean

  // Data from HighCourtDelhiDetails Component Outlet
  const { caseDetails } = useOutletContext();
  // console.log(caseDetails);

  const access_token = localStorage.getItem("access_token"); // Authorization Token

  // Fetching Case Notes
  const fetchCaseNotes = useMemo(
    () => async () => {
      setNotesLoader(true);
      try {
        if (caseDetails?.court === "delhi") {
          const response = await axiosInstance.get(
            `highcourt/notes?case_id=${caseDetails.id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response.data);
          setNotes(response.data);
        } else if (caseDetails?.court2 === "allhighcourt") {
          const response = await axiosInstance.get(
            `allhighcourt/notes?case_id=${caseDetails.id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response.data);
          setNotes(response.data);
        } else if (caseDetails?.court === "punjab") {
          const response = await axiosInstance.get(
            `highcourt/notes?case_id=${caseDetails.id}&court=${caseDetails?.court}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response.data);
          setNotes(response.data);
        } else if (caseDetails?.court === "district") {
          const response = await axiosInstance.get(
            `casedetail/notes/?case_id=${caseDetails.id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response.data);
          setNotes(response.data);
        } else if (caseDetails?.court === "supreme") {
          const response = await axiosInstance.get(
            `sccourt/notes/?case_id=${caseDetails.id}`,
            {
              headers: {
                Authorization: `Bearer ${access_token}`,
                "Content-Type": "application/json",
              },
            }
          );
          console.log(response.data);
          setNotes(response.data);
        }
      } catch (err) {
        console.log(err);
      } finally {
        setNotesLoader(false);
      }
    },
    [access_token, caseDetails?.id, caseDetails?.court]
  );

  useEffect(() => {
    fetchCaseNotes();
  }, [fetchCaseNotes, addNoteModal, noteDeleted, editNoteModal]);

  // Opening the Modal to add a Note
  const openNoteModal = () => {
    setAddNoteModal(true);
  };

  // Handling of the Submission of a Note
  const handleNoteSubmit = async (e) => {
    e.preventDefault();

    if (caseDetails?.court === "district") {
      const form = e.target;
      const formData = new FormData(form);
      formData.append("case_id", caseDetails.id);

      const data = {};
      for (let keyValue of formData.entries()) {
        data[keyValue[0]] = keyValue[1];
      }

      try {
        const response = await axiosInstance.post("casedetail/notes/", data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(response);
      } catch (err) {
        console.log(err);
      } finally {
        setAddNoteModal(false);
      }
    } else if (caseDetails?.court === "supreme") {
      const form = e.target;
      const formData = new FormData(form);
      formData.append("case_id", caseDetails.id);

      const data = {};
      for (let keyValue of formData.entries()) {
        data[keyValue[0]] = keyValue[1];
      }

      try {
        const response = await axiosInstance.post("sccourt/notes/", data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(response);
      } catch (err) {
        console.log(err);
      } finally {
        setAddNoteModal(false);
      }
    } else if (caseDetails?.court2 === "allhighcourt") {
      const form = e.target;
      const formData = new FormData(form);
      formData.append("case_id", caseDetails.id);

      const data = {};
      for (let keyValue of formData.entries()) {
        data[keyValue[0]] = keyValue[1];
      }

      try {
        const response = await axiosInstance.post("allhighcourt/notes/", data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(response);
      } catch (err) {
        console.log(err);
      } finally {
        setAddNoteModal(false);
      }
    } else {
      const form = e.target;
      const formData = new FormData(form);
      if (caseDetails?.court === "punjab") {
        formData.append("punjabhighcourtcase", caseDetails.id);
      } else {
        formData.append("highcourtcase", caseDetails.id); // Delhi Note Add
      }
      const data = {};
      for (let keyValue of formData.entries()) {
        data[keyValue[0]] = keyValue[1];
      }

      try {
        const response = await axiosInstance.post("highcourt/notes/", data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(response);
      } catch (err) {
        console.log(err);
      } finally {
        setAddNoteModal(false);
      }
    }
  };

  // Deleting a Note
  const handleDeleteNote = async (id) => {
    if (caseDetails?.court === "district") {
      try {
        await axiosInstance.delete(`casedetail/notes/${id}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        setNoteDeleted((prev) => !prev);
      } catch (err) {
        console.log(err);
      }
    } else if (caseDetails?.court === "supreme") {
      try {
        await axiosInstance.delete(`sccourt/notes/${id}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        setNoteDeleted((prev) => !prev);
      } catch (err) {
        console.log(err);
      }
    } else if (caseDetails?.court2 === "allhighcourt") {
      try {
        await axiosInstance.delete(`allhighcourt/notes/${id}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        setNoteDeleted((prev) => !prev);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        await axiosInstance.delete(`highcourt/notes/${id}/`, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        setNoteDeleted((prev) => !prev);
      } catch (err) {
        console.log(err);
      }
    }
  };

  // Editing a Note
  const handleEditNote = async (e, id) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }

    if (caseDetails?.court === "district") {
      try {
        const response = await axiosInstance.put(
          `casedetail/notes/${id}/`,
          data,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        setEditNoteModal(false);
      } catch (err) {
        console.log(err);
      }
    } else if (caseDetails?.court2 === "allhighcourt") {
      try {
        const response = await axiosInstance.put(
          `allhighcourt/notes/${id}/`,
          data,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        setEditNoteModal(false);
      } catch (err) {
        console.log(err);
      }
    } else if (caseDetails?.court === "supreme") {
      try {
        const response = await axiosInstance.put(`sccourt/notes/${id}/`, data, {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        });

        console.log(response);
        setEditNoteModal(false);
      } catch (err) {
        console.log(err);
      }
    } else {
      try {
        const response = await axiosInstance.put(
          `highcourt/notes/${id}/`,
          data,
          {
            headers: {
              Authorization: `Bearer ${access_token}`,
              "Content-Type": "application/json",
            },
          }
        );

        console.log(response);
        setEditNoteModal(false);
      } catch (err) {
        console.log(err);
      }
    }
  };

  if (notesLoader) {
    return (
      <div className="flex justify-center py-20">
        <Loader />
      </div>
    );
  }

  if (!(notes.length > 0)) {
    return (
      <div className="flex flex-col gap-8 pt-6 pb-12 border-b border-b-[#d9d9d9] dark:border-b-gray-600">
        {addNoteModal ? (
          <AddNoteModal handleNoteSubmit={handleNoteSubmit} />
        ) : (
          <div className="flex flex-col items-center text-center gap-8 py-6">
            <img src={FolderIcon} alt="folder icon" />
            <div>
              <p>You have not added any Notes yet.</p>
              <p>Click below to add Notes.</p>
            </div>
            <button
              className="bg-dark-600 dark:bg-light-600 text-gray-200 dark:text-gray-700 px-3 py-1"
              onClick={openNoteModal}
            >
              Add Notes
            </button>
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="flex flex-col gap-4 px-12 py-6 border-b border-b-[#d9d9d9] dark:border-b-gray-600">
      {addNoteModal ? (
        <AddNoteModal handleNoteSubmit={handleNoteSubmit} />
      ) : editNoteModal ? (
        <EditNoteModal
          setEditNoteModal={setEditNoteModal}
          noteData={noteData}
          setNoteData={setNoteData}
          handleEditNote={handleEditNote}
        />
      ) : (
        <>
          <button
            onClick={openNoteModal}
            className="self-end text-sm font-medium bg-dark-600 text-gray-200 dark:bg-light-600 dark:text-gray-700 px-3 py-2"
          >
            Add New Note
          </button>
          {notes.map((note) => (
            <div
              key={note.id}
              className="flex flex-col gap-3 border border-[#d9d9d9] dark:border-gray-600 px-6 pt-4 pb-12 rounded-lg"
            >
              <div className="flex items-center justify-between text-xl">
                <p className="font-semibold">{note.title}</p>
                <div className="flex gap-4">
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <TbEdit
                          onClick={() => {
                            setEditNoteModal(true);
                            setNoteData({
                              title: note.title,
                              description: note.description,
                              id: note.id,
                            });
                          }}
                          className="cursor-pointer select-none hover:opacity-60"
                        />
                      </TooltipTrigger>
                      <TooltipContent>Edit</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                  <TooltipProvider>
                    <Tooltip>
                      <TooltipTrigger>
                        <AiOutlineDelete
                          onClick={() => handleDeleteNote(note.id)}
                          className="cursor-pointer select-none hover:opacity-60"
                        />
                      </TooltipTrigger>
                      <TooltipContent>Delete</TooltipContent>
                    </Tooltip>
                  </TooltipProvider>
                </div>
              </div>
              <p>{note.description}</p>
            </div>
          ))}
        </>
      )}
    </div>
  );
};

const AddNoteModal = ({ handleNoteSubmit }) => {
  return (
    <form className="flex flex-col mx-10 gap-4" onSubmit={handleNoteSubmit}>
      <div className="flex flex-col border border-[#d9d9d9] dark:border-gray-600 rounded-lg">
        <input
          className="rounded-t-lg border-b font-semibold px-4 py-3 border-b-[#d9d9d9] dark:bg-dark-450 dark:text-white dark:border-gray-600 outline-none focus:outline-none focus:border-b-[#d9d9d9]"
          placeholder="Add Title"
          name="title"
          required
        />
        <textarea
          className="rounded-b-lg h-[185px] px-4 py-3 outline-none focus:outline-none dark:bg-dark-450 dark:text-white dark:border-gray-600"
          placeholder="Add Description"
          name="description"
          required
        />
      </div>
      <div className="self-end">
        <button
          type="submit"
          className="bg-dark-600 dark:bg-white dark:text-black text-white text-sm font-medium px-5 py-2"
        >
          Add Note
        </button>
      </div>
    </form>
  );
};

const EditNoteModal = ({
  setEditNoteModal,
  noteData,
  setNoteData,
  handleEditNote,
}) => {
  return (
    <form
      className="flex flex-col mx-10 gap-4"
      onSubmit={(e) => handleEditNote(e, noteData.id)}
    >
      <div className="flex flex-col border border-[#d9d9d9] dark:border-gray-600 rounded-lg">
        <input
          className="rounded-t-lg border-b font-semibold px-4 py-3 border-b-[#d9d9d9] dark:bg-dark-450 dark:text-white dark:border-gray-600 outline-none focus:outline-none focus:border-b-[#d9d9d9]"
          placeholder="Add Title"
          name="title"
          value={noteData.title}
          onChange={(e) => setNoteData({ ...noteData, title: e.target.value })}
          required
        />
        <textarea
          className="rounded-b-lg h-[185px] px-4 py-3 outline-none focus:outline-none dark:bg-dark-450 dark:text-white dark:border-gray-600"
          placeholder="Add Description"
          name="description"
          value={noteData.description}
          onChange={(e) =>
            setNoteData({ ...noteData, description: e.target.value })
          }
          required
        />
      </div>
      <div className="self-end flex gap-2">
        <button
          type="button"
          onClick={() => setEditNoteModal(false)}
          className="bg-red-500 dark:bg-red-800 dark:text-white text-white text-sm font-medium px-5 py-2"
        >
          Cancel
        </button>
        <button
          type="submit"
          className="bg-dark-600 dark:bg-white dark:text-black text-white text-sm font-medium px-5 py-2"
        >
          Save
        </button>
      </div>
    </form>
  );
};

export default Notes;

// React Utils
import React from "react";

// ShadcnUI Input
import { Input } from "../../components/ui/input";

// Icons
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaXTwitter,
} from "react-icons/fa6";

// Images
import CMLogo from "../../assets/images/navbar/legal-tech-logo.svg";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <div className="py-20 bg-black">
      <div className="flex flex-col gap-10">
        <div className="flex justify-between px-36 max-sm:px-4 max-sm:flex-col">
          <div className="flex gap-24 max-sm:order-2">
            <div className="flex flex-col gap-6">
              <p className="font-poppins font-semibold text-lg text-white">
                Assets
              </p>
              <div className="flex flex-col gap-5 font-poppins tracking-[-1px] font-medium text-[#8c8f9d]">
                {/* <p>Feature</p>
                <p>Company</p> */}
                <Link to="/" className="hover:underline hover:opacity-80">
                  Home
                </Link>
                <Link
                  to="/pricing"
                  className="hover:underline hover:opacity-80"
                >
                  Pricing
                </Link>
                <Link to="/faq" className="hover:underline hover:opacity-80">
                  FAQs
                </Link>
                <Link
                  to="/terms-and-conditions"
                  className="hover:underline hover:opacity-80"
                >
                  Terms of Service
                </Link>
                <Link
                  to="/privacy-policy"
                  className="hover:underline hover:opacity-80"
                >
                  Privacy Policy
                </Link>
              </div>
            </div>
            <div className="flex flex-col gap-6">
              <p className="font-poppins font-semibold text-lg text-white">
                Need a hand ?
              </p>
              <div className="flex flex-col gap-5 font-poppins tracking-[-1px] font-medium text-[#8c8f9d]">
                <Link
                  to="/contactus"
                  className="hover:underline hover:opacity-80"
                >
                  Help and Support
                </Link>
                <Link to="/signup" className="hover:underline hover:opacity-80">
                  Signup
                </Link>
              </div>
            </div>
          </div>
          {/* <div className="flex flex-col gap-4 mt-10 max-sm:mt-0 max-sm:mb-10 max-sm:order-1">
            <p className="font-poppins font-medium text-[13px] text-white">
              Join our community to receive updates
            </p>
            <form className="flex gap-16 max-sm:gap-6 max-sm:flex-col">
              <Input
                placeholder="Enter your email"
                className="rounded-[160px] py-6 px-5 min-w-[240px]"
              />
              <button className="bg-white dark:text-black max-sm:py-2 px-5 rounded-[160px] font-poppins font-medium">
                Subscribe
              </button>
            </form>
            <p className="font-poppins text-center text-[#A7A7A7] text-xs">
              By subscribing, you agree to our Privacy Policy
            </p>
          </div> */}
        </div>
        <div className="flex justify-between max-sm:justify-center items-center gap-10 max-sm:mx-4 max-sm:px-2 mx-20 border-t border-b px-10 border-gray-500 py-10">
          <div className="flex gap-4 items-center">
            <img src={CMLogo} width={"50px"} alt="Case Minister Logo" />
            <h5 className="font-garamond font-medium text-3xl text-white">
              CaseMinister
            </h5>
          </div>
          <div className="flex gap-5 items-center max-sm:hidden">
            {/* <FaFacebook className="text-white text-xl" /> */}
            <a
              href="https://www.instagram.com/caseminister?igsh=MWpwamkzNWI3dTdveA=="
              target="_blank"
              rel="noreferrer"
            >
              <FaInstagram className="text-white text-xl" />
            </a>
            <a
              href="https://www.linkedin.com/company/caseminister/"
              target="_blank"
              rel="noreferrer"
            >
              <FaLinkedin className="text-white text-xl" />
            </a>
            <a
              href="https://x.com/CaseMinister?t=mmMKJyx7SNCwxGKTPTMDkQ&s=08"
              target="_blank"
              rel="noreferrer"
            >
              <FaXTwitter className="text-white text-xl" />
            </a>
            {/* <FaYoutube className="text-white text-xl" /> */}
          </div>{" "}
        </div>
        <div className="font-poppins font-medium max-sm:text-xs max-sm:justify-center max-sm:gap-y-6 text-sm flex max-sm:flex-wrap justify-between items-center gap-10 px-20 max-sm:px-4 max-sm:gap-4 text-[#8c8f9d]">
          <div className="flex gap-6">
            <Link
              to="/privacy-policy"
              className="hover:underline hover:opacity-80"
            >
              Privacy Policy
            </Link>
            <Link
              to="/terms-and-conditions"
              className="hover:underline hover:opacity-80"
            >
              Terms of Service
            </Link>
            {/* <Link to="/">Cookie Policy</Link> */}
          </div>
          <div>
            <p>© 2025 CaseMinister. All rights reserved</p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;

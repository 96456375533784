// React Utils
import React from "react";

// ShadcnUI Accordian
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../components/ui/accordion";

const FAQSection = () => {
  const questionsAndAnswers1 = [
    {
      id: 1,
      question:
        "Is CaseMinister suitable for both individuals and legal professionals?",
      answer:
        "Yes, CaseMinister is designed for both individuals seeking legal assistance and legal professionals who need AI-powered tools for research, document analysis, and case management.",
    },
    {
      id: 2,
      question:
        "How accurate is CaseMinister’s AI in providing legal insights?",
      answer:
        "CaseMinister leverages advanced AI algorithms trained on vast legal data to provide highly accurate insights, though users should verify critical legal matters with a licensed professional.",
    },
    {
      id: 3,
      question: "Can I upload and analyze my legal documents on CaseMinister?",
      answer:
        "Yes, CaseMinister allows users to upload legal documents, and its AI analyzes them for key information, summaries, and potential insights.",
    },
    {
      id: 4,
      question:
        "Is my data and case information secure on the CaseMinister platform?",
      answer:
        "Yes, CaseMinister follows strict security protocols, including encryption and data protection measures, to ensure the privacy and confidentiality of user information.",
    },
  ];

  const questionsAndAnswers2 = [
    {
      id: 1,
      question:
        "Can CaseMinister help with legal research and case precedents?",
      answer:
        "Absolutely. The platform provides access to legal research, case precedents, and AI-powered analysis to assist professionals in finding relevant case laws and legal arguments.",
    },
    {
      id: 2,
      question:
        "Is there a mobile app for CaseMinister, or is it only web-based?",
      answer:
        "CaseMinister is primarily web-based, but a mobile is in development for easier access to legal tools on the go.",
    },
    {
      id: 3,
      question: "What types of cases can be tracked using CaseMinister?",
      answer:
        "Users can track civil, criminal, corporate, and family law cases, among others. The platform is designed to support a wide range of legal domains.",
    },
    {
      id: 4,
      question:
        "Is CaseMinister available for use in multiple countries, or is it region-specific?",
      answer:
        "While CaseMinister may focus on specific jurisdictions, its AI-driven insights and case management tools can be useful globally. Availability depends on regional legal databases and integrations.",
    },
  ];

  return (
    <div className="flex justify-center pt-32 pb-32 max-sm:p-10">
      <div className="flex flex-col gap-20">
        <div className="flex flex-col gap-10">
          <h4 className="font-sora font-semibold text-5xl max-sm:text-3xl text-[#323232] dark:text-white text-center">
            Frequently asked questions
          </h4>
          <p className="font-poppins text-center text-[#636363] dark:text-[#9c9c9c] text-sm">
            Can’t find the answer you’re looking for? Reach out to customer
            support team.
          </p>
        </div>
        <div className="grid grid-cols-2 max-sm:grid-cols-1 gap-x-10 max-sm:space-x-0 space-x-4 w-full">
          <div>
            <Accordion
              type="single"
              collapsible
              className="w-full flex flex-col gap-4 max-w-[537px]"
            >
              {questionsAndAnswers1.map((item) => (
                <AccordionItem value={`item-${item.id}`} key={item.id}>
                  <AccordionTrigger className="max-sm:text-left text-left">
                    {item.question}
                  </AccordionTrigger>
                  <AccordionContent className="tracking-wide">
                    {item.answer}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
          <div>
            <Accordion
              type="single"
              collapsible
              className="w-full flex flex-col gap-4 max-w-[537px]"
            >
              {questionsAndAnswers2.map((item) => (
                <AccordionItem value={`item-${item.id}`} key={item.id}>
                  <AccordionTrigger className="max-sm:text-left text-left">
                    {item.question}
                  </AccordionTrigger>
                  <AccordionContent className="tracking-wide">
                    {item.answer}
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FAQSection;

// React Utils
import React from "react";

// Framer Motion Utils
import { motion } from "framer-motion";

// ShadcnUI Tabs
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "../../components/ui/tabs";

// Videos
// import ChatbotVideo from "../../assets/images/NewLandingPage/LawizChatbotProductFeature.mp4";
// import CaseManagementVideo from "../../assets/images/NewLandingPage/CaseManagementProductFeature.mp4";
// import DocumentAnalysisVideo from "../../assets/images/NewLandingPage/DocumentAnalysisProductFeature.mp4";

// Thumbnails
import LawizThumbnail from "../../assets/images/NewLandingPage/LawizThumbnail.png";
import CaseManagementThumbnail from "../../assets/images/NewLandingPage/CaseManagementThumbnail.png";
import DocumentAnalysisThumbnail from "../../assets/images/NewLandingPage/DocumentAnalysisThumbnail.png";

const ProductFeatures = () => {
  return (
    <div className="pb-32 pt-16">
      <div className="flex flex-col items-center gap-20 max-sm:gap-8 px-20 max-sm:px-4">
        <motion.div
          initial={{
            opacity: 0,
            y: 20,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.7,
            ease: "easeOut",
            delay: 0,
          }}
          viewport={{
            once: true,
            margin: "-50px",
          }}
          className="flex flex-col gap-10"
        >
          <motion.h4
            className="font-sora font-bold text-5xl max-sm:text-3xl text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0 }}
            viewport={{ once: true }}
          >
            Product Feature
          </motion.h4>

          <motion.p
            className="font-poppins text-sm text-center"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.6, delay: 0 }}
            viewport={{ once: true }}
          >
            Supercharge your workflow with automation and AI-powered insights.
          </motion.p>
        </motion.div>
        <div>
          <div>
            <Tabs defaultValue="lawiz-chatbot" className="flex flex-col gap-8">
              <TabsList className="flex max-sm:flex-col gap-5 bg-white dark:bg-black h-fit items-start">
                <TabsTrigger
                  value="lawiz-chatbot"
                  className="max-w-[400px] data-[state=active]:bg-gradient-to-b flex-1 data-[state=active]:from-[#521EDF] data-[state=active]:to-[#D028CB] p-1 rounded-lg"
                >
                  <div className="flex h-fit w-fit flex-col gap-3 items-start min-h-[240px] max-sm:min-h-0 rounded-md px-6 py-4 bg-white dark:bg-black dark:text-white">
                    <div className="size-8 max-sm:size-6 rounded-full border flex items-center justify-center">
                      <p className="font-poppins text-sm max-sm:text-xs">1</p>
                    </div>
                    <p className="font-poppins font-bold text-2xl max-sm:text-base">
                      LaWiz Chatbot
                    </p>
                    <p className="text-[#5c5c5c] dark:text-[#a4a4a4] text-sm max-sm:text-xs font-poppins text-wrap text-start leading-[24px]">
                      Chat with LaWiz for instant legal advice through our smart
                      conversational AI.
                    </p>
                  </div>
                </TabsTrigger>
                <TabsTrigger
                  value="case-management"
                  className="max-w-[400px] data-[state=active]:bg-gradient-to-b flex-1 data-[state=active]:from-[#521EDF] data-[state=active]:to-[#D028CB] p-1 rounded-lg"
                >
                  <div className="flex h-fit w-fit flex-col gap-3 items-start min-h-[240px] max-sm:min-h-0 rounded-md px-6 py-4 bg-white dark:bg-black dark:text-white">
                    <div className="size-8 max-sm:size-6 rounded-full border flex items-center justify-center">
                      <p className="font-poppins text-sm max-sm:text-xs">2</p>
                    </div>
                    <p className="font-poppins font-bold text-2xl max-sm:text-base text-wrap text-start">
                      AI-Based Case Management
                    </p>
                    <p className="text-[#5c5c5c] dark:text-[#a4a4a4] text-sm max-sm:text-xs font-poppins text-wrap text-start leading-[24px]">
                      Stay on top of your cases through our case management
                      system that tracks and organizes relevant case details.
                    </p>
                  </div>
                </TabsTrigger>
                <TabsTrigger
                  value="document-analysis"
                  className="max-w-[400px] data-[state=active]:bg-gradient-to-b flex-1 data-[state=active]:from-[#521EDF] data-[state=active]:to-[#D028CB] p-1 rounded-lg"
                >
                  <div className="flex h-fit w-fit flex-col gap-3 items-start min-h-[240px] max-sm:min-h-0 rounded-md px-6 py-4 bg-white dark:bg-black dark:text-white">
                    <div className="size-8 max-sm:size-6 rounded-full border flex items-center justify-center">
                      <p className="font-poppins text-sm max-sm:text-xs">3</p>
                    </div>
                    <p className="font-poppins font-bold text-2xl max-sm:text-base">
                      Document Analysis
                    </p>
                    <p className="text-[#5c5c5c] dark:text-[#a4a4a4] text-sm max-sm:text-xs font-poppins text-wrap text-start leading-[24px]">
                      Effortlessly transform your document management process
                      with advanced AI-driven tools designed to handle
                      everything from bulk uploads to insightful analysis.
                    </p>
                  </div>
                </TabsTrigger>
              </TabsList>
              <div className="relative w-full min-h-[800px] max-sm:min-h-[200px]">
                <TabsContent
                  value="lawiz-chatbot"
                  className="absolute inset-0 opacity-0 invisible data-[state=active]:opacity-100 data-[state=active]:visible transition-opacity duration-300"
                >
                  <div className=" bg-gradient-to-br from-[#521EDF] to-[#D028CB] p-1.5 max-sm:p-1 rounded-xl">
                    <video
                      controls
                      className="w-full h-full rounded-xl"
                      poster={LawizThumbnail}
                    >
                      <source
                        src="https://caseminister.blob.core.windows.net/video/LawizChatbotProductFeature.mp4"
                        // src={ChatbotVideo}
                      />
                    </video>
                  </div>
                </TabsContent>

                <TabsContent
                  value="case-management"
                  className="absolute inset-0 opacity-0 invisible data-[state=active]:opacity-100 data-[state=active]:visible transition-opacity duration-300"
                >
                  <div className=" bg-gradient-to-br from-[#521EDF] to-[#D028CB] p-1.5 max-sm:p-1 rounded-xl">
                    <video
                      controls
                      className="w-full h-full rounded-xl"
                      poster={CaseManagementThumbnail}
                    >
                      <source
                        src="https://caseminister.blob.core.windows.net/video/CaseManagementProductFeature.mp4"
                        // src={CaseManagementVideo}
                      />
                    </video>
                  </div>
                </TabsContent>

                <TabsContent
                  value="document-analysis"
                  className="absolute inset-0 opacity-0 invisible data-[state=active]:opacity-100 data-[state=active]:visible transition-opacity duration-300"
                >
                  <div className=" bg-gradient-to-br from-[#521EDF] to-[#D028CB] p-1.5 max-sm:p-1 rounded-xl">
                    <video
                      controls
                      className="w-full h-full rounded-xl"
                      poster={DocumentAnalysisThumbnail}
                    >
                      <source
                        src="https://caseminister.blob.core.windows.net/video/DocumentAnalysisProductFeature.mp4"
                        // src={DocumentAnalysisVideo}
                      />
                    </video>
                  </div>
                </TabsContent>
              </div>
            </Tabs>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductFeatures;

// src/context/FolderContext.js
import React, { createContext, useState, useEffect } from "react";
import axiosInstance from "../utils/axiosInstance";
import { toast } from "react-toastify";

export const FolderContext = createContext();

export const FolderProvider = ({ children }) => {
  const [folders, setFolders] = useState([]);

  const getFolders = async () => {
    const access_token = localStorage.getItem("access_token");
    try {
      const response = await axiosInstance.get("/assistant/userfolder/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      if (response.data) {
        setFolders(response.data);
        console.log(response.data);
      } else {
        toast("Error fetching folders");
      }
    } catch (error) {
      console.error("Error fetching folders:", error);
      toast("Error fetching folders");
    }
  };

  return (
    <FolderContext.Provider value={{ folders, getFolders }}>
      {children}
    </FolderContext.Provider>
  );
};

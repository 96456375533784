import React, { useEffect, useState } from "react";

// Icons
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GrContactInfo } from "react-icons/gr";
import { CgArrowTopRight } from "react-icons/cg";

// Data
import { statesAndCities } from "./data";

// Dialog Box
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/ui/dialog";

// Shadcn Input
import { Input } from "../../components/ui/input";

// Shadcn Select
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

// Shadcn Textarea
import { Textarea } from "../../components/ui/textarea";

// Axios
import axiosInstance from "../../utils/axiosInstance";

const UserProfile = ({
  userProfileData,
  setUserProfileData,
  setChangeUserProfileData,
  getUser,
}) => {
  const [selectedState, setSelectedState] = useState();
  const [selectedCity, setSelectedCity] = useState();
  const [editDialogBox, setEditDialogBox] = useState(false);
  const access_token = localStorage.getItem("access_token"); // Access Token
  const [formData, setFormData] = useState({
    user: { first_name: "", last_name: "" },
    city: "",
    state: "",
    pincode: "",
    mobile_number: "",
    bio: "",
  });

  useEffect(() => {
    if (userProfileData) {
      setFormData({
        user: {
          first_name: userProfileData.user.first_name || "",
          last_name: userProfileData.user.last_name || "",
        },
        city: userProfileData.city || "",
        state: userProfileData.state || "",
        pincode: userProfileData.pincode || "",
        mobile_number: userProfileData.mobile_number || "",
        bio: userProfileData.bio || "",
      });

      setSelectedState(userProfileData.state || "");
      setSelectedCity(userProfileData.city || "");
    }
  }, [userProfileData]);

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity(""); // Reset city when state changes
    setFormData((prev) => ({
      ...prev,
      state: value,
      city: "",
    }));
  };

  // Handle City Selection
  const handleCityChange = (value) => {
    setSelectedCity(value);
    setFormData((prev) => ({
      ...prev,
      city: value,
    }));
  };

  const handleSaveDetails = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = { user: {} };

    for (let [key, value] of formData.entries()) {
      if (key === "first_name" || key === "last_name") {
        data.user[key] = value; // Store first_name and last_name inside user object.
      } else {
        data[key] = value; // Store other fields directly inside data object.
      }
    }

    console.log(data);

    try {
      const response = await axiosInstance.put(
        `api/profile/${userProfileData.id}/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setChangeUserProfileData((prev) => !prev);
        setEditDialogBox(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  // UPDATE USER DETAILS
  const updateUser = async () => {
    try {
      const response = await axiosInstance.put(
        `api/user/${userProfileData.id}/`,
        { is_lawyer: true, email: userProfileData.user.email },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        getUser();
      }

      console.log(response);
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="px-20 max-lg:px-10 max-sm:px-4 max-md:flex-col max-md:items-center flex justify-between gap-10">
      {/* LEFT */}
      <div className="shadow-md -mt-24 rounded-lg bg-white dark:bg-dark-400 max-sm:w-full w-[500px] p-8 flex flex-col gap-6">
        <div className="flex justify-between">
          <div className="size-36 rounded-full bg-gray-300 dark:bg-gray-500 flex items-center gap-1 justify-center">
            <p className="font-mulish font-bold text-4xl">
              {userProfileData?.user?.first_name?.substring(0, 1).toUpperCase()}
            </p>
            <p className="font-mulish font-bold text-4xl">
              {userProfileData?.user?.last_name?.substring(0, 1).toUpperCase()}
            </p>
          </div>
          <div>
            <Dialog open={editDialogBox} onOpenChange={setEditDialogBox}>
              <DialogTrigger>
                <FaRegEdit className="text-lg" />
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit Personal Details</DialogTitle>
                  <DialogDescription>
                    Update your profile details.
                  </DialogDescription>
                </DialogHeader>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleSaveDetails}
                >
                  <div className="flex gap-4 w-full">
                    <div className="w-full">
                      <label
                        htmlFor="first_name"
                        className="font-raleway font-semibold text-sm"
                      >
                        First Name*
                      </label>
                      <Input
                        value={formData.user.first_name}
                        onChange={(e) => {
                          const value = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setFormData((prev) => ({
                            ...prev,
                            user: { ...prev.user, first_name: value },
                          }));
                        }}
                        name="first_name"
                        type="text"
                        placeholder="Enter Your First Name"
                        required
                        autoComplete="off"
                      />
                    </div>
                    <div className="w-full">
                      <label
                        htmlFor="last_name"
                        className="font-raleway font-semibold text-sm"
                      >
                        Last Name*
                      </label>
                      <Input
                        value={formData.user.last_name}
                        onChange={(e) => {
                          const value = e.target.value.replace(
                            /[^a-zA-Z ]/g,
                            ""
                          );
                          setFormData((prev) => ({
                            ...prev,
                            user: { ...prev.user, last_name: value },
                          }));
                        }}
                        name="last_name"
                        type="text"
                        placeholder="Enter Your Last Name"
                        required
                        autoComplete="off"
                      />
                    </div>
                  </div>

                  <div>
                    <label
                      htmlFor="state"
                      className="font-raleway font-semibold text-sm"
                    >
                      Select State*
                    </label>
                    <Select
                      name="state"
                      value={selectedState}
                      onValueChange={handleStateChange}
                      required
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a state" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.keys(statesAndCities).map((state) => (
                          <SelectItem key={state} value={state}>
                            {state}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label
                      htmlFor="city"
                      className="font-raleway font-semibold text-sm"
                    >
                      Select City*
                    </label>
                    <Select
                      value={selectedCity}
                      onValueChange={handleCityChange}
                      name="city"
                      required
                      disabled={!selectedState}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a city" />
                      </SelectTrigger>
                      <SelectContent>
                        {statesAndCities[selectedState]?.map((city) => (
                          <SelectItem key={city} value={city}>
                            {city}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label className="font-raleway font-semibold text-sm">
                      Pin Code*
                    </label>
                    <Input
                      value={formData.pincode}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9 ]/g, "");
                        setFormData((prev) => ({
                          ...prev,
                          pincode: value,
                        }));
                      }}
                      name="pincode"
                      type="text"
                      placeholder="Enter Your Pin Code"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div>
                    <label className="font-raleway font-semibold text-sm">
                      Mobile Number*
                    </label>
                    <Input
                      value={formData.mobile_number}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          mobile_number: e.target.value,
                        }))
                      }
                      name="mobile_number"
                      className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      type="text"
                      placeholder="Enter Your Mobile Number"
                      maxLength="10"
                      minLength="10"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label className="font-raleway font-semibold text-sm">
                      Bio*
                    </label>
                    <Textarea
                      value={formData.bio}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          bio: e.target.value,
                        }))
                      }
                      name="bio"
                      placeholder="Enter Your Bio"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <DialogFooter className="max-sm:gap-2">
                    <DialogClose className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm">
                      Cancel
                    </DialogClose>
                    <button className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm bg-black dark:bg-white text-white dark:text-black">
                      Save
                    </button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        <div className="flex flex-col gap-4">
          <h3 className="font-mulish font-semibold text-2xl">
            {userProfileData?.user?.first_name}{" "}
            {userProfileData?.user?.last_name}
          </h3>
          <div className="flex gap-4 items-center">
            <MdOutlineEmail />
            <p>{userProfileData?.user?.email}</p>
          </div>
          <div className="flex gap-4 items-center">
            <FaMapMarkerAlt />
            <p>
              {userProfileData?.city}, {userProfileData?.state},{" "}
              {userProfileData?.pincode}
            </p>
          </div>
          <div className="flex gap-4 items-center">
            <GrContactInfo />
            <p>{userProfileData?.mobile_number}</p>
          </div>
          <div className="flex flex-col gap-4">
            <p className="font-mulish font-semibold text-lg">Bio</p>
            <div className="shadow-sm h-44 bg-[#f8f8f8] dark:bg-dark-450 rounded-md px-3 py-2">
              {userProfileData?.bio}
            </div>
          </div>
        </div>
      </div>
      {/* RIGHT */}
      <div className="flex flex-col max-md:mt-0 max-md:mb-10 -mt-24 justify-center items-center gap-6 max-w-[400px] h-[620px] rounded-lg text-center bg-[url('/src/assets/images/UserProfile/LawyerUpgrade.png')] text-white bg-no-repeat bg-cover bg-black">
        <p className="font-semibold text-xl w-1/2">
          If you are a lawyer looking to increase your visibility, join our
          platform.
        </p>
        <button
          onClick={updateUser}
          className="flex items-center gap-2 bg-white px-3 py-2 text-gray-700 font-medium"
        >
          <CgArrowTopRight className="text-2xl" />
          Upgrade to Lawyer's Profile
        </button>
      </div>
    </div>
  );
};

export default UserProfile;

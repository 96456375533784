// React Utils
import React, { useEffect, useState } from "react";

// Axios
import axiosInstance from "../../utils/axiosInstance";

// Components
import LawyerProfile from "./LawyerProfile";
import UserProfile from "./UserProfile";

// Loader Component
import Loader from "../HighCourtDelhi/Loader";

const Profile = () => {
  const [isLawyer, setIsLawyer] = useState(false);
  const [userProfileData, setUserProfileData] = useState();
  const [changeUserProfileData, setChangeUserProfileData] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const access_token = localStorage.getItem("access_token"); // Access Token

  // GET USER DETAILS
  /* Example Response -  
      email: "amaan@caseminister.com"
      first_name: "Amaan"
      is_lawyer: true
      last_name: "Siddiqui"
      username: null 
  */
  const getUser = async () => {
    try {
      setIsLoading(true);
      const response = await axiosInstance.get("api/user/", {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      setIsLawyer(response.data[0].is_lawyer);
      setIsLoading(false);
    } catch (err) {
      console.log(err);
      setIsLoading(false);
    }
  };

  // GET USER FULL DETAILS
  const getUserProfile = async () => {
    const response = await axiosInstance.get("api/profile/", {
      headers: {
        Authorization: `Bearer ${access_token}`,
        "Content-Type": "application/json",
      },
    });

    setUserProfileData(response.data[0]);
  };

  useEffect(() => {
    getUser();
    getUserProfile();
  }, [changeUserProfileData, isLawyer]);

  return (
    <div className="pt-16">
      <div>
        <div className="bg-[url('./assets/images/UserProfile/Profile.png')] w-full h-[170px] "></div>
        <div>
          {isLoading && !isLawyer ? (
            <div className="flex justify-center items-center w-full h-[400px]">
              <Loader />
            </div>
          ) : isLawyer ? (
            <LawyerProfile
              userProfileData={userProfileData}
              setUserProfileData={setUserProfileData}
              setChangeUserProfileData={setChangeUserProfileData}
              getUser={getUser}
            />
          ) : (
            <UserProfile
              userProfileData={userProfileData}
              setUserProfileData={setUserProfileData}
              setChangeUserProfileData={setChangeUserProfileData}
              getUser={getUser}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default Profile;

import React from "react";
// import Navbar from "./Navbar";
import privacyIcon from "../assets/images/comingSoon/privacy-icon.svg";

export default function TermsAndCondition() {
  return (
    <>
      {/* <Navbar /> */}
      <div className="privacy-main-contain dark:bg-black bg-light-600">
        <div className="privacy-header mt-[150px] mb-8">
          <h3 className="text-gray-700 dark:text-gray-300">
            <span>Terms & </span>Conditions
          </h3>
          <p className="text-gray-900 border p-3 dark:text-gray-300">
            Last updated: July 7, 2024
          </p>
        </div>

        <div className="privacy-contain-container dark:bg-dark-400 dark:text-gray-200 tracking-wide leading-relaxed">
          <img src={privacyIcon} alt="" />
          <div className="privacy-main-section">
            <p className="mb-8">
              Welcome to CaseMinister, a product of Advanced Technology and
              Research Innovation Group. For the purposes of these Terms and
              Conditions, "CaseMinister" refers to the website
              (caseminister.com) and the services provided by Advanced
              Technology and Research Innovation Group. By using CaseMinister
              ("Service"), you agree to be bound by the following terms and
              conditions ("Terms"). Please read them carefully before using our
              Service. 
            </p>
            <h1>1. Acceptance Of Terms</h1>
            <p className="mb-10">
              By accessing or using CaseMinister, you agree to comply with and
              be bound by these Terms. If you do not agree to these Terms, you
              may not access or use the Service.
            </p>
            <h1>2. Modification To Terms</h1>
            <p className="mb-10">
              We reserve the right to modify these Terms at any time. Any
              changes will be effective immediately upon posting the revised
              Terms on our website. Your continued use of the Service after any
              such changes constitutes your acceptance of the new Terms.
            </p>
            <h1>3. Eligibility</h1>
            <p className="mb-10">
              You must be at least 18 years old to use our Service. By using
              CaseMinister, you represent and warrant that you are at least 18
              years of age.
            </p>
            <h1>4. Account Registration</h1>
            <p className="mb-10">
              To access certain features of the Service, you may need to
              register for an account. You agree to provide accurate and
              complete information during the registration process and to keep
              your account information up-to-date. You are responsible for
              maintaining the confidentiality of your account credentials and
              for all activities that occur under your account.
            </p>
            <h1>5. User Consent Clause</h1>
            <p className="mb-10">
              By submitting your case information to CaseMinister, you
              explicitly consent to CaseMinister accessing your court records
              available publicly for the purpose of providing and improving our
              services. You acknowledge and agree that the information accessed
              from public court records will be used in accordance with our
              Privacy Policy and Terms and Conditions. This includes, but is not
              limited to, sourcing data from eCourts, Delhi High Court, and
              other public legal records.{" "}
            </p>
            <h1>6. Disclaimer</h1>
            <p className="mb-4">
              The information provided by CaseMinister is sourced from public
              court records, including but not limited to the eCourts and Delhi
              High Court websites. Users are advised to verify the accuracy of
              the data directly with the respective courts or through official
              records. The data provided by CaseMinister is not intended for use
              as legal evidence and should not be relied upon as such.{" "}
            </p>
            <p className="mb-10">
              Neither the Courts concerned nor the National Informatics Centre
              (NIC) nor the e-Committee is responsible for any data inaccuracy
              or delay in the updation of the data on their websites. We do not
              accept any responsibility or liability for any damage or loss
              arising from the direct or indirect use of the information
              provided on CaseMinister. If any errors or omissions are found, we
              would appreciate them being brought to our notice so that
              corrections can be made.{" "}
            </p>
            <h1>7. Use Of The Service</h1>
            <p>
              You agree to use CaseMinister only for lawful purposes and in
              accordance with these Terms. You may not use the Service:{" "}
            </p>
            <ul className="mb-10">
              <li>
                In any way that violates any applicable local, state, national,
                or international law or regulation.{" "}
              </li>
              <li>
                To engage in any activity that is harmful, fraudulent,
                deceptive, or otherwise objectionable.{" "}
              </li>
              <li>
                To infringe on the rights of others, including intellectual
                property rights and privacy rights.{" "}
              </li>
              <li>
                To transmit any material that is defamatory, obscene, offensive,
                or otherwise inappropriate.{" "}
              </li>
            </ul>
            <h1>8. Data Usage & Research</h1>
            <p className="mb-10">
              CaseMinister utilizes publicly available legal data for research
              purposes to provide accurate and up-to-date legal information. We
              are committed to ensuring that our data usage complies with all
              applicable laws and regulations. The data we gather is processed
              and analyzed using advanced AI techniques to offer insights and
              recommendations to our users. Please be aware that the knowledge
              base of these AI models is current as of October 2023. While the
              AI can assist with a wide range of queries, it may not have
              information on developments or changes in laws, regulations, or
              other relevant areas that have occurred after this date. Users are
              advised to consult up-to-date sources and qualified professionals
              for the most current information and advice.
            </p>
            <h1>9. Disclaimer</h1>
            <h2>Third-Party Data Sources </h2>
            <p>
              CaseMinister also utilizes data from publicly available
              third-party websites. By using CaseMinister, you acknowledge and
              agree to the following:{" "}
            </p>
            <ul className="mb-10">
              <li>
                Data Accuracy: We strive to ensure that the data we collect from
                third-party sources is accurate and current. However, we do not
                guarantee the accuracy, completeness, or reliability of any
                information obtained from third-party websites.
              </li>
              <li>
                No Endorsement: The inclusion of data from third-party websites
                does not imply any endorsement or association with those
                websites.{" "}
              </li>
              <li>
                Compliance with Third-Party Terms: You agree to comply with the
                terms of use and privacy policies of any third-party websites
                from which CaseMinister sources data. We are not responsible for
                any breaches of such terms by our users.
              </li>
              <li>
                Limitations of Liability: CaseMinister shall not be liable for
                any inaccuracies or issues arising from the use of data obtained
                from third-party websites. Your use of such data is at your own
                risk.{" "}
              </li>
              <li>
                Intellectual Property: We respect the intellectual property
                rights of others. If you believe that any data sourced from
                third-party websites and used by CaseMinister infringes on your
                intellectual property rights, please notify us immediately.{" "}
              </li>
            </ul>
            <h1>9. AI & Safe Usage</h1>
            <p className="mb-10">
              CaseMinister employs artificial intelligence to enhance legal
              information providing and decision support. We are committed to
              the ethical and safe use of AI. Our AI systems are designed to
              respect user privacy, operate transparently, and be free from
              biases. We continually monitor and update our AI models to ensure
              they provide accurate and fair information.{" "}
            </p>
            <h1>10. AI & Safe Usage</h1>
            <p className="mb-4">
              CaseMinister is committed to protecting the privacy and security
              of your personal information. Our data practices comply with the
              Information Technology (Reasonable Security Practices and
              Procedures and Sensitive Personal Data or Information) Rules,
              2011, under the Information Technology Act, 2000, and any
              applicable regulations, including the upcoming Personal Data
              Protection Bill, 2019. 
            </p>
            <p>
              {" "}
              <span style={{ fontWeight: "800" }}>
                Collection of Personal Information:{" "}
              </span>
              We collect personal information from users to provide and improve
              our services. This may include, but is not limited to, names,
              contact information, and professional details. 
            </p>
            <p>
              <span style={{ fontWeight: "800" }}>
                Use of Personal Information.
              </span>{" "}
              Personal information collected is used to: 
            </p>
            <ul className="mb-6">
              <li>Provide and enhance our services </li>
              <li>
                Conduct due diligence and verify the credentials of legal
                professionals 
              </li>
              <li>Perform data analysis and research </li>
              <li>Communicate with users</li>
            </ul>
            <p>
              {" "}
              <span style={{ fontWeight: "800" }}>Data Security. </span>We
              implement appropriate technical and organizational measures to
              protect personal information against unauthorized access,
              alteration, disclosure, or destruction. These measures include: 
            </p>
            <ul className="mb-6">
              <li>Encryption of data in transit and at rest </li>
              <li>Regular security assessments and audits </li>
              <li>Access controls and authentication mechanisms</li>
            </ul>
            <p>
              {" "}
              <span style={{ fontWeight: "800" }}>Data Retention </span>Personal
              information is retained only for as long as necessary to fulfill
              the purposes for which it was collected, or as required by law. 
            </p>
            <p>
              {" "}
              <span style={{ fontWeight: "800" }}>User Rights.</span> Users have
              the right to: 
            </p>
            <ul className="mb-6">
              <li>Access their personal information </li>
              <li>
                Request correction or deletion of their personal information 
              </li>
              <li>Withdraw consent for data processing </li>
            </ul>
            <p>
              To exercise these rights, users can contact us at{" "}
              <span style={{ fontWeight: "800" }}>Info@Caseminister.com.</span> 
            </p>
            <p>
              Disclosure to Third Parties. We do not disclose personal
              information to third parties except: 
            </p>
            <ul className="mb-6">
              <li>With user consent </li>
              <li>For legal or regulatory requirements </li>
              <li>
                To protect the rights, property, or safety of CaseMinister, our
                users, or the public 
              </li>
            </ul>
            <p className="mb-6">
              {" "}
              <span style={{ fontWeight: "800" }}>
                International Data Transfers.
              </span>{" "}
              If personal information is transferred outside India, we ensure
              that it is protected in a manner consistent with how it is
              protected in India. 
            </p>
            <p className="mb-6">
              {" "}
              <span style={{ fontWeight: "800" }}>
                {" "}
                Changes to This Policy.
              </span>{" "}
              We may update this policy from time to time. Users will be
              notified of any significant changes through our website or other
              appropriate means. 
            </p>
            <p className="mb-10">
              {" "}
              <span style={{ fontWeight: "800" }}>Contact Us -</span> If you
              have any questions or concerns about our data protection
              practices, please contact us at: <br /> Advanced Technology and
              Research Innovation Group <br />
              Suite 01, 64 Nile Street, London 
              <br />
              Info@Caseminister.com 
            </p>
            <h1>11. Licensing Information</h1>
            <p className="mb-10">
              Users are granted a limited license to access and use
              CaseMinister’s services solely for personal, non-commercial
              purposes. Any unauthorized use of CaseMinister's trademarks,
              logos, and other intellectual property is prohibited.{" "}
            </p>
            <h1>12. User-Generated Content</h1>
            <p className="mb-10">
              By submitting content on CaseMinister, users grant us a
              non-exclusive, royalty-free license to use, reproduce, modify, and
              distribute the content. Users retain all ownership rights to their
              content.{" "}
            </p>
            <h1>13. Copyright Infringement</h1>
            <p className="mb-10">
              If you believe any content on CaseMinister infringes your
              copyright, please notify us with the necessary details, and we
              will investigate and take appropriate action. 
            </p>
            <h1>14. External Links</h1>
            <p className="mb-10">
              CaseMinister may contain links to third-party websites. We are not
              responsible for the content or practices of these sites, and users
              should review the terms and privacy policies of any third-party
              sites they visit. 
            </p>
            <h1>15. Termination of Accounts</h1>
            <p className="mb-10">
              CaseMinister reserves the right to suspend or terminate user
              accounts for any violations of these Terms. Users may also
              terminate their accounts by contacting customer support. 
            </p>
            <h1>16. Governing Law & Jurisdiction</h1>
            <p className="mb-10">
              These Terms are governed by and construed in accordance with the
              laws of England and Wales. Any disputes arising from these Terms
              or the use of the Service shall be subject to the exclusive
              jurisdiction of the courts of England and Wales. 
            </p>
            <h1>17. Modifications & Interruptions</h1>
            <p className="mb-10">
              CaseMinister reserves the right to modify or discontinue any part
              of the Service at any time. Users will be notified of significant
              changes via email or through the platform. 
            </p>
            <h1>18. Disclaimer Of Warranties</h1>
            <p className="mb-10">
              The Service is provided on an "as is" and "as available" basis. We
              make no representations or warranties of any kind, express or
              implied, as to the operation of the Service or the information,
              content, or materials included in the Service. You expressly agree
              that your use of the Service is at your sole risk. 
            </p>
            <h1>19. Limitation Of Liability</h1>
            <p className="mb-4">
              To the fullest extent permitted by law, Advanced Technology and
              Research Innovation Group shall not be liable for any indirect,
              incidental, special, consequential, or punitive damages, or any
              loss of profits or revenues, whether incurred directly or
              indirectly, or any loss of data, use, goodwill, or other
              intangible losses, resulting from: 
            </p>
            <ul className="mb-10">
              <li>
                Your access to or use of or inability to access or use the
                Service;
              </li>
              <li>
                Any conduct or content of any third party on the Service; 
              </li>
              <li>Any content obtained from the Service; </li>
              <li>
                Unauthorized access, use, or alteration of your transmissions or
                content. 
              </li>
            </ul>
            <h1>20. Indemnification</h1>
            <p className="mb-10">
              You agree to indemnify, defend, and hold harmless Advanced
              Technology and Research Innovation Group, its affiliates, and
              their respective officers, directors, employees, and agents from
              and against any and all claims, liabilities, damages, losses,
              costs, expenses, or fees (including reasonable attorneys' fees)
              arising from your use of the Service or your violation of these
              Terms. 
            </p>
            <h1>21. Lawyer Information & Due Diligence</h1>
            <p className="mb-10">
              Lawyers providing their details to CaseMinister for due diligence
              purposes agree that their information may be used to conduct
              searches and analyze their data on publicly available platforms.
              We will not publish any data of lawyers on our website without
              first seeking their explicit consent. Explicit consent will be
              obtained through a clear and affirmative action, such as a
              checkbox or digital signature, during the data submission process.
              CaseMinister is designed to facilitate legal research, case
              management, and provide valuable insights to legal professionals
              through the use of advanced AI techniques and publicly available
              legal data. We are not engaged in solicitation or advertisement of
              lawyers in compliance with the Advocates Act 1961 and Bar Council
              of India Rules. 
            </p>
            <h1>22. General Terms</h1>
            <p className="mb-10">
              CaseMinister is not responsible for any advice or assistance
              provided by the lawyers listed on our platform. We are an
              independent third-party platform enabling clients to communicate
              with and, if needed, meet lawyers. We have no control over the
              services provided by the lawyers and are not responsible for any
              issues or discrepancies in the services provided. 
            </p>
            <h1>23. Beta Disclaimer & Good Faith Usage</h1>
            <p className="mb-10">
              CaseMinister is currently in beta and is provided to users in good
              faith. The platform is being tested and may not function as
              intended. By using the Service, you acknowledge and agree that the
              Service is in beta, you accept any risks associated with its use,
              and you understand that the platform may contain bugs, limited
              functionality, or other issues that could affect its performance.
              We do not guarantee that the Service will be free from errors or
              that it will meet your expectations. 
            </p>
            <h1>24. Accuracy Of Information</h1>
            <p className="mb-10">
              The information provided on CaseMinister is for general
              informational purposes only and does not constitute legal advice.
              While we strive to ensure that the information provided is
              accurate and up-to-date, we make no representations or warranties
              of any kind, express or implied, about the accuracy, reliability,
              or completeness of any information on the Service. You should
              consult a qualified attorney for specific legal advice tailored to
              your circumstances. 
            </p>
            <h1>25. Prohibited Activities</h1>
            <p className="mb-4">
              Users are prohibited from engaging in any activity that could
              harm, disrupt, or misuse the CaseMinister platform. This includes,
              but is not limited to: 
            </p>
            <ul className="mb-4">
              <li>
                Scraping or extracting data from our website without prior
                authorization. 
              </li>
              <li>
                Using our AI services for any unethical, illegal, or harmful
                purposes. 
              </li>
              <li>
                Attempting to bypass any security measures to gain unauthorized
                access to our systems. 
              </li>
              <li>
                Engaging in any activity that interferes with or disrupts the
                integrity or performance of our services. 
              </li>
            </ul>
            <p className="mb-10">
              Violations of these prohibitions may result in immediate
              termination of access to CaseMinister. 
            </p>
            <h1>26. Effective Date & Termination</h1>
            <p className="mb-4">
              These Terms take effect on the date you first use the Site in any
              form. The agreement and associated licenses will end immediately
              without notice if you (or anyone using your account) violate these
              Terms or any other rules that may be established by CaseMinister
              over time. 
            </p>
            <p className="mb-4">
              We reserve the right to suspend or terminate your registration for
              any reason, including extended periods of inactivity, and will
              attempt to provide prior notice of such suspension or termination.
              Upon termination, you agree to delete all CaseMinister materials
              in your possession and stop using the Service. CaseMinister may
              remove any information, files, or other content related to your
              account. We are not liable to you or any third party for the
              termination of your access to the Site and/or Service. 
            </p>
            <p className="mb-4">
              Each provision of these Terms operates independently. If any
              provision is found invalid or unenforceable, the remaining
              provisions will continue in effect. Any invalid or unenforceable
              provision will be modified to the minimum extent necessary to make
              it enforceable. 
            </p>
            <p className="mb-4">
              CaseMinister’s failure to enforce any right or provision of these
              Terms does not constitute a waiver of such right or provision. By
              using this Site and/or registering for the Site or Service, you
              agree to these Terms and consent to enter into agreements with us
              electronically. 
            </p>
            <p className="mb-4">
              You accept and assume all risks associated with the limitations
              outlined in these Terms by using CaseMinister. No promises or
              guarantees are made regarding the outcome of any consultation or
              the resolution of any specific issue. 
            </p>
            <p className="mb-6">
              If you do not agree to these Terms of Use or our Privacy Policy,
              please do not use this site. 
            </p>
          </div>
        </div>
      </div>
    </>
  );
}

import React, { useEffect, useState } from "react";

// Dialog Box
import {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogFooter,
  DialogTitle,
} from "../../../components/ui/dialog";
import { Input } from "../../../components/ui/input";

// Icons
import { FaUser } from "react-icons/fa6";
import { AiOutlineDelete } from "react-icons/ai";
import { FaPhoneAlt } from "react-icons/fa";
import { IoMail } from "react-icons/io5";

import axiosInstance from "../../../utils/axiosInstance";
import moment from "moment";
import { toast } from "react-toastify";

const Clients = () => {
  const [allClients, setAllClients] = useState([]);
  const [clientAdded, setClientAdded] = useState(false);
  const [clientDeleted, setClientDeleted] = useState(false);

  const access_token = localStorage.getItem("access_token"); // Authorization Token

  // Fetch all clients data.
  const getAllClients = async () => {
    try {
      const response = await axiosInstance.get("/dashboard/clients", {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      console.log(response.data);
      setAllClients(response.data);
    } catch (err) {
      console.log(err);
    }
  };

  // Adding a Client
  const handleAddClient = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = {};
    for (let keyValue of formData.entries()) {
      data[keyValue[0]] = keyValue[1];
    }
    try {
      await axiosInstance.post("/dashboard/clients/", data, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });

      setClientAdded((prev) => !prev);
      toast.success("Client Added Successfully.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (err) {
      console.log(err.response.data);
      if (err.response.data.contact_no) {
        toast.error(err.response.data.contact_no[0], {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
      if (err.response.data.email) {
        toast.error(err.response.data.email[0], {
          position: "top-right",
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    }
  };

  const handleClientDelete = async (id) => {
    try {
      await axiosInstance.delete(`/dashboard/clients/${id}/`, {
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      setClientDeleted((prev) => !prev);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    getAllClients();
  }, [clientAdded, clientDeleted]);

  return (
    <div className="px-6 py-4 relative dark:bg-dark-600 flex flex-col gap-4">
      <div>
        {/* <Dialog open={clientAdded} onOpenChange={setClientAdded}>
          <DialogTrigger className="border rounded-md px-2 py-1.5 text-sm">
            + Add New Client
          </DialogTrigger>
          <DialogContent>
            <DialogHeader>
              <DialogTitle>Add a new Client</DialogTitle>
              <DialogDescription>
                Save your clients information.
              </DialogDescription>
            </DialogHeader>
            <form className="flex flex-col gap-4" onSubmit={handleAddClient}>
              <Input
                name="full_name"
                placeholder="Full Name *"
                required
                autoComplete="off"
              />
              <Input
                name="contact_no"
                placeholder="Contact No. * "
                required
                autoComplete="off"
              />
              <Input
                name="email"
                placeholder="Email *"
                required
                autoComplete="off"
              />
              <DialogFooter>
                <button
                  type="submit"
                  className="w-full mt-4 py-2 bg-dark-600 text-white dark:bg-light-600 dark:text-gray-700 font-semibold"
                >
                  Add Client
                </button>
              </DialogFooter>
            </form>
          </DialogContent>
        </Dialog> */}
      </div>
      <div className="flex flex-col gap-3">
        {allClients?.map((client) => (
          <div
            key={client.id}
            className="px-4 py-4 bg-[#f9f9f9] dark:bg-dark-400 flex justify-between"
          >
            <div className="flex gap-5 items-center">
              <div className="bg-[#403a3a] rounded-sm">
                <FaUser className="text-8xl text-[#f4f2f2] p-2" />
              </div>
              <div className="flex flex-col gap-3">
                <p className="font-semibold text-xl font-raleway">
                  {client?.full_name[0].toUpperCase() +
                    client?.full_name.slice(1)}
                </p>
                <div className="flex gap-2 items-center">
                  <FaPhoneAlt className="text-sm" />
                  <p className="text-sm font-roboto">{client?.contact_no}</p>
                </div>
                <div className="flex gap-2 items-center">
                  <IoMail />
                  <p className="text-sm font-raleway">{client?.email}</p>
                </div>
              </div>
            </div>
            <div className="flex gap-2 items-start">
              <Dialog>
                <DialogTrigger onClick={(e) => e.stopPropagation()}>
                  <AiOutlineDelete className="mt-0.5 cursor-pointer select-none hover:opacity-60" />
                </DialogTrigger>
                <DialogContent onClick={(e) => e.stopPropagation()}>
                  <DialogHeader>
                    <DialogTitle>Delete Client</DialogTitle>
                  </DialogHeader>
                  <div className="flex flex-col gap-4">
                    <p>Are you sure you want to delete this client?</p>
                    <div className="flex gap-2">
                      <button
                        className="px-6 py-1.5 bg-red-600 text-gray-200"
                        onClick={() => handleClientDelete(client.id)}
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </DialogContent>
              </Dialog>
              <p className="text-[#0739A5] dark:text-blue-400 font-medium text-sm font-mulish">
                Created on - {moment(client?.created_at).format("Do MMMM YYYY")}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Clients;

// React Utils
import React from "react";

// Framer Motion Utils
import { motion } from "framer-motion";

// Images
import ChatbotImage from "../../assets/images/NewLandingPage/LawizChat.png";
import AnalyticsIllustration from "../../assets/images/NewLandingPage/AnalyticsIllustration.png";
import TotalCasesIllustration from "../../assets/images/NewLandingPage/TotalCasesIllustration.png";
import PendingCasesIllustration from "../../assets/images/NewLandingPage/PendingCasesIllustration.png";
import LawizChats from "../../assets/images/NewLandingPage/LawizChats.png";

// Icons
import { IoSparklesSharp, IoSearchOutline } from "react-icons/io5";
import { RiEdit2Fill } from "react-icons/ri";

const MeetLawizSection = () => {
  return (
    <div className="pt-16 pb-40 px-40 max-sm:px-4 max-sm:py-10 bg-[url('/src/assets/images/NewLandingPage/MeetLawizBackground.png')] bg-no-repeat bg-[50%_70%] max-sm:bg-none">
      <div className="flex flex-col gap-16 max-sm:gap-10 items-center">
        <motion.div
          initial={{
            opacity: 0,
            y: 30,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.8,
            ease: "easeOut",
            delay: 0,
          }}
          viewport={{
            once: true,
            margin: "-60px",
          }}
          className="flex flex-col gap-10 max-sm:gap-6"
        >
          <motion.h3
            className="text-center font-sora font-bold text-5xl max-sm:text-2xl leading-[60px]"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0 }}
            viewport={{ once: true }}
          >
            Meet LaWIZ: AI-powered chatbot for all your legal queries.
          </motion.h3>

          <motion.p
            className="font-inter max-sm:text-sm text-sm dark:text-white max-sm:leading-[21px] font-medium leading-[28px] text-center text-black/70 tracking-wider"
            initial={{ opacity: 0 }}
            whileInView={{ opacity: 1 }}
            transition={{ duration: 0.7, delay: 0 }}
            viewport={{ once: true }}
          >
            Your Smart Legal Assistant – Instant Answers, Anytime, Anywhere.
          </motion.p>
        </motion.div>
        <div className="w-80 relative">
          {/* Transitions of Images */}
          {/* Analytics Illustration */}
          <motion.div
            initial={{
              clipPath: "circle(0% at 50% 50%)",
              rotate: -30,
              scale: 0.5,
            }} // Small circle + start rotated
            whileInView={{
              clipPath: "circle(100% at 50% 50%)",
              rotate: 0,
              scale: 1,
            }} // Expands + rotates to normal
            viewport={{ once: true }}
            transition={{ duration: 0.7, ease: "easeOut", delay: 1 }} // Smooth animation
            className="absolute top-0 left-[-100%] max-sm:hidden"
          >
            <img src={AnalyticsIllustration} alt="anaylitics-illustration" />
          </motion.div>
          {/* Total Cases Illustration */}
          <motion.div
            initial={{
              clipPath: "circle(0% at 50% 50%)",
              rotate: -30,
              scale: 0.5,
            }} // Small circle + start rotated
            whileInView={{
              clipPath: "circle(100% at 50% 50%)",
              rotate: 0,
              scale: 1,
            }} // Expands + rotates to normal
            viewport={{ once: true }}
            transition={{ duration: 0.7, ease: "easeOut", delay: 0.5 }} // Smooth animation
            className="absolute left-[-65%] bottom-[88px] max-sm:hidden"
          >
            <img src={TotalCasesIllustration} alt="total-cases-illustration" />
          </motion.div>
          {/* Pending Cases Illustration */}
          <motion.div
            initial={{
              clipPath: "circle(0% at 50% 50%)",
              rotate: -30,
              scale: 0.5,
            }} // Small circle + start rotated
            whileInView={{
              clipPath: "circle(100% at 50% 50%)",
              rotate: 0,
              scale: 1,
            }} // Expands + rotates to normal
            viewport={{ once: true }}
            transition={{ duration: 0.7, ease: "easeOut", delay: 0.8 }} // Smooth animation
            className="absolute left-[-75%] bottom-0 max-sm:hidden"
          >
            <img
              src={PendingCasesIllustration}
              alt="pending-cases-illustration"
            />
          </motion.div>
          {/* Lawiz Chats */}
          <motion.div
            initial={{
              x: "-100%",
              y: "-20%",
              opacity: 1,
              rotate: -45,
              scale: 0,
            }}
            whileInView={{
              x: "0%",
              y: "0%",
              opacity: 1,
              rotate: 0,
              scale: 1,
            }}
            transition={{
              duration: 1,
              type: "intertia",
              delay: 1,
            }} // Smooth animation
            viewport={{ once: true }}
            className="absolute top-16 right-[-115%] max-sm:hidden"
          >
            <img src={LawizChats} alt="lawiz-chats" />
          </motion.div>
          <PhoneMock />
        </div>
        <div className="flex gap-6 max-sm:flex-col">
          <Card
            icon={<IoSparklesSharp />}
            heading={"Instant Legal Assistance"}
            comment={
              "Get quick, AI-driven answers to legal queries without waiting."
            }
          />
          <Card
            icon={<IoSearchOutline />}
            heading={"Case & Document Analysis"}
            comment={
              "Upload legal documents and let AI summarize key points instantly."
            }
          />
          <Card
            icon={<RiEdit2Fill />}
            heading={"Smart Legal Insights"}
            comment={
              "Ask anything, anytime, anywhere. Stuck while programming? Need some copy? Or just have a question - Ask AI."
            }
          />
        </div>
      </div>
    </div>
  );
};

const PhoneMock = () => {
  return (
    <div className="mx-auto relative w-80 max-sm:w-72 max-sm:h-fit h-[600px] rounded-[45px] shadow-[0_0_2px_2px_rgba(255,255,255,0.1)] border-8 border-zinc-900">
      {/* <!-- Dynamic Island --> */}
      <div className="absolute top-2 left-1/2 transform -translate-x-1/2 w-[90px] h-[22px] bg-zinc-950 rounded-full z-20"></div>
      <div className="absolute -inset-[1px] border-[3px] border-zinc-700 border-opacity-40 rounded-[37px] pointer-events-none"></div>

      {/* <!-- Screen Content --> */}
      <div className="relative w-full h-full rounded-[37px] overflow-hidden flex items-center justify-center  bg-zinc-900/10">
        <img src={ChatbotImage} alt="chatbot" draggable={false} />
      </div>

      {/* Left Side Buttons */}
      {/* Silent Switch */}
      <div className="absolute left-[-12px] top-20 w-[6px] h-8 bg-zinc-900 rounded-l-md shadow-md"></div>
      {/* Volume Up */}
      <div className="absolute left-[-12px] top-36 w-[6px] h-12 bg-zinc-900 rounded-l-md shadow-md"></div>
      {/* Volume Down */}
      <div className="absolute left-[-12px] top-52 w-[6px] h-12 bg-zinc-900 rounded-l-md shadow-md"></div>
      {/* Right Side Power Button */}
      <div className="absolute right-[-12px] top-36 w-[6px] h-16 bg-zinc-900 rounded-r-md shadow-md"></div>
    </div>
  );
};

const Card = ({ icon, heading, comment }) => {
  return (
    <div className="flex max-w-[400px] flex-col gap-5 flex-1 border border-[#E5E5E5] py-5 pl-5 pr-12 rounded-xl mt-10 max-sm:mt-0">
      <div className="text-xl">{icon}</div>
      <h4 className="font-poppins text-xl max-sm:text-base max-sm:font-semibold align-middle">
        {heading}
      </h4>
      <p className="font-inter text-sm max-sm:text-sm tracking-[-0.5px] opacity-60">
        {comment}
      </p>
    </div>
  );
};

export default MeetLawizSection;

import React, { useState } from "react";
import axiosInstance from "../utils/axiosInstance"; // Adjust the path as needed
import "../assets/css/searchLawyer.css";
import Filters from "./Filteredlawyer";
import { useNavigate } from "react-router-dom";
// import { useHistory } from 'react-router-dom';
import { useCaseContext } from "../context/CaseContext";
import { toast } from "react-toastify";
import location from "../assets/images/LawyerRegistration/location.svg";
import nameIcon from "../assets/images/LawyerRegistration/nameIcon.svg";
function SearchBar() {
  const [isFilterVisible, setFilterVisible] = useState(false);
  const [name, setName] = useState("");
  const [state, setState] = useState("");
  const navigate = useNavigate();
  const { setLawyersData } = useCaseContext();

  const access_token = localStorage.getItem("access_token");

  const toggleFilterVisibility = () => {
    setFilterVisible(!isFilterVisible);
  };

  const handleSearch = async (e) => {
    e.preventDefault();
    if (!name.trim()) {
      toast.error("Please fill in the name field.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    if (!state.trim()) {
      toast.error("Please fill in the city field.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      return;
    }
    try {
      const response = await axiosInstance.get("search/searchlawyer/", {
        params: {
          name,
          state,
        },
        headers: {
          Authorization: `Bearer ${access_token}`,
          "Content-Type": "application/json",
        },
      });
      if (response.data.length === 0) {
        toast.info(
          "No lawyers found. Please try again with different criteria.",
          {
            position: "top-right",
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
          }
        );
      }
      setLawyersData(response.data);
      setName("");
      setState("");
    } catch (error) {
      console.error("Error searching for lawyers:", error);
    }
  };

  return (
    <div className="main-container">
      <div className="searchBar">
        <div className="search-filter-container">
          <div className="location-select-container">
            <img src={location} alt="location" className="location-icon" />
            <select
              className="select"
              onChange={(e) => setState(e.target.value)}
            >
              <option>Location</option>
              <option>Ahmedabad</option>
              <option>Bengaluru</option>
              <option>Bhopal</option>
              <option>Bhubaneswar</option>
              <option>Chandigarh</option>
              <option>Chennai</option>
              <option>Coimbatore</option>
              <option>Delhi</option>
              <option>Guwahati</option>
              <option>Hyderabad</option>
              <option>Indore</option>
              <option>Jaipur</option>
              <option>Kanpur</option>
              <option>Kochi</option>
              <option>Kolkata</option>
              <option>Lucknow</option>
              <option>Ludhiana</option>
              <option>Madurai</option>
              <option>Mumbai</option>
              <option>Mysore</option>
              <option>Nagpur</option>
              <option>Patna</option>
              <option>Pune</option>
              <option>Raipur</option>
              <option>Ranchi</option>
              <option>Surat</option>
              <option>Thiruvananthapuram</option>
              <option>Vadodara</option>
              <option>Visakhapatnam</option>
            </select>
          </div>
          <img src={nameIcon} alt="" />
          <input
            type="text"
            placeholder="Name"
            className="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="search-filter-container1">
          <div className="location-select-container1">
            <img src={location} alt="location" className="location-icon" />
            <select
              className="select"
              onChange={(e) => setState(e.target.value)}
            >
              <option>Location</option>
              <option>Ahmedabad</option>
              <option>Bengaluru</option>
              <option>Bhopal</option>
              <option>Bhubaneswar</option>
              <option>Chandigarh</option>
              <option>Chennai</option>
              <option>Coimbatore</option>
              <option>Delhi</option>
              <option>Guwahati</option>
              <option>Hyderabad</option>
              <option>Indore</option>
              <option>Jaipur</option>
              <option>Kanpur</option>
              <option>Kochi</option>
              <option>Kolkata</option>
              <option>Lucknow</option>
              <option>Ludhiana</option>
              <option>Madurai</option>
              <option>Mumbai</option>
              <option>Mysore</option>
              <option>Nagpur</option>
              <option>Patna</option>
              <option>Pune</option>
              <option>Raipur</option>
              <option>Ranchi</option>
              <option>Surat</option>
              <option>Thiruvananthapuram</option>
              <option>Vadodara</option>
              <option>Visakhapatnam</option>
            </select>
          </div>
        </div>
        <div className="location-select-container1">
          <img src={nameIcon} className="location-icon" alt="" />
          <input
            type="text"
            placeholder="Name"
            className="input"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>
        <div className="mobile-filter-button">
          <button className="button" onClick={handleSearch}>
            Search
          </button>
          <button className="filterButton" onClick={toggleFilterVisibility}>
            Filter
          </button>
        </div>
      </div>
      {isFilterVisible && (
        <Filters isVisible={isFilterVisible} onClose={toggleFilterVisibility} />
      )}
    </div>
  );
}

export default SearchBar;

import moment from "moment";
import React, { useEffect, useState } from "react";
import axiosInstance from "../../../utils/axiosInstance";

import {
  Pagination,
  PaginationContent,
  PaginationItem,
  PaginationLink,
  PaginationNext,
  PaginationPrevious,
} from "../../../components/ui/pagination";

const Acts = () => {
  const [allActs, setAllActs] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);

  const access_token = localStorage.getItem("access_token"); // Authorization Token

  // Fetch all Acts
  const fetchAllActs = async (page) => {
    // setCurrentPage(page);
    try {
      const response = await axiosInstance.get(
        page ? `/act/list?page=${page}` : "/act/list",
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response.data);
      setAllActs(response.data.results);
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    fetchAllActs();
  }, []);

  return (
    <div className="px-6 py-4 relative dark:bg-dark-600 flex flex-col">
      <div>{/* <Input placeholder="Search by Act name..." /> */}</div>
      <div className="flex flex-col gap-3">
        {allActs.map((act) => (
          <div
            key={act.id}
            className="bg-[#fcfcfc] dark:bg-dark-400 p-4 flex flex-col gap-3 border border-[#d9d9d9] dark:border-gray-600 rounded-md"
          >
            <div className="border-b pb-3">
              <h6 className="font-semibold">{act.name}</h6>
            </div>
            <div className="flex flex-col gap-2">
              {/* <p>
                <span className="font-semibold">Description</span> -
                {act.description}
              </p>
              <p>
                <span className="font-semibold">Ministry</span> - {act.ministry}
              </p>
              <p>
                <span className="font-semibold">Department</span> -{" "}
                {act.department}
              </p> */}
              <div className="flex gap-4 items-center">
                <p>
                  <span className="font-semibold">Enactment Date</span> -{" "}
                  {moment(act.date_of_enactment).format("DD MMMM YYYY")}
                </p>
                <p className="-mt-0.5 opacity-30">|</p>
                <p>
                  <span className="font-semibold">Enforcement Date</span> -{" "}
                  {moment(act.date_of_enactment).format("DD MMMM YYYY")}
                </p>
              </div>
            </div>
          </div>
        ))}
        <Pagination>
          <PaginationContent>
            <PaginationItem>
              <PaginationPrevious
                className="cursor-pointer select-none"
                onClick={() =>
                  setCurrentPage((prev) => {
                    if (prev > 3) {
                      return prev - 3;
                    }
                    return prev;
                  })
                }
              />
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                className="cursor-pointer"
                onClick={() => fetchAllActs(currentPage)}
              >
                {currentPage}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                className="cursor-pointer"
                onClick={() => fetchAllActs(currentPage + 1)}
              >
                {currentPage + 1}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                className="cursor-pointer"
                onClick={() => fetchAllActs(currentPage + 2)}
              >
                {currentPage + 2}
              </PaginationLink>
            </PaginationItem>
            <PaginationItem></PaginationItem>
            <PaginationItem>
              <PaginationNext
                className="cursor-pointer select-none"
                onClick={() =>
                  setCurrentPage((prev) => {
                    if (prev < 75) {
                      return prev + 3;
                    }
                    return prev;
                  })
                }
              />
            </PaginationItem>
          </PaginationContent>
        </Pagination>
      </div>
    </div>
  );
};

export default Acts;

import React, {
  createContext,
  useContext,
  useState,
  useCallback,
  useMemo,
  useEffect,
} from "react";
import axiosInstance from "../utils/axiosInstance";

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [messages, setMessages] = useState({});
  const [conversationStarted, setConversationStarted] = useState(false);
  const [selectedChat, setSelectedChat] = useState(null);
  const [isChatOpen, setIsChatOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showPrompts, setShowPrompts] = useState(false);
  const [chatRooms, setChatRooms] = useState([]);
  const [chatRoom1, setChatRoom1] = useState(null);

  const access_token = localStorage.getItem("access_token");

  const fetchChatHistory = async () => {
    try {
      const response = await axiosInstance.get("/chatapp/chat-room/", {
        headers: {
          "Content-Type": "application/json",
          Authorization: `Bearer ${access_token}`,
        },
      });
      // console.log("I am an empty arary", chatRooms);
      setChatRooms(response.data);
      if (response.data.length > 0) {
        handleSelectChat(response.data[0].id);
      } else {
        handleSelectChat("");
      }
    } catch (err) {
      console.error("Error fetching chat history:", err);
      setError("Failed to fetch chat history");
    }
  };

  useEffect(() => {
    fetchChatHistory();
  }, []);

  const addMessage = useCallback(
    (chatRoomId, role, content, loading = false) => {
      setMessages((prevMessages) => ({
        ...prevMessages,
        [chatRoomId]: [
          ...(prevMessages[chatRoomId] || []),
          { role, content, loading },
        ],
      }));
    },
    []
  );

  const clearChat = useCallback((chatRoomId) => {
    setMessages((prevMessages) => ({
      ...prevMessages,
      [chatRoomId]: [],
    }));
  }, []);

  const handleNewChat = useCallback(() => {
    if (selectedChat) {
      clearChat(selectedChat);
    }
    setIsChatOpen(true);
    setSelectedChat(null);
    setError(null);
    setConversationStarted(false);
  }, [clearChat, selectedChat]);

  const handleSelectChat = useCallback((chatId) => {
    console.log("Selected Chat:", chatId);
    setSelectedChat(chatId);
    setIsChatOpen(false);
  }, []);

  const startNewChat = useCallback(() => {
    if (selectedChat) {
      clearChat(selectedChat);
    }
    setConversationStarted(false);
    setIsLoading(false);
    setError(null);

    // Ensure chat messages are initialized for the new chat
    setMessages((prevMessages) => ({
      ...prevMessages,
      [selectedChat]: [],
    }));
  }, [clearChat, selectedChat]);

  const contextValue = useMemo(
    () => ({
      messages,
      addMessage,
      setMessages,
      showPrompts,
      setShowPrompts,
      setIsChatOpen,
      chatRooms,
      setChatRooms,
      clearChat,
      startNewChat,
      conversationStarted,
      setConversationStarted,
      selectedChat,
      isChatOpen,
      handleSelectChat,
      handleNewChat,
      isLoading,
      setIsLoading,
      fetchChatHistory,
      setChatRoom1,
      chatRoom1,
      error,
      setError,
    }),
    [messages, conversationStarted, selectedChat, isChatOpen, isLoading, error]
  );

  return (
    <ChatContext.Provider value={contextValue}>{children}</ChatContext.Provider>
  );
};

export const useChat = () => {
  const context = useContext(ChatContext);
  if (!context) {
    throw new Error("useChat must be used within a ChatProvider");
  }
  return context;
};

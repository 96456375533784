// React Utils
import React from "react";

// Framer Motion Utils
import { motion } from "framer-motion";

// Framer Motion Words Pull Up Animation
import { WordsPullUp } from "../../components/ui/words-pull-up";

// Images
import DelhiHC from "../../assets/images/landingpage/DelhiHC.png";
import PunjabHC from "../../assets/images/landingpage/Punjab&HaryanaHC.png";
import RajasthanHC from "../../assets/images/landingpage/RajhasthanHC.png";
import DelhiBarCouncil from "../../assets/images/landingpage/BarCouncilDelhi.png";

const SupportSection = () => {
  return (
    <div className="pt-16 pb-40 max-sm:pb-20 px-40 max-sm:px-4 flex flex-col gap-20 bg-[url('/src/assets/images/NewLandingPage/SupportBackground.png')] bg-[0%_100%] bg-no-repeat">
      <div className="flex flex-col gap-7">
        <WordsPullUp
          text="Supported by Leading Tech & Startup Ecosystems"
          className="font-sora text-sm font-semibold text-center leading-[72px] max-sm:text-2xl"
        />
        <div>
          <motion.div
            initial={{
              opacity: 0,
              y: 20,
            }} // Simpler initial state - just hidden and slightly below position
            whileInView={{
              opacity: 1,
              y: 0,
            }} // Fade in and move to correct position
            transition={{
              duration: 0.7,
              ease: "easeOut",
              delay: 0.2,
            }}
            viewport={{
              once: true,
              margin: "-50px", // Add some margin to trigger earlier
            }}
            className="flex max-sm:gap-2 gap-10 justify-center"
          >
            <motion.img
              className="w-1/4 max-sm:w-[43%] dark:bg-gray-200 dark:rounded-xl"
              src="https://caseminister.blob.core.windows.net/frontend/nvidia.png"
              alt="nvidia logo"
              initial={{ scale: 0.95 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.3 }}
            />
            <motion.img
              className="w-1/4 max-sm:w-[43%] dark:bg-gray-200 dark:rounded-xl"
              src="https://caseminister.blob.core.windows.net/frontend/microsoft.svg"
              alt="microsoft logo"
              initial={{ scale: 0.95 }}
              whileInView={{ scale: 1 }}
              transition={{ duration: 0.5, delay: 0.4 }}
            />
          </motion.div>
        </div>
      </div>
      <div className="flex flex-col gap-10 max-sm:gap-4">
        <WordsPullUp
          text="We Have Partnered With"
          className="font-sora text-sm font-semibold text-center leading-[72px] max-sm:text-2xl"
          totalDelay={2}
        />
        <div>
          <motion.div
            initial={{
              opacity: 0,
              y: 20,
            }}
            whileInView={{
              opacity: 1,
              y: 0,
            }}
            transition={{
              duration: 0.7,
              ease: "easeOut",
              delay: 0,
            }}
            viewport={{
              once: true,
              margin: "-50px",
            }}
            className="flex flex-wrap gap-10 justify-center max-sm:gap-4"
          >
            {/* Individual logo animations with staggered delays */}
            <motion.img
              src={PunjabHC}
              alt="punjab-hc"
              width={150}
              height={150}
              className="max-sm:w-[60px] max-sm:h-[60px]"
              initial={{ scale: 0.9, opacity: 0.7 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
            />
            <motion.img
              src={DelhiBarCouncil}
              alt="delhi-bar-council"
              width={150}
              height={150}
              className="max-sm:w-[60px] max-sm:h-[60px]"
              initial={{ scale: 0.9, opacity: 0.7 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
            />
            <motion.img
              src={DelhiHC}
              alt="delhi-hc"
              width={150}
              height={150}
              className="max-sm:w-[60px] max-sm:h-[60px]"
              initial={{ scale: 0.9, opacity: 0.7 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
            />
            <motion.img
              src={RajasthanHC}
              alt="rajasthan-hc"
              width={150}
              height={150}
              className="max-sm:w-[60px] max-sm:h-[60px]"
              initial={{ scale: 0.9, opacity: 0.7 }}
              whileInView={{ scale: 1, opacity: 1 }}
              transition={{ duration: 0.5, delay: 0.1 }}
              viewport={{ once: true }}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
};

export default SupportSection;

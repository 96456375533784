// React Utils
import React from "react";

// Framer Motion Utils
import { motion } from "framer-motion";

// Icons
import { CgArrowTopRight } from "react-icons/cg";
import { GoCheckCircle } from "react-icons/go";
import { Link } from "react-router-dom";

const TryCaseMinister = () => {
  return (
    <div className="flex flex-col gap-20 max-sm:gap-10 bg-black pt-20 pb-10 max-sm:pt-10 max-sm:pb-10 bg-[url('/src/assets/images/NewLandingPage/TryCaseMinisterBG.png')] bg-no-repeat bg-[90%_0%] max-sm:bg-[100%_20%] max-sm:bg-[auto_100px]">
      <div className="flex justify-start px-40 gap-10 max-sm:px-4">
        <div className="flex flex-col gap-12 max-sm:gap-6 items-start max-sm:justify-start">
          <h6 className="font-sora font-semibold text-5xl max-sm:text-3xl text-white">
            Try CaseMinister for Free
          </h6>
          <Link
            to="/contactus"
            className="flex gap-3 max-sm:gap-1 max-sm:px-4 max-sm:py-2.5 justify-self-start max-sm:text-sm items-center bg-white dark:text-black py-4 px-8 rounded-[36px]"
          >
            <span className="font-poppins max-sm:text-xs">Request Demo</span>
            <CgArrowTopRight className="text-xl max-sm:text-sm" />
          </Link>
        </div>
      </div>
      <div>
        <InfiniteSwiper />
      </div>
    </div>
  );
};

const InfiniteSwiper = () => {
  const items = [
    "AI-Powered Legal Insights",
    "Track Judge & Court Trends",
    "Save Time, Reduce Legal Costs",
    "Optimize Legal Strategy with Data",
  ];
  return (
    <div className="overflow-hidden w-full py-10">
      <motion.div
        className="flex space-x-10 max-sm:space-x-5"
        initial={{ x: 0 }}
        animate={{ x: ["0%", "-100%"] }} // Moves the swiper to the left
        transition={{
          ease: "linear",
          duration: 12, // Speed of scrolling
          repeat: Infinity, // Loops forever
        }}
      >
        {/* Duplicate the divs to create a seamless loop */}
        {[
          ...items,
          ...items,
          ...items,
          ...items,
          ...items,
          ...items,
          ...items,
        ].map((text, index) => (
          <div
            key={index}
            className="flex gap-3 text-nowrap py-3 px-6 max-sm:py-1.5 max-sm:px-3 rounded-[50px] bg-[#212121] items-center justify-center text-white shadow-lg"
          >
            <GoCheckCircle className="text-2xl max-sm:text-lg" />
            <p className="font-poppins text-xl max-sm:text-sm ">{text}</p>
          </div>
        ))}
      </motion.div>
    </div>
  );
};

export default TryCaseMinister;

import React from "react";
import HeroSection from "./HeroSection";
import SupportSection from "./SupportSection";
import MeetLawizSection from "./MeetLawizSection";
import ProductFeatures from "./ProductFeatures";
import PricingSection from "./PricingSection";
import TryCaseMinister from "./TryCaseMinister";
import FAQSection from "./FAQSection";
import Footer from "./Footer";
import InsightsSection from "./InsightsSection";
import WhyCaseMinister from "./WhyCaseMinister";

const NewLandingPage = () => {
  return (
    <div className="bg-white dark:bg-black dark:text-white">
      <HeroSection />
      <SupportSection />
      <MeetLawizSection />
      <WhyCaseMinister />
      <ProductFeatures />
      <InsightsSection />
      <PricingSection />
      <TryCaseMinister />
      <FAQSection />
      <Footer />
    </div>
  );
};

export default NewLandingPage;

import React from "react";
import "../assets/css/CourtSelection.css";
import Navbar from "./Navbar";
import rightcorner from "../assets/images/selectcourt/right-corner.png";
import leftcorner from "../assets/images/selectcourt/left-corner-illustration.png";
import center from "../assets/images/selectcourt/center-back.png";
import { useNavigate } from "react-router-dom";
import mobileFlags from "../assets/images/mycases/mobileFlags.svg";
import NewNotificationBar from "./NewNotificationBar";
import panjabhighcourt from "../assets/images/selectcourt/panjab_high_court.svg";
import dashboard from "../assets/images/profile/Vector.png";

function CourtSelection() {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate("/district-courts");
  };

  const handleAllhighcourt = () => {
    navigate("/all-high-court");
  };

  const handleSupremeCourt = () => {
    navigate("/supreme-court");
  };

  const handleDashboard = () => {
    // navigate("/user-dashboard");
    navigate("/all-cases-dashboard");
  };
  return (
    <div className="pt-10 pb-6">
      {/* <Navbar /> */}
      {/* <NewNotificationBar /> */}
      <div className="container1 mt-4 max-md:mt-16 max-sm:mt-4 max-md:h-[100%] font-roboto bg-white dark:bg-black flex flex-col gap-3">
        <div className="header flex flex-col">
          <h1 className="text-4xl dark:text-gray-400 text-gray-600 tracking-wide">
            <span className="text-gray-800 dark:text-gray-300">
              Select Your Court{" "}
            </span>
            to Get Case Details{" "}
          </h1>
          <p className="text-gray-800 dark:text-gray-300 mb-12 max-sm:mt-6 max-sm:text-base px-3 text-xl">
            Download orders, view summaries, talk to your case in your language,
            and Analyze it in one step.
          </p>
          <button
            className="px-5 py-2 dark:bg-dark-500 dark:text-gray-300 font-raleway font-semibold rounded-lg border dark:border-dark-500 bg-light-500 text-black text-md hover:shadow-[4px_4px_0px_0px_rgba(0,0,0)] dark:hover:shadow-[4px_4px_0px_0px_#313134] transition duration-200"
            onClick={handleDashboard}
          >
            {/* <img src={dashboard} alt="" /> */}
            All Cases Dashboard
          </button>
        </div>
        <div className="court-cards">
          <div className="card-stack" onClick={handleClick}>
            <div className="card background-card2">
              <img
                src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/District-Court.svg"
                alt="district background"
                className="court-background-image"
                loading="lazy"
              />
              <div className="court-background-overlay"></div>
              <p>Indian District Courts</p>
            </div>
            <div className="card foreground-card2">
              <div className="card-content">
                {/* <p>Indian District Courts</p> */}
              </div>
            </div>
          </div>
          <h2 className="mobile-heading max-sm:mt-[-7px] dark:text-gray-300 max-sm:text-[18px]">
            Indian District Courts
          </h2>
          <div className="card-stack main-supreme" onClick={handleSupremeCourt}>
            <div className="card background-card2">
              <img
                src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/1573657162supreme-court-of-india 1.svg"
                alt="supreme court background"
                className="court-background-image"
                loading="lazy"
              />
              <div className="court-background-overlay"></div>
              <p>Supreme Court of India</p>
            </div>
            <div className="card foreground-card2">
              <div className="card-content">
                <p>Supreme Court of India</p>
              </div>
            </div>
          </div>
          <h2 className="mobile-heading max-sm:mt-[-7px] dark:text-gray-300 max-sm:text-[18px]">
            Supreme Court of India
          </h2>
          <div className="card-stack" onClick={handleAllhighcourt}>
            <div className="card background-card2">
              <img
                src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/delhi_high_court.jpeg"
                alt="delhi high court"
                className="court-background-image"
                loading="lazy"
              />
              <div className="court-background-overlay"></div>
              <p>Indian High Courts</p>
            </div>
            <div className="card foreground-card2">
              <div className="card-content">
                <p>Indian High Courts</p>
              </div>
            </div>
          </div>
          <h2 className="mobile-heading max-sm:mt-[-7px] dark:text-gray-300 max-sm:text-[18px]">
            Indian High Courts
          </h2>
          {/* <div className="high_court_section"> */}
          {/* <div className="card-stack main-panjab" onClick={handlehighcourt}>
                            <div className="card background-card2">
                                <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/delhi_high_court.jpeg"
                                    alt="Background Image"c
                                    className="court-background-image"
                                    loading="lazy"
                                />
                                <div className="court-background-overlay"></div>
                                <p>High Court of Delhi</p>
                            </div>
                            <div className="card foreground-card2">
                                <div className="card-content">
                                    <p>High Court of Delhi</p>
                                </div>
                            </div>
                        </div>
                        <h2 className='mobile-heading'>High Court of Delhi</h2> */}
          {/* <div className="card-stack main-panjab" onClick={handlePanjabHighCourt}>
                            <div className="card background-card2">
                                <img src="https://frontendcdn-fgabhsddewd9a2hw.z03.azurefd.net/frontend/panjab_high_court.svg"
                                    alt="Background Image"
                                    className="court-background-image"
                                    loading="lazy"
                                />
                                <div className="court-background-overlay"></div>
                                <p>High Court of <br /> Punjab & Haryana</p>
                            </div>
                            <div className="card foreground-card2">
                                <div className="card-content">
                                    <p>High Court Of <br /> Punjab & Haryana</p>
                                </div>
                            </div>
                        </div>
                        <h2 className='mobile-heading'>High Court of <br /> Panjab & Haryana</h2> */}
          {/* </div> */}
          <img src={mobileFlags} alt="" className="mobileFlags max-sm:hidden" />
        </div>
        <div className="court-footer w-2/3 max-md:w-full max-md:px-4">
          <p
            style={{ marginTop: "50px" }}
            className="dark:text-gray-300 font-mulish max-sm:text-center"
          >
            {" "}
            <span className="text-black dark:text-gray-300 font-bold">
              {" "}
              Disclaimer: <br />
            </span>{" "}
            The content of this site is only for information purpose. Users are
            advised not to depend on the information and use it for official
            purpose. Neither the High Court of Delhi nor the National
            Informatics Centre, Ministry of Information Technology, Government
            of India is responsible for any damages arising in contract from the
            use of the content of this site.
          </p>
        </div>
      </div>

      <div className="court-bottom-image">
        <img
          src={rightcorner}
          alt=""
          className="right-corner dark:opacity-50"
        />
        <img
          src={leftcorner}
          alt=""
          className="court-left-corner dark:opacity-50"
        />
        <img src={center} alt="" className="center-image dark:opacity-30" />
      </div>
    </div>
  );
}

export default CourtSelection;

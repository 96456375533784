// React Utils
import React from "react";

// Framer Motion Utils
import { motion } from "framer-motion";

// Icons
import { FaArrowRightLong, FaRegFaceSmileBeam } from "react-icons/fa6";
import { ImCross } from "react-icons/im";
import { BsEmojiNeutral } from "react-icons/bs";
import { IoMdCheckmark } from "react-icons/io";
import { BiDislike, BiLike } from "react-icons/bi";

const WhyCaseMinister = () => {
  return (
    <div className="pb-32 max-sm:pb-16 pt-16">
      <div className="flex flex-col items-center gap-20 px-28 max-sm:px-4 max-sm:gap-10">
        <motion.div
          initial={{
            opacity: 0,
            y: 25,
          }}
          whileInView={{
            opacity: 1,
            y: 0,
          }}
          transition={{
            duration: 0.7,
            ease: "easeOut",
            delay: 0,
          }}
          viewport={{
            once: true,
            margin: "-250px",
          }}
          className="flex flex-col gap-10 items-center max-sm:gap-5"
        >
          <motion.h4
            className="font-sora font-bold text-5xl max-sm:text-2xl text-center leading-[1.4]"
            initial={{ opacity: 0, y: 15 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.0 }}
            viewport={{ once: true }}
          >
            Why CaseMinister? <br />
            {/* Stop Wasting Time & Money! */}
          </motion.h4>

          <motion.p
            className="font-poppins text-sm text-center leading-[1.6]"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.0 }}
            viewport={{ once: true }}
          >
            CaseMinister consolidates all your case management needs into a
            single, intuitive platform. <br className="max-sm:hidden" /> No more
            juggling multiple apps.
          </motion.p>

          <motion.a
            href="/about-us"
            target="_blank"
            className="flex max-sm:scale-75 items-center gap-4 border border-black/50 justify-between font-poppins font-semibold rounded-[23px] py-2.5 px-8"
            initial={{ opacity: 0, y: 10 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.6, delay: 0.0 }}
            viewport={{ once: true }}
            whileHover={{
              scale: 1.05,
              transition: { duration: 0.2 },
            }}
            whileTap={{ scale: 0.98 }}
          >
            Learn More <FaArrowRightLong />
          </motion.a>
        </motion.div>
        <div className="w-full flex flex-col items-center gap-10">
          <div className="border-[3px] border-[#D91818] max-w-[1440px] rounded-lg">
            <div className="bg-[#FFF2F2] dark:bg-[#0b0202] flex justify-center gap-5 items-center flex-col px-20 max-sm:px-4 pt-6 pb-10 rounded-lg">
              <p className="text-[#D91818] font-sora font-semibold text-2xl">
                The Problem
              </p>
              <p className="font-poppins text-sm max-sm:text-center text-[#3b3b3b] dark:text-[#c4c4c4]">
                Law firms, legal teams, and independent lawyers are forced to
                juggle multiple tools for:
              </p>
              <div className="flex flex-wrap gap-x-12 max-sm:text-left max-sm:justify-start gap-y-6 mt-2 font-medium font-poppins justify-center">
                <div className="flex items-center gap-3">
                  <ImCross className="text-[#FF0E00]" />
                  <p>Case Management</p>
                </div>
                <div className="flex items-center gap-3">
                  <ImCross className="text-[#FF0E00]" />
                  <p>AI chatbots for legal queries</p>
                </div>
                <div className="flex items-center gap-3">
                  <ImCross className="text-[#FF0E00]" />
                  <p>AI-Powered Legal Research</p>
                </div>
                <div className="flex items-center gap-3">
                  <ImCross className="text-[#FF0E00]" />
                  <p>Legal analytics & reporting</p>
                </div>
                <div className="flex items-center gap-3">
                  <ImCross className="text-[#FF0E00]" />
                  <p>Researching case laws & precedents</p>
                </div>
              </div>
            </div>
            <div className="px-10 max-sm:px-4 py-4 text-center">
              <div className="flex flex-1 gap-3 items-center justify-center">
                <BsEmojiNeutral className="text-[#FF0E00] flex-shrink-0 text-3xl max-sm:text-2xl" />
                <p className="font-poppins max-sm:text-sm">
                  This means higher costs, inefficiency, and wasted time
                  switching between apps.
                </p>
              </div>
            </div>
          </div>
          <div className="border-[3px] border-[#1E940C] max-w-[1440px] rounded-lg">
            <div className="bg-[#F3FFF1] dark:bg-[#0b0202]  flex justify-center gap-5 items-center flex-col px-20 max-sm:px-4 pt-6 pb-10 rounded-lg">
              <p className="text-[#1E940C] font-sora font-semibold text-2xl">
                The Solution
              </p>
              <p className="font-poppins text-sm max-sm:text-center text-[#3b3b3b] dark:text-[#c4c4c4]">
                CaseMinister – One destination for every legal solution.
              </p>
              <div className="flex flex-wrap gap-x-12 gap-y-6 mt-2 max-sm:text-left max-sm:justify-start font-medium font-poppins justify-center">
                <div className="flex items-center gap-3">
                  <IoMdCheckmark className="text-[#1E940C] text-2xl" />
                  <p>Case Management</p>
                </div>
                <div className="flex items-center gap-3">
                  <IoMdCheckmark className="text-[#1E940C] text-2xl" />
                  <p>AI chatbots for legal queries</p>
                </div>
                <div className="flex items-center gap-3">
                  <IoMdCheckmark className="text-[#1E940C] text-2xl" />
                  <p>AI-Powered Legal Research</p>
                </div>
                <div className="flex items-center gap-3">
                  <IoMdCheckmark className="text-[#1E940C] text-2xl" />
                  <p>Legal analytics & reporting</p>
                </div>
                <div className="flex items-center gap-3">
                  <IoMdCheckmark className="text-[#1E940C] text-2xl" />
                  <p>Researching case laws & precedents</p>
                </div>
              </div>
            </div>
            <div className="px-10 max-sm:px-4 py-4 text-center">
              <div className="flex flex-1 gap-3 items-center justify-center">
                <FaRegFaceSmileBeam className="text-[#1E940C] flex-shrink-0 text-3xl max-sm:text-2xl" />
                <p className="font-poppins max-sm:text-sm">
                  Why pay for multiple tools when one can do it all?
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-col gap-14 items-center">
          <div className="max-sm:mt-8">
            <h4 className="font-sora font-semibold text-3xl max-sm:text-2xl max-sm:text-center">
              CaseMinister vs. The Rest – The Smarter Choice
            </h4>
          </div>
          <div className="flex max-sm:flex-col gap-10 justify-center relative">
            <div className="absolute top-1/2 max-sm:hidden -translate-y-1/2 bg-white dark:bg-black font-inter text-3xl size-24 rounded-full flex items-center justify-center border">
              VS
            </div>
            <div className="flex flex-col gap-10">
              <div className="flex items-center justify-center gap-3 max-sm:gap-2">
                <p className="font-poppins font-medium text-xl max-sm:text-base">
                  Competitors{" "}
                  <span className="text-lg max-sm:text-base">
                    (Multiple Tools Required)
                  </span>
                </p>
                <BiDislike className="text-3xl max-sm:text-xl" />
              </div>
              <div className="max-w-[480px] h-full border-[3px] border-b-0 border-black dark:border-white rounded-tl-[33px] rounded-tr-[33px] px-4 py-7">
                <div className="flex flex-col text-center text-lg max-sm:text-sm max-sm:gap-4 max-sm:px-2 gap-6 px-6">
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">Needs third-party AI tool</p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Requires additional research tool
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Limited analytics, requires manual research
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Requires another paid subscription
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">Pay separately for each tool</p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Requires complex integrations
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      No predictive insights, only historical data
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Reduces case research time by 40%
                    </p>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-10">
              <div className="flex items-center justify-center gap-3 max-sm:gap-2">
                <p className="font-poppins font-medium text-xl max-sm:text-base text-transparent bg-clip-text bg-gradient-to-r from-[#521EDF] to-[#D028CB]">
                  CaseMinister{" "}
                  <span className="text-lg max-sm:text-base">
                    (All-in-One Solution)
                  </span>
                </p>
                <BiLike className="text-3xl max-sm:text-xl" />
              </div>
              <div className="max-w-[480px] h-full border-[3px] border-b-0 border-black dark:border-white rounded-tl-[33px] rounded-tr-[33px] px-4 py-7">
                <div className="flex flex-col text-center text-lg max-sm:text-sm max-sm:gap-4 max-sm:px-2 gap-6 px-6 text-transparent bg-clip-text bg-gradient-to-r from-[#521EDF] to-[#D028CB]">
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">AI Legal Assistant</p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">AI-powered case law research</p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Real-time insights on case trends, judge behavior, and
                      timelines
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      AI-driven chatbot integrated into workflow
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      One Subscription – No Hidden Fees
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Works with existing legal software
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Predict case outcomes & optimize strategy
                    </p>
                    <hr />
                  </div>
                  <div className="flex flex-col gap-4">
                    <p className="font-poppins">
                      Time wasted switching between platforms
                    </p>
                    <hr />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default WhyCaseMinister;

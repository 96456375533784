import React, { useEffect, useState } from "react";

// Icons
import { FaRegEdit } from "react-icons/fa";
import { MdOutlineEmail } from "react-icons/md";
import { FaMapMarkerAlt } from "react-icons/fa";
import { GrContactInfo } from "react-icons/gr";
import { CgArrowTopRight } from "react-icons/cg";
import { FaXTwitter } from "react-icons/fa6";
import { FaFacebookF } from "react-icons/fa";
import { FaLinkedinIn } from "react-icons/fa6";

// Data
import { statesAndCities } from "./data";

// Dialog Box
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
  DialogClose,
} from "../../components/ui/dialog";

// Shadcn Input
import { Input } from "../../components/ui/input";

// Shadcn Select
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../components/ui/select";

// Shadcn Textarea
import { Textarea } from "../../components/ui/textarea";

// Axios
import axiosInstance from "../../utils/axiosInstance";

const LawyerProfile = ({
  userProfileData,
  setChangeUserProfileData,
  getUser,
}) => {
  const [selectedState, setSelectedState] = useState("");
  const [selectedCity, setSelectedCity] = useState("");
  const [editDialogBox1, setEditDialogBox1] = useState(false);
  const [editDialogBox2, setEditDialogBox2] = useState(false);
  const [editDialogBox3, setEditDialogBox3] = useState(false);
  const [editDialogBox4, setEditDialogBox4] = useState(false);
  const [editDialogBox5, setEditDialogBox5] = useState(false);
  const [formData, setFormData] = useState({
    user: { first_name: "", last_name: "" },
    city: "",
    state: "",
    pincode: "",
    mobile_number: "",
    practice_area: "",
    court_of_practice: "",
    experience: "",
    barcode_number: "",
    language: "",
    bio: "",
    education_details: [{ university: "", degree: "", passing_year: "" }],
    employment_details: [
      {
        employername: "",
        organisation: "",
        period_of_employment: "",
        job_title: "",
      },
    ],
    published_works: "",
    honors_and_awards: "",
    consultation_fees: "",
    twitter: "",
    facebook: "",
    linkedin: "",
  });

  useEffect(() => {
    if (userProfileData) {
      setFormData({
        user: {
          first_name: userProfileData.user.first_name || "",
          last_name: userProfileData.user.last_name || "",
        },
        city: userProfileData.city || "",
        state: userProfileData.state || "",
        pincode: userProfileData.pincode || "",
        mobile_number: userProfileData.mobile_number || "",
        practice_area: userProfileData.practice_area || "",
        court_of_practice: userProfileData.court_of_practice || "",
        experience: userProfileData.experience || "",
        barcode_number: userProfileData.barcode_number || "",
        language: userProfileData.language || "",
        bio: userProfileData.bio || "",
        education_details: [
          userProfileData.education_details?.[0] || {
            university: "",
            degree: "",
            passing_year: "",
          },
        ],
        employment_details: [
          userProfileData.employment_details?.[0] || {
            employername: "",
            organisation: "",
            period_of_employment: "",
            job_title: "",
          },
        ],
        published_works: userProfileData.published_works || "",
        honors_and_awards: userProfileData.honors_and_awards || "",
        consultation_fees: userProfileData.consultation_fees || "",
        twitter: userProfileData.twitter || "",
        facebook: userProfileData.facebook || "",
        linkedin: userProfileData.linkedin || "",
      });

      setSelectedState(userProfileData.state || "");
      setSelectedCity(userProfileData.city || "");
    }
  }, [userProfileData]);

  const handleStateChange = (value) => {
    setSelectedState(value);
    setSelectedCity(""); // Reset city when state changes
    setFormData((prev) => ({
      ...prev,
      state: value,
      city: "",
    }));
  };

  // Handle City Selection
  const handleCityChange = (value) => {
    setSelectedCity(value);
    setFormData((prev) => ({
      ...prev,
      city: value,
    }));
  };

  const access_token = localStorage.getItem("access_token"); // Access Token

  console.log(userProfileData);

  // UPDATE USER DETAILS
  const updateUser = async () => {
    try {
      const response = await axiosInstance.put(
        `api/user/${userProfileData.id}/`,
        { is_lawyer: false, email: userProfileData.user.email },
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        getUser();
      }
    } catch (err) {
      console.log(err);
    }
  };

  const handleSaveDetails = async (e) => {
    e.preventDefault();

    const form = e.target;
    const formData = new FormData(form);
    const data = {
      user: { ...(userProfileData?.user || {}) }, // Preserve existing user details
      education_details: [
        {
          ...(userProfileData?.education_details?.[0] || {}),
          profile: userProfileData?.id,
        },
      ],
      employment_details: [
        {
          ...(userProfileData?.employment_details?.[0] || {}),
          profile: userProfileData?.id,
        },
      ],
    };

    for (let [key, value] of formData.entries()) {
      if (key === "first_name" || key === "last_name") {
        data.user[key] = value; // Store first_name and last_name inside user object.
      } else if (
        key === "university" ||
        key === "degree" ||
        key === "passing_year"
      ) {
        data.education_details[0][key] = value; // Store Academic Details.
      } else if (
        key === "employername" ||
        key === "organisation" ||
        key === "period_of_employment" ||
        key === "job_title"
      ) {
        data.employment_details[0][key] = value; // Store Employment Details
      } else {
        data[key] = value; // Store other fields directly inside data object.
      }
    }

    console.log(data);

    try {
      const response = await axiosInstance.put(
        `api/profile/${userProfileData.id}/`,
        data,
        {
          headers: {
            Authorization: `Bearer ${access_token}`,
            "Content-Type": "application/json",
          },
        }
      );
      console.log(response);
      if (response.status === 200) {
        setChangeUserProfileData((prev) => !prev);
        setEditDialogBox1(false);
        setEditDialogBox2(false);
        setEditDialogBox3(false);
        setEditDialogBox4(false);
        setEditDialogBox5(false);
      }
    } catch (err) {
      console.log(err);
    }
  };

  return (
    <div className="px-20 pb-12 flex max-lg:flex-col max-lg:items-center max-lg:px-10 max-md:px-4 gap-10 justify-center">
      {/* LEFT */}
      <div className="flex flex-col gap-4 -mt-24">
        {/* TOP */}
        <div className="shadow-md rounded-lg bg-white dark:bg-dark-400 w-[600px] max-lg:w-full p-8 flex gap-6">
          <div>
            <div className="size-36 max-md:hidden rounded-full bg-gray-300 dark:bg-gray-500 flex items-center gap-1 justify-center">
              <p className="font-mulish font-bold text-4xl">
                {userProfileData?.user?.first_name
                  ?.substring(0, 1)
                  .toUpperCase()}
              </p>
              <p className="font-mulish font-bold text-4xl">
                {userProfileData?.user?.last_name
                  ?.substring(0, 1)
                  .toUpperCase()}
              </p>
            </div>
          </div>
          <div className="flex flex-col gap-3 flex-grow max-sm:text-sm">
            <h3 className="font-mulish font-semibold text-2xl max-sm:text-xl">
              {userProfileData?.user?.first_name}{" "}
              {userProfileData?.user?.last_name}
            </h3>
            <div className="flex gap-4 items-center">
              <MdOutlineEmail />
              <p>{userProfileData?.user?.email}</p>
            </div>
            <div className="flex gap-4 items-center">
              <FaMapMarkerAlt />
              <p>
                {userProfileData?.city}, {userProfileData?.state},{" "}
                {userProfileData?.pincode}
              </p>
            </div>
            <div className="flex gap-4 items-center">
              <GrContactInfo />
              <p>{userProfileData?.mobile_number}</p>
            </div>
          </div>
          <div>
            <Dialog open={editDialogBox1} onOpenChange={setEditDialogBox1}>
              <DialogTrigger>
                <FaRegEdit className="text-lg" />
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit Personal Details</DialogTitle>
                  <DialogDescription>
                    Update your profile details.
                  </DialogDescription>
                </DialogHeader>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleSaveDetails}
                >
                  <div>
                    <label
                      htmlFor="first_name"
                      className="font-raleway font-semibold text-sm"
                    >
                      First Name*
                    </label>
                    <Input
                      value={formData.user.first_name}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                        setFormData((prev) => ({
                          ...prev,
                          user: { ...prev.user, first_name: value },
                        }));
                      }}
                      name="first_name"
                      type="text"
                      placeholder="Enter Your First Name"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="last_name"
                      className="font-raleway font-semibold text-sm"
                    >
                      Last Name *
                    </label>
                    <Input
                      value={formData.user.last_name}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                        setFormData((prev) => ({
                          ...prev,
                          user: { ...prev.user, last_name: value },
                        }));
                      }}
                      name="last_name"
                      type="text"
                      placeholder="Enter Your Last Name"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="state"
                      className="font-raleway font-semibold text-sm"
                    >
                      Select State*
                    </label>
                    <Select
                      name="state"
                      value={selectedState}
                      onValueChange={handleStateChange}
                      required
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a state" />
                      </SelectTrigger>
                      <SelectContent>
                        {Object.keys(statesAndCities).map((state) => (
                          <SelectItem key={state} value={state}>
                            {state}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label
                      htmlFor="city"
                      className="font-raleway font-semibold text-sm"
                    >
                      Select City*
                    </label>
                    <Select
                      value={selectedCity}
                      onValueChange={handleCityChange}
                      name="city"
                      required
                      disabled={!selectedState}
                    >
                      <SelectTrigger className="w-full">
                        <SelectValue placeholder="Select a city" />
                      </SelectTrigger>
                      <SelectContent>
                        {statesAndCities[selectedState]?.map((city) => (
                          <SelectItem key={city} value={city}>
                            {city}
                          </SelectItem>
                        ))}
                      </SelectContent>
                    </Select>
                  </div>
                  <div>
                    <label className="font-raleway font-semibold text-sm">
                      Pin Code*
                    </label>
                    <Input
                      value={formData.pincode}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^0-9 ]/g, "");
                        setFormData((prev) => ({
                          ...prev,
                          pincode: value,
                        }));
                      }}
                      name="pincode"
                      type="text"
                      placeholder="Enter Your Pin Code"
                      autoComplete="off"
                      required
                    />
                  </div>
                  <div>
                    <label className="font-raleway font-semibold text-sm">
                      Mobile Number*
                    </label>
                    <Input
                      value={formData.mobile_number}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          mobile_number: e.target.value,
                        }))
                      }
                      name="mobile_number"
                      className="[appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"
                      type="text"
                      placeholder="Enter Your Mobile Number"
                      maxLength="10"
                      minLength="10"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <DialogFooter className="max-sm:gap-2">
                    <DialogClose className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm">
                      Cancel
                    </DialogClose>
                    <button className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm bg-black dark:bg-white text-white dark:text-black">
                      Save
                    </button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
        </div>
        {/* BOTTOM */}
        <div className="shadow-md rounded-lg bg-white dark:bg-dark-400 w-[600px] max-lg:w-full p-8 flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold font-mulish">
              Professional Details
            </p>
            <Dialog open={editDialogBox2} onOpenChange={setEditDialogBox2}>
              <DialogTrigger>
                <FaRegEdit className="text-lg" />
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit Professional Details</DialogTitle>
                  <DialogDescription>
                    Update your professional details.
                  </DialogDescription>
                </DialogHeader>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleSaveDetails}
                >
                  <div>
                    <label
                      htmlFor="practice_area"
                      className="font-raleway font-semibold text-sm"
                    >
                      Practice Area*
                    </label>
                    <Input
                      value={formData.practice_area}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                        setFormData((prev) => ({
                          ...prev,
                          practice_area: value,
                        }));
                      }}
                      name="practice_area"
                      type="text"
                      placeholder="eg. Corporate Law"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="court_of_practice"
                      className="font-raleway font-semibold text-sm"
                    >
                      Court of Practice*
                    </label>
                    <Input
                      value={formData.court_of_practice}
                      onChange={(e) => {
                        const value = e.target.value.replace(/[^a-zA-Z ]/g, "");
                        setFormData((prev) => ({
                          ...prev,
                          court_of_practice: value,
                        }));
                      }}
                      name="court_of_practice"
                      type="text"
                      placeholder="eg. High Court"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="experience"
                      className="font-raleway font-semibold text-sm"
                    >
                      Experience (in years)*
                    </label>
                    <Input
                      value={formData.experience}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          experience: e.target.value,
                        }))
                      }
                      name="experience"
                      type="number"
                      placeholder="Years of Experience"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="barcode_number"
                      className="font-raleway font-semibold text-sm"
                    >
                      Barcode Number
                    </label>
                    <Input
                      value={formData.barcode_number}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          barcode_number: e.target.value,
                        }))
                      }
                      name="barcode_number"
                      type="text"
                      placeholder="Enter Your Barcode Number"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="language"
                      className="font-raleway font-semibold text-sm"
                    >
                      Language
                    </label>
                    <Input
                      value={formData.language}
                      onChange={(e) => {
                        const value = e.target.value.replace(
                          /[^a-zA-Z, ]/g,
                          ""
                        );
                        setFormData((prev) => ({
                          ...prev,
                          language: value,
                        }));
                      }}
                      name="language"
                      type="text"
                      placeholder="eg. English, Hindi"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="bio"
                      className="font-raleway font-semibold text-sm"
                    >
                      Bio*
                    </label>
                    <Textarea
                      value={formData.bio}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          bio: e.target.value,
                        }))
                      }
                      name="bio"
                      placeholder="Enter Your Bio"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <DialogFooter className="max-sm:gap-2">
                    <DialogClose className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm">
                      Cancel
                    </DialogClose>
                    <button className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm bg-black dark:bg-white text-white dark:text-black">
                      Save
                    </button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-col gap-4 max-sm:text-sm">
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Practice Area -</p>
              <p className="font-mulish">{userProfileData?.practice_area}</p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Court of Practice -</p>
              <p className="font-mulish">
                {userProfileData?.court_of_practice}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Experience -</p>
              <p className="font-mulish">
                {userProfileData?.experience > 1
                  ? `${userProfileData?.experience} years`
                  : `${userProfileData?.experience} year`}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Barcode Number -</p>
              <p className="font-mulish">{userProfileData?.barcode_number}</p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Languages -</p>
              <p className="font-mulish">{userProfileData?.language}</p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Bio -</p>
              <p className="font-mulish">{userProfileData?.bio}</p>
            </div>
          </div>
          <div className="mt-8">
            <button
              onClick={updateUser}
              className="flex items-center gap-2 bg-black dark:bg-white dark:text-gray-700 px-3 py-2 text-gray-200 font-medium"
            >
              <CgArrowTopRight />
              Swtich to User Profile
            </button>
          </div>
        </div>
      </div>
      {/* RIGHT */}
      <div className="flex flex-col gap-4 -mt-24 max-lg:mt-0 w-full">
        <div className="shadow-md rounded-lg bg-white dark:bg-dark-400 w-[600px] max-lg:w-full p-8 flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold font-mulish">
              Academic Details
            </p>
            <Dialog open={editDialogBox3} onOpenChange={setEditDialogBox3}>
              <DialogTrigger>
                <FaRegEdit className="text-lg" />
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit Academic Details</DialogTitle>
                  <DialogDescription>
                    Update your academic details.
                  </DialogDescription>
                </DialogHeader>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleSaveDetails}
                >
                  <div>
                    <label
                      htmlFor="university"
                      className="font-raleway font-semibold text-sm"
                    >
                      University*
                    </label>
                    <Input
                      value={formData.education_details[0].university}
                      onChange={(e) => {
                        const value = e.target.value.replace(
                          /[^a-zA-Z., ]/g,
                          ""
                        );
                        setFormData((prev) => ({
                          ...prev,
                          education_details: [
                            {
                              ...prev.education_details[0],
                              university: value,
                            },
                          ],
                        }));
                      }}
                      name="university"
                      type="text"
                      placeholder="Enter Your University Name"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="degree"
                      className="font-raleway font-semibold text-sm"
                    >
                      Degree*
                    </label>
                    <Input
                      value={formData.education_details[0].degree}
                      onChange={(e) => {
                        const value = e.target.value.replace(
                          /[^a-zA-Z,. ]/g,
                          ""
                        );
                        setFormData((prev) => ({
                          ...prev,
                          education_details: [
                            {
                              ...prev.education_details[0],
                              degree: value,
                            },
                          ],
                        }));
                      }}
                      name="degree"
                      type="text"
                      placeholder="Enter Your Degree Name"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="passing_year"
                      className="font-raleway font-semibold text-sm"
                    >
                      Passing Year*
                    </label>
                    <Input
                      value={formData.education_details[0].passing_year}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          education_details: [
                            {
                              ...prev.education_details[0],
                              passing_year: e.target.value,
                            },
                          ],
                        }))
                      }
                      name="passing_year"
                      type="number"
                      placeholder="Enter Your Passing Year"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <DialogFooter className="max-sm:gap-2">
                    <DialogClose className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm">
                      Cancel
                    </DialogClose>
                    <button className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm bg-black dark:bg-white text-white dark:text-black">
                      Save
                    </button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-col gap-3 max-sm:text-sm">
            <div className="flex gap-1">
              <p className="font-mulish font-bold text-nowrap">University -</p>
              <p className="font-mulish">
                {userProfileData?.education_details
                  ? userProfileData?.education_details[0]?.university
                  : ""}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Degree -</p>
              <p className="font-mulish">
                {userProfileData?.education_details
                  ? userProfileData?.education_details[0]?.degree
                  : ""}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Passing Year -</p>
              <p className="font-mulish">
                {userProfileData?.education_details
                  ? userProfileData?.education_details[0]?.passing_year
                  : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="shadow-md rounded-lg bg-white dark:bg-dark-400 w-[600px] max-lg:w-full p-8 flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold font-mulish">
              Employment Details
            </p>
            <Dialog open={editDialogBox4} onOpenChange={setEditDialogBox4}>
              <DialogTrigger>
                <FaRegEdit className="text-lg" />
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit Employment Details</DialogTitle>
                  <DialogDescription>
                    Update your Employment details.
                  </DialogDescription>
                </DialogHeader>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleSaveDetails}
                >
                  <div>
                    <label
                      htmlFor="employername"
                      className="font-raleway font-semibold text-sm"
                    >
                      Employer Name*
                    </label>
                    <Input
                      value={formData.employment_details[0].employername}
                      onChange={(e) => {
                        const value = e.target.value.replace(
                          /[^a-zA-Z,. ]/g,
                          ""
                        );
                        setFormData((prev) => ({
                          ...prev,
                          employment_details: [
                            {
                              ...prev.employment_details[0],
                              employername: value,
                            },
                          ],
                        }));
                      }}
                      name="employername"
                      type="text"
                      placeholder="Enter Your Employer Name"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="organisation"
                      className="font-raleway font-semibold text-sm"
                    >
                      Organization Name*
                    </label>
                    <Input
                      value={formData.employment_details[0].organisation}
                      onChange={(e) => {
                        const value = e.target.value.replace(
                          /[^a-zA-Z,.: ]/g,
                          ""
                        );
                        setFormData((prev) => ({
                          ...prev,
                          employment_details: [
                            {
                              ...prev.employment_details[0],
                              organisation: value,
                            },
                          ],
                        }));
                      }}
                      name="organisation"
                      type="text"
                      placeholder="Enter Your Organization Name"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="period_of_employment"
                      className="font-raleway font-semibold text-sm"
                    >
                      Total Period of Employment (in years)*
                    </label>
                    <Input
                      value={
                        formData.employment_details[0].period_of_employment
                      }
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          employment_details: [
                            {
                              ...prev.employment_details[0],
                              period_of_employment: e.target.value,
                            },
                          ],
                        }))
                      }
                      name="period_of_employment"
                      type="number"
                      placeholder="Enter Employment Period in Years"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="job_title"
                      className="font-raleway font-semibold text-sm"
                    >
                      Job Title*
                    </label>
                    <Input
                      value={formData.employment_details[0].job_title}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          employment_details: [
                            {
                              ...prev.employment_details[0],
                              job_title: e.target.value,
                            },
                          ],
                        }))
                      }
                      name="job_title"
                      type="text"
                      placeholder="Enter Your Job Title"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <DialogFooter className="max-sm:gap-2">
                    <DialogClose className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm">
                      Cancel
                    </DialogClose>
                    <button className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm bg-black dark:bg-white text-white dark:text-black">
                      Save
                    </button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-col gap-3 max-sm:text-sm">
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Previous Employer Name -</p>
              <p className="font-mulish">
                {userProfileData?.employment_details
                  ? userProfileData?.employment_details[0]?.employername
                  : ""}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Previous Organization -</p>
              <p className="font-mulish">
                {userProfileData?.employment_details
                  ? userProfileData?.employment_details[0]?.organisation
                  : ""}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Period of Employment -</p>
              <p className="font-mulish">
                {userProfileData?.employment_details
                  ? `${userProfileData?.employment_details[0]?.period_of_employment} year(s)`
                  : ""}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Job Title -</p>
              <p className="font-mulish">
                {userProfileData?.employment_details
                  ? userProfileData?.employment_details[0]?.job_title
                  : ""}
              </p>
            </div>
          </div>
        </div>
        <div className="shadow-md rounded-lg bg-white dark:bg-dark-400 w-[600px] max-lg:w-full p-8 flex flex-col gap-6">
          <div className="flex justify-between items-center">
            <p className="text-xl font-semibold font-mulish">
              Additional Information
            </p>

            <Dialog open={editDialogBox5} onOpenChange={setEditDialogBox5}>
              <DialogTrigger>
                <FaRegEdit className="text-lg" />
              </DialogTrigger>
              <DialogContent>
                <DialogHeader>
                  <DialogTitle>Edit Personal Details</DialogTitle>
                  <DialogDescription>
                    Update your profile details.
                  </DialogDescription>
                </DialogHeader>
                <form
                  className="flex flex-col gap-4"
                  onSubmit={handleSaveDetails}
                >
                  <div>
                    <label
                      htmlFor="published_works"
                      className="font-raleway font-semibold text-sm"
                    >
                      Published Works*
                    </label>
                    <Input
                      value={formData.published_works}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          published_works: e.target.value,
                        }))
                      }
                      name="published_works"
                      type="text"
                      placeholder="Enter Your Published Work."
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="honors_and_awards"
                      className="font-raleway font-semibold text-sm"
                    >
                      Honours and Awards*
                    </label>
                    <Input
                      value={formData.honors_and_awards}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          honors_and_awards: e.target.value,
                        }))
                      }
                      name="honors_and_awards"
                      type="text"
                      placeholder="Enter Your Honours and Awards"
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="consultation_fees"
                      className="font-raleway font-semibold text-sm"
                    >
                      Consultation Fees* (per session)
                    </label>
                    <Input
                      value={formData.consultation_fees}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          consultation_fees: e.target.value,
                        }))
                      }
                      name="consultation_fees"
                      type="number"
                      placeholder="Enter Your Consultation Fees."
                      required
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="twitter"
                      className="font-raleway font-semibold text-sm"
                    >
                      X Profile
                    </label>
                    <Input
                      value={formData.twitter}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          twitter: e.target.value,
                        }))
                      }
                      name="twitter"
                      type="text"
                      placeholder="eg.- https://x.com/caseminister"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="facebook"
                      className="font-raleway font-semibold text-sm"
                    >
                      Facebook Profile
                    </label>
                    <Input
                      value={formData.facebook}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          facebook: e.target.value,
                        }))
                      }
                      name="facebook"
                      type="text"
                      placeholder="eg.- https://facebook.com/caseminister"
                      autoComplete="off"
                    />
                  </div>
                  <div>
                    <label
                      htmlFor="linkedin"
                      className="font-raleway font-semibold text-sm"
                    >
                      LinkedIn Profile
                    </label>
                    <Input
                      value={formData.linkedin}
                      onChange={(e) =>
                        setFormData((prev) => ({
                          ...prev,
                          linkedin: e.target.value,
                        }))
                      }
                      name="linkedin"
                      type="text"
                      placeholder="eg.- https://linkedin.com/in/caseminister"
                      autoComplete="off"
                    />
                  </div>
                  <DialogFooter className="max-sm:gap-2">
                    <DialogClose className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm">
                      Cancel
                    </DialogClose>
                    <button className="font-raleway font-semibold border px-3 py-1.5 rounded-md text-sm bg-black dark:bg-white text-white dark:text-black">
                      Save
                    </button>
                  </DialogFooter>
                </form>
              </DialogContent>
            </Dialog>
          </div>
          <div className="flex flex-col gap-3 max-sm:text-sm">
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Published Work -</p>
              <p className="font-mulish">{userProfileData?.published_works}</p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Honours & Awards -</p>
              <p className="font-mulish">
                {userProfileData?.honors_and_awards}
              </p>
            </div>
            <div className="flex gap-1">
              <p className="font-mulish font-bold">Consultation Fees -</p>
              <p className="font-mulish">
                ₹{userProfileData?.consultation_fees} per session
              </p>
            </div>
            <div className="flex gap-4 mt-8">
              <a
                href={userProfileData?.twitter || "https://x.com"}
                target="_blank"
                rel="noreferrer"
                className="p-2 bg-black rounded-full flex justify-center items-center"
              >
                <FaXTwitter className="text-white" />
              </a>
              <a
                href={userProfileData?.facebook || "https://facebook.com"}
                target="_blank"
                rel="noreferrer"
                className="p-2 bg-[#0765ff] rounded-full flex justify-center items-center"
              >
                <FaFacebookF className="text-white" />
              </a>
              <a
                href={userProfileData?.linkedin || "https://linkedin.com"}
                target="_blank"
                rel="noreferrer"
                className="p-2 bg-[#0065c7] rounded-full flex justify-center items-center"
              >
                <FaLinkedinIn className="text-white" />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LawyerProfile;
